
import { defineComponent, onMounted, ref } from 'vue'
import { RouteLocationRaw } from 'vue-router'
import { EducationRouteNames } from '@/router/route-names'
import { useCourseMenuItems } from './tab-menu-items'
import { MenuItem } from 'primevue/menuitem'

export default defineComponent({
  setup () {
    const menuItems = ref<MenuItem[]>([])

    onMounted(async () => {
      menuItems.value = useCourseMenuItems()
    })

    return {
      menuItems,
      toCreatePerson: (): RouteLocationRaw => {
        return {
          name: EducationRouteNames.PERSON_CREATE
        }
      }
    }
  }
})
