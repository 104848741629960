import {
  ToastLife,
  ToastSeverities,
  ToastSummaries
} from '@/models/components/toast/enums'
import { useToast } from 'primevue/usetoast'

interface UseToastService {
  toast: {
    add(args: {
      severity?: string | undefined
      summary?: string | undefined
      detail?: string | undefined
      life?: number | undefined
      closable?: boolean | undefined
      group?: string | undefined
    }): void
    removeGroup(group: string): void
    removeAllGroups(): void
  }
  showSuccessfullyDone(detail?: string): void
  showWarn(detail?: string): void
  showInfo(detail?: string): void
  showError(detail?: string): void
}

export const useToastService = (): UseToastService => {
  const toast = useToast()

  const showSuccessfullyDone = (
    detail = 'Se completó la acción de forma exitosa.'
  ) => {
    toast.add({
      severity: ToastSeverities.Success,
      summary: ToastSummaries.SuccessfullyDone,
      detail: detail,
      life: ToastLife.Default
    })
  }

  const showWarn = (detail = 'Se completó la acción con advertencias.') => {
    toast.add({
      severity: ToastSeverities.Warn,
      summary: ToastSummaries.Warn,
      detail: detail,
      life: ToastLife.Default
    })
  }

  const showError = (detail = 'Ocurrió algún tipo de error.') => {
    toast.add({
      severity: ToastSeverities.Error,
      summary: ToastSummaries.Error,
      detail: detail,
      life: ToastLife.Default
    })
  }

  const showInfo = (detail = 'Ocurrió algún tipo de error.') => {
    toast.add({
      severity: ToastSeverities.Info,
      summary: ToastSummaries.Info,
      detail: detail,
      life: ToastLife.Default
    })
  }

  return {
    toast,
    showSuccessfullyDone,
    showWarn,
    showError,
    showInfo
  }
}
