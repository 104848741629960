import { CommunityRouteNames } from '@/router/route-names'
import { RouteRecordRaw } from 'vue-router'
const CommunityRoutes: Array<RouteRecordRaw> = [
  {
    path: 'referrals',
    name: CommunityRouteNames.REFERALS,
    component: () => import('@/views/community/referrals/ReferralsList.vue')
  },
  {
    path: '',
    redirect: { name: CommunityRouteNames.REFERALS }
  }
]

export default CommunityRoutes
