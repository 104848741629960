
import { defineComponent, onMounted, ref } from 'vue'
import { StaffSquadsAPI } from '@/api/staff-squads.api'
import { StaffSquadDto } from '@/models/staff-squads/interfaces/StaffSquad.dto'
import DataTable from 'primevue/datatable'
import router from '@/router'
import { RouteNames } from '@/router/route-names'

export default defineComponent({
  components: {
    DataTable
  },
  setup () {
    const squads = ref<Array<StaffSquadDto>>([])

    onMounted(async () => {
      squads.value = await StaffSquadsAPI.findAll()
    })

    return {
      squads,
      onClickCreate () {
        router.push({ name: RouteNames.STAFF_SQUAD, params: { squadId: 'nuevo' } })
      },
      onClickEdit (context: StaffSquadDto) {
        router.push({ name: RouteNames.STAFF_SQUAD, params: { squadId: context._id } })
      }
    }
  }
})
