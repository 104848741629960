/**
 * Group the elements based on the key generated by the provided function
 * @param elements the elements to be grouped
 * @param keyFn function that obtains the a key for an element
 * @returns a map from keys to array of elements with same key
 */
export function groupByInMap<T> (elements: T[], keyFn: (e: T) => string): Map<string, T[]> {
  return elements.reduce<Map<string, T[]>>((gs, e) => {
    const key = keyFn(e)
    gs.set(key, [...(gs.get(key) || []), e])
    return gs
  }, new Map())
}

/**
 * Group the elements based on the keys generated by the provided function.
 * A same element can be in many groups if the `keyFn` generates many keys for it
 * @param elements the elements to be grouped
 * @param keyFn function that obtains an array of keys for an element, if the array is empty, the element is ignored
 * @returns a map from keys to array of elements with same key
 */
export function groupByManyInMap<T> (elements: T[], keyFn: (e: T) => Array<string>): Map<string, T[]> {
  return elements.reduce<Map<string, T[]>>((gs, e) => {
    keyFn(e)?.forEach(key => gs.set(key, [...(gs.get(key) || []), e]))
    return gs
  }, new Map())
}

/**
 * Group the elements based on the key generated by the provided function
 * @param elements the elements to be grouped
 * @param keyFn function that obtains the a key for an element
 * @returns aa array of keys and array of elements with same key
 */
export function groupBy<T> (elements: T[], keyFn: (e: T) => string): [string, T[]][] {
  return [...groupByInMap(elements, keyFn).entries()]
}
