export enum TableName {
  CoursePerformanceView = 'COURSE_PERFORMANCE_VIEW',
  CourseFinishedPerformanceView = 'COURSE_PERFORMANCE_FINISHED_VIEW',
  PlanningCoursesView = 'PLANNING_COURSES_VIEW',
  TeachersRegularView = 'TEACHERS_REGULAR_VIEW',
  TeacherReinfoView = 'TEACHERS_REINFO_VIEW',
  TutorsView = 'TUTORS_VIEW',
  CoordinatorView = 'COORDINATOR_VIEW'
}

export enum TypeFilter {
  View = 'VIEW',
  Filter = 'FILTER',
}
