
import { computed, defineComponent, onBeforeMount, onMounted, Prop, PropType, ref, watch } from 'vue'
import { getTranslationsEntries } from '@/api/translations.api'
import { TranslationEntry } from '@/models/translations/TranslationEntry.model'
import { SetMail } from '@/models/email-sending/SetMail'
import { EmailSendingAPI } from '@/api/email-sending.api'
import { ActivityRecordAPI } from '@/api/activity-record.api'
import { EmailData } from '@/models/email-sending/EmailData'
import { DataRecord } from '@/models/activity-record/ActivityRecord'
import { useUserSessionStore } from '@/store/modules/commons/user-session-store'
import { CoderEmails } from '@/utils/enums'
import { useToast } from 'primevue/usetoast'
import { StaffTracing } from '@/models/tracing-staff/classes/StaffTracing'
import { ProfileEducation } from '@/models/education-students/classes/education-student-profile'

export default defineComponent({
  props: {
    visible: {
      type: Boolean
    },
    setMail: {
      type: Object as PropType<SetMail>,
      require: true
    },
    contextId: {
      type: String,
      require: true
    },
    tracingInfo: {
      type: StaffTracing,
      require: true
    },
    activity: {
      type: Boolean,
      required: true,
      default: true
    },
    userName: {
      type: String,
      required: true
    }
  },
  emits: ['update:visible'],
  setup (props, { emit }) {
    let emailTemplates: TranslationEntry[] = []
    const titles = Array<any>()
    const contextId = ref<string>(props.contextId as string)
    const objEmail = computed(() => props.setMail)
    const visibleProp = computed(() => props.visible)
    const isVisible = ref<boolean>(visibleProp.value)
    const subject = ref<string>()
    const body = ref<string>()
    const selectedTemplate = ref<string>()
    const toast = useToast()
    const tracingInfo = ref<StaffTracing | any>(props.tracingInfo!)
    const isLoading = ref(false)

    onBeforeMount(() => {
      isLoading.value = true
    })

    const loadTemplate = (name: string, nameTemplate: string, keyTemplate: string) => {
      if (objEmail.value) {
        selectedTemplate.value = keyTemplate
        const country = objEmail.value.country
        const template = emailTemplates.find(t => t.key === (nameTemplate + '.body'))
        const mapLanguage = new Map([['ar', template?.published.es_AR], ['mx', template?.published.es_MX], ['br', template?.published.es_BR], ['es', template?.published.es_ES]])
        subject.value = name
        body.value = (mapLanguage.get(country) || template?.published.es_ES) as string
        if (props.activity) {
          body.value = body.value.replaceAll('%camadaid%', tracingInfo.value?.camadaInfo.id ?? '--')
          body.value = body.value.replaceAll('%username%', `${tracingInfo.value?.userInfo.profile.firstName} ${tracingInfo.value?.userInfo.profile.lastName} ` ?? '--')
        } else {
          body.value = body.value.replaceAll('%username%', `${props.userName} ` ?? '--')
        }
      }
    }
    const sendMail = () => {
      if (objEmail.value && objEmail.value.toMail && subject.value && body.value) {
        const emailData = new EmailData([{ email: objEmail.value.toMail }], { email: CoderEmails.defaultEmail }, subject.value, body.value)
        EmailSendingAPI.sendEmail(emailData).then(() => {
          toast.add({ severity: 'success', detail: 'El mail se ha enviado correctamente.', life: 3500 })
          const session = useUserSessionStore()
          const dataRecord = new DataRecord(
            objEmail.value?.userId as string,
            session.currentUser._id,
            selectedTemplate.value as string,
            { subject: subject.value as string, body: body.value as string, sended: true },
            null,
            null,
            null,
            null,
            null,
            null,
            null
          )
          ActivityRecordAPI.activityRecord(dataRecord)
        }).catch(
          (err: unknown) => {
            toast.add({ severity: 'error', summary: 'Error al enviar el mail', detail: String(err), life: 3500 })
          }
        ).finally(
          () => {
            endMailSending()
          }
        )
      }
    }
    const endMailSending = () => {
      subject.value = ''
      body.value = ''
      selectedTemplate.value = ''
      emit('update:visible', false)
    }
    async function getTemplates () {
      emailTemplates = await getTranslationsEntries(contextId.value)
      const templates = emailTemplates.filter(t => t.key.endsWith('.title'))
      templates.forEach(t => {
        const objTemp = Object()
        const keySplitted = t.key.split('.')// 0:'emails'|1:templateName|2:type
        objTemp.templateName = keySplitted.slice(0, keySplitted.length - 1).join('.')
        objTemp.value = t.published.es_ES?.replaceAll('%camadaid%', tracingInfo.value?.camadaInfo.id ?? '--')
        objTemp.keyTemplate = t.key
        objTemp.checked = false
        objTemp.group = 'rb'
        titles.push(objTemp)
      })
    }

    watch(visibleProp, () => { isVisible.value = visibleProp.value })

    onMounted(async () => {
      try {
        getTemplates()
      } catch (error: any) {
        console.log(error)
      } finally {
        isLoading.value = false
      }
    })

    return {
      isVisible,
      titles,
      objEmail,
      subject,
      body,
      selectedTemplate,
      loadTemplate,
      sendMail,
      endMailSending,
      isLoading
    }
  }
})

