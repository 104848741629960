import { Api } from './api'

import { PersonFeedbackSummary } from '@/models/recruitment/interfaces'
import {
  ICourseFeedbackSummaryResponse,
  ICourseFeedbackSummaryResponseProcessed
} from '@/models/interfaces/dto'
import { PersonRoles } from '@/models/person/enums'
import { CourseFeedback } from '@/models/course-feedbacks/classes/CourseFeedback'
import { CourseFeedbackByPerson } from '@/models/course-feedbacks/interfaces/CourseFeedBackByPerson'
import { IClassFeedbackSummary } from '@/models/course-feedbacks/summaries/interfaces/IClassFeedbackSummary'
import { ClassFeedbackSummary } from '@/models/course-feedbacks/summaries/classes/ClassFeedbackSummary'
import {
  IFeedbackSummary,
  IFeedbackSummaryTotal
} from '@/models/course-feedbacks/summaries/interfaces/IFeedbackSummary'
import {
  CommentNPS,
  CommentsNPSInfo,
  CommentSummary,
  ICommentsNPSInfo
} from '@/models/course-feedbacks/summaries/interfaces/CommentSummary'
import { ICourseFeedback } from '@/models/course-feedbacks/interfaces/dto/CourseFeedback'
import { CamadaStatus } from '@/models/course-feedbacks/summaries/interfaces/ICamadaStatus'

class CourseFeedbackApi {
  private readonly baseURL: string

  constructor() {
    this.baseURL = process.env.ACADEMIC_API_ENDPOINT
  }

  parseComments(comment: CommentNPS): CommentNPS {
    return new CommentNPS(
      comment._id,
      comment.personId,
      comment.role,
      comment.courseId,
      comment.status,
      comment.v1CourseId,
      comment.schedule,
      comment.entries,
      comment.contentFeedback,
      comment.npsFeedback,
      comment.original,
      comment.comment,
      comment.infoUser
    )
  }

  parseCommentsNPSInfo(commentsNPSinfo: ICommentsNPSInfo): CommentsNPSInfo {
    return new CommentsNPSInfo(
      commentsNPSinfo.comments,
      commentsNPSinfo.detractors,
      commentsNPSinfo.passives,
      commentsNPSinfo.promoters,
      commentsNPSinfo.score,
      commentsNPSinfo.ranks
    )
  }

  private async findAllByRole(
    courseId: string,
    role: PersonRoles = PersonRoles.Student
  ): Promise<CourseFeedbackByPerson> {
    const dto = await Api.get<ICourseFeedback[]>(
      `${this.baseURL}/v1/courses/${courseId}/feedbacks`,
      { role }
    )
    const feedbacks = dto.map((cf) => new CourseFeedback(cf))
    return CourseFeedback.parseToFeedbackByPerson(feedbacks)
  }

  public async findAllByStudents(
    courseId: string
  ): Promise<CourseFeedbackByPerson> {
    const feedbacks = await this.findAllByRole(courseId, PersonRoles.Student)
    return new Map(feedbacks)
  }

  public async findAllByStaff(
    courseId: string
  ): Promise<CourseFeedbackByPerson> {
    const teacherFeedbacks = await this.findAllByRole(
      courseId,
      PersonRoles.Teacher
    )
    const tutorsFeedbacks = await this.findAllByRole(
      courseId,
      PersonRoles.Tutor
    )
    return new Map([
      ...Array.from(teacherFeedbacks.entries()),
      ...Array.from(tutorsFeedbacks.entries())
    ])
  }

  public async findAllAfterClass(
    courseId: string
  ): Promise<CourseFeedbackByPerson> {
    const dto = await Api.get<ICourseFeedback[]>(
      `${this.baseURL}/v1/courses/${courseId}/feedbacks/afterClass`
    )
    const feedbacks = dto.map((cf) => new CourseFeedback(cf))
    return CourseFeedback.parseToFeedbackByPerson(feedbacks)
  }

  public async findNPSComments(courseId: string): Promise<CommentsNPSInfo> {
    const feedbacks = await Api.get<ICommentsNPSInfo>(
      `${this.baseURL}/v1/courses/${courseId}/feedbacks/NPScomments`
    )
    return this.parseCommentsNPSInfo(feedbacks)
  }

  public async findAllNPS(
    courseId: string,
    role: PersonRoles = PersonRoles.Student
  ): Promise<CourseFeedbackByPerson> {
    const dto = await Api.get<ICourseFeedback[]>(
      `${this.baseURL}/v1/courses/${courseId}/feedbacks`,
      { role, nps: 'true' }
    )
    const feedbacks = dto.map((cf) => new CourseFeedback(cf))
    return CourseFeedback.parseToFeedbackByPerson(feedbacks)
  }

  public async reprocessFeedback(commissionNumber: string): Promise<void> {
    await Api.put<void>(
      `${this.baseURL}/v1/typeforms/${commissionNumber}/reprocess`,
      {}
    )
  }
}

class CourseFeedbackSummaryApi {
  private readonly baseURL: string

  constructor() {
    this.baseURL = process.env.ACADEMIC_API_ENDPOINT
  }

  private parseDTOToSummary(
    foundSummary: IClassFeedbackSummary
  ): ClassFeedbackSummary {
    return new ClassFeedbackSummary(
      foundSummary.className,
      foundSummary.classId,
      foundSummary.classNumber,
      foundSummary.totalClass,
      foundSummary.contentSummary as IFeedbackSummary,
      foundSummary.contentSummaryTotal,
      foundSummary.totalDeliveries,
      foundSummary.totalFinalDeliveries,
      foundSummary.totalGraduates,
      foundSummary.courseId,
      foundSummary.courseNumber,
      foundSummary.courseName,
      foundSummary.country,
      foundSummary.date,
      foundSummary.start,
      foundSummary.end,
      foundSummary.limitDate,
      foundSummary.personSummary as Record<string, IFeedbackSummary>,
      foundSummary.personSummaryTotal as Record<string, IFeedbackSummaryTotal>,
      foundSummary.teacherSummary as IFeedbackSummary,
      foundSummary.teacherSummaryTotal,
      foundSummary.tutorSummary as IFeedbackSummary,
      foundSummary.tutorSummaryTotal,
      foundSummary.coordinatorSummary as IFeedbackSummary,
      foundSummary.coordinatorSummaryTotal,
      foundSummary.npsSummary as IFeedbackSummary,
      foundSummary._id as string,
      foundSummary.days,
      foundSummary.tags,
      foundSummary.classDays,
      foundSummary.courseDates
    )
  }

  parseCommentSummary(commentSummary: CommentSummary): CommentSummary {
    return new CommentSummary(
      commentSummary._id,
      commentSummary.comments,
      commentSummary.info
    )
  }

  parsePersonFeedbackSummary(
    personFeedbackSummary: PersonFeedbackSummary
  ): PersonFeedbackSummary {
    return new PersonFeedbackSummary(
      personFeedbackSummary._id,
      personFeedbackSummary.start,
      personFeedbackSummary.end,
      personFeedbackSummary.courseName,
      personFeedbackSummary.feedbackCount,
      personFeedbackSummary.normalized,
      personFeedbackSummary.rating,
      personFeedbackSummary.role,
      personFeedbackSummary.courseNumber
    )
  }

  public async findLastFromCourses(
    query: Record<string, string | boolean | number | Date>
  ): Promise<ICourseFeedbackSummaryResponseProcessed> {
    const res = await Api.get<ICourseFeedbackSummaryResponse>(
      `${this.baseURL}/v1/courses/feedbacks/summaries`,
      query
    )
    return {
      ...res,
      data: res.data.map(this.parseDTOToSummary)
    }
  }

  public async findAll(courseId: string): Promise<Array<ClassFeedbackSummary>> {
    const foundSummaries = await Api.get<Array<IClassFeedbackSummary>>(
      `${this.baseURL}/v1/courses/feedbacks/summaries/${courseId}`
    )
    return foundSummaries.map(this.parseDTOToSummary)
  }

  public async findAllComments(
    courseId: string
  ): Promise<Array<CommentSummary>> {
    const foundComments = await Api.get<Array<CommentSummary>>(
      `${this.baseURL}/v1/courses/feedbacks/summaries/comments/${courseId}`
    )
    return foundComments.map(this.parseCommentSummary)
  }

  public async findAfterClass(
    courseId: string
  ): Promise<Array<ClassFeedbackSummary>> {
    const foundSummaries = await Api.get<Array<IClassFeedbackSummary>>(
      `${this.baseURL}/v1/courses/feedbacks/summaries/${courseId}`
    )
    return foundSummaries.map(this.parseDTOToSummary)
  }

  public async findNPS(courseId: number): Promise<Array<ClassFeedbackSummary>> {
    const foundSummaries: IClassFeedbackSummary[] = await Api.get(
      `${this.baseURL}/v1/courses/feedbacks/summaries/${courseId}`,
      { nps: 'true' }
    )
    return foundSummaries.map(this.parseDTOToSummary)
  }

  public async findByPersonIdAndCourseIds(
    personId: string,
    courseIds: string[]
  ): Promise<PersonFeedbackSummary[]> {
    const summaries = await Api.get<PersonFeedbackSummary[]>(
      `${this.baseURL}/v1/courses/feedbacks/summaries/person/${personId}`,
      { courseIds: courseIds.join(',') }
    )
    return summaries.map(this.parsePersonFeedbackSummary)
  }

  public async getCamadaStatus(
    commissionNumber: string
  ): Promise<CamadaStatus> {
    return Api.get<CamadaStatus>(
      `${this.baseURL}/v1/typeforms/camada/${commissionNumber}/status`
    )
  }
}

export const CourseFeedbackAPI = new CourseFeedbackApi()
export const CourseFeedbackSummaryAPI = new CourseFeedbackSummaryApi()
