import { defineStore } from 'pinia'
import { Locale } from '@/models/locale/locale'
import { getLocale } from '@/api/locale.api'
import { Language } from '@/utils/locale'
import { useI18nStore } from './i18n-store'

export const useLocaleStore = defineStore({
  id: 'locale',
  state: () => ({
    locale: null as Locale | null
  }),
  getters: {
    getLocale (): Locale | null {
      return this.locale
    }
  },
  actions: {
    async fetchLocale () {
      const lang = Language.get('es') as string
      this.locale = await getLocale(lang)
      useI18nStore().setLocale(this.locale?.locale)
      useI18nStore().setLocaleMessages(
        this.locale?.locale,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.locale?.translations
      )
    }
  }
})
