import { IStaffList } from '@/models/staff-list/interfaces/StaffList.dto'
import { StaffData } from '@/models/staff-list/classes/StaffList'
import { Api } from './api'

class StaffListAPI {
  private readonly baseURL: string

  constructor() {
    this.baseURL = process.env.ACADEMIC_API_ENDPOINT
  }

  private returnStaffList(iStaffList: IStaffList): StaffData {
    return new StaffData(
      iStaffList.count,
      iStaffList.totalPages,
      iStaffList.skip,
      iStaffList.limit,
      iStaffList.data
    )
  }

  public async getStaffList(
    query: Record<string, string | boolean | number | Date>
  ): Promise<StaffData> {
    const data = await Api.get<IStaffList>(
      `${this.baseURL}/v1/staff/summary`,
      query
    )
    const res = this.returnStaffList(data)
    return res
  }
}

export const StaffListApi = new StaffListAPI()
