export enum CountriesFlags {
  all = require('@/assets/icons/flags/All.svg'),
  ar = require('@/assets/icons/flags/Argentina.svg'),
  cl = require('@/assets/icons/flags/Chile.svg'),
  pe = require('@/assets/icons/flags/Peru.svg'),
  mx = require('@/assets/icons/flags/Mexico.svg'),
  es = require('@/assets/icons/flags/Spain.svg'),
  co = require('@/assets/icons/flags/Columbia.svg'),
  uy = require('@/assets/icons/flags/Uruguay.svg'),
  usa = require('@/assets/icons/flags/USA.svg'),
  br = require('@/assets/icons/flags/Brazil.svg')
}
