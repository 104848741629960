import axios from 'axios'

import { ApplicationUser } from '@/models/user-session/interfaces/ApplicationUser'
import { LoginResult } from '@/models/user-session/interfaces/LoginResult'

const baseURL = process.env.API_ENDPOINT

async function login(
  userName: string,
  password: string
): Promise<{ user: ApplicationUser; token: string }> {
  const data = {
    email: userName,
    password
  }

  const {
    data: { user, token, error }
  } = await axios.post<LoginResult>(`${baseURL}/private/auth`, data)

  if (error) throw error

  return { user, token: token || '' }
}

async function logout(): Promise<void> {
  delete axios.defaults.headers.common.Authorization
}

export default {
  login,
  logout
}
