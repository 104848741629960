import { ContractGroups } from './contract-groups.enum'
import { ContractRoles } from './contract-roles.enum'
import { ContractStatus } from './contract-status.enum'

export interface IEnrollmentContract {
  createdAt: string
  expirationDate: string
  firstName: string
  id: string
  lastName: string
  role: ContractRoles
  group: ContractGroups
  status: ContractStatus
}

export class EnrollmentContract implements Omit<IEnrollmentContract, 'expirationDate' | 'createdAt'> {
  createdAt: Date
  expirationDate: Date
  constructor (
    public firstName: string,
    public id: string,
    public lastName: string,
    public role: ContractRoles,
    public group: ContractGroups,
    public status: ContractStatus,
    createdAt: string,
    expirationDate: string
  ) {
    this.createdAt = new Date(createdAt)
    this.expirationDate = new Date(expirationDate)
  }
}
