import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-7b5db73e")
const _hoisted_1 = { class: "code-box p-mb-3" }
const _hoisted_2 = { ref: "codeRef" }
const _hoisted_3 = {
  key: 1,
  ref: "codeRef"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_ScrollPanel = _resolveComponent("ScrollPanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Button, {
      onClick: _ctx.copy,
      class: "copy-button  p-button-primary",
      icon: "pi pi-copy"
    }, null, 8, ["onClick"]),
    (_ctx.scrollHeightPx || _ctx.scrollWidthPx)
      ? (_openBlock(), _createBlock(_component_ScrollPanel, {
          key: 0,
          style: _normalizeStyle({
        height: _ctx.scrollHeightPx ? `${_ctx.scrollHeightPx}px` : 'auto',
        width: _ctx.scrollWidthPx ? `${_ctx.scrollWidthPx}px` : 'auto'
      })
        }, {
          default: _withCtx(() => [
            _createElementVNode("code", _hoisted_2, [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ], 512)
          ]),
          _: 3
        }, 8, ["style"]))
      : (_openBlock(), _createElementBlock("code", _hoisted_3, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 512))
  ]))
}