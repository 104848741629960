export class SendedData {
  subject: string
  body: string
  sended: boolean
  constructor (
    subject: string,
    body: string,
    sended: boolean
  ) {
    this.subject = subject
    this.body = body
    this.sended = sended
  }
}

export class DataRecord {
  user?: string | null
  author?: string | null
  key?: string | null
  email?: SendedData | null
  course?: string | null
  date?: Date | null
  reminderDate?: Date | null
  type?: string | null
  stage?: string | null
  image?: string | null
  description?: string | null
  constructor (
    user: string | null,
    author: string | null,
    key: string | null,
    email: SendedData | null,
    course: string | null,
    date: Date | null,
    reminderDate: Date | null,
    type: string | null,
    stage: string | null,
    image: string | null,
    description: string | null
  ) {
    this.user = user
    this.author = author
    this.key = key
    this.email = email
    this.course = course
    this.date = date
    this.reminderDate = reminderDate
    this.type = type
    this.stage = stage
    this.image = image
    this.description = description
  }
}

export class DataRecordResponse {
  user: string
  author: string
  key: string
  email: SendedData
  course?: string
  date?: Date
  reminderDate?: Date
  type?: string
  stage?: string
  image?: string
  description?: string
  id?: string
  enable?: boolean
  createdAt?: Date
  updatedAt?: Date
  __v?: number
  constructor (
    user: string,
    author: string,
    key: string,
    email: SendedData,
    course: string,
    date: Date,
    reminderDate: Date,
    type: string,
    stage: string,
    image: string,
    description: string,
    id: string,
    enable: boolean,
    createdAt: Date,
    updatedAt: Date,
    __v: number
  ) {
    this.user = user
    this.author = author
    this.key = key
    this.email = email
    this.course = course
    this.date = date
    this.reminderDate = reminderDate
    this.type = type
    this.stage = stage
    this.image = image
    this.description = description
    this.id = id
    this.enable = enable
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.__v = __v
  }
}
