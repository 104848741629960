
import { findActivePeople, findAllPopulatedPeoplePosition, findPeoplePosition, removePosition, updatePosition } from '@/api/people.api'
import PeopleChart from '@/components/people/cards/PeopleChart.vue'
import PositionEdit from '@/components/people/input/PositionEdit.vue'
import StructureEdit from '@/components/people/input/StructureEdit.vue'
import { OrgPosition, PersonNode, PersonPositionPopulatedDto } from '@/models/organization'
import { useEnvironmentStore } from '@/store/modules/environment-store'
import { compareByName } from '@/utils/entities'
import { useConfirm } from 'primevue/useconfirm'
import { useToast } from 'primevue/usetoast'
import { defineComponent, onMounted, ref, watch } from 'vue'
import ZoomPanel from '@/components/commons/ZoomPanel.vue'

export default defineComponent({
  name: 'Structure',
  components: {
    PositionEdit,
    PeopleChart,
    StructureEdit,
    ZoomPanel
  },
  setup () {
    const environmentStore = useEnvironmentStore()
    const toast = useToast()
    const confirm = useConfirm()
    const positions = ref<Array<PersonPositionPopulatedDto>>([])
    const graphMode = ref(true)
    const editMode = ref(false)
    const selectedPosition = ref<PersonPositionPopulatedDto>({} as PersonPositionPopulatedDto)
    const activePeople = ref<Array<PersonNode>>([])

    onMounted(async () => {
      positions.value = await findAllPopulatedPeoplePosition()
      selectedPosition.value = positions.value && positions.value[0]
    })

    watch(editMode, async (newEditMode) => {
      if (newEditMode) {
        activePeople.value = (await findActivePeople()).sort(compareByName)
      }
    })
    const onSelected = async (position: OrgPosition) => {
      selectedPosition.value = await findPeoplePosition(position._id)
    }

    const save = async (position: PersonPositionPopulatedDto, newAreaName?: string) => {
      try {
        await updatePosition(position, newAreaName)
        toast.add({ severity: 'info', summary: 'Posición actualizada', detail: position.name, life: 3000 })
        positions.value = await findAllPopulatedPeoplePosition()
        selectedPosition.value = positions.value.find(p => p._id === selectedPosition.value._id) || positions.value[0]
      } catch (err: unknown) {
        toast.add({ severity: 'error', summary: 'Error al actualizar posición', detail: String(err), life: 3000 })
      }
    }

    const deletePosition = (position: PersonPositionPopulatedDto) => {
      confirm.require({
        group: 'global',
        message: `¿Está seguro de remover la posición ${position.name}?`,
        header: 'Remover posición',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          try {
            await removePosition(position._id)
            toast.add({ severity: 'info', summary: 'Removido', detail: `Se removió la posición ${position.name}`, life: 3000 })
            positions.value = await findAllPopulatedPeoplePosition()
            selectedPosition.value = positions.value.find(p => p._id === position.parent?._id) || positions.value[0]
          } catch (err: unknown) {
            toast.add({ severity: 'error', summary: 'Error al remover la posición', detail: String(err), life: 3000 })
          }
        }
      })
    }

    return {
      editMode,
      graphMode,
      onSelected,
      orgChartEditEnabled: environmentStore.orgChartEditEnabled,
      positions,
      selectedPosition,
      save,
      deletePosition,
      activePeople
    }
  }
})
