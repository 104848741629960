import { PersonRoles } from '@/models/person/enums'

export interface CourseAssignedByRole {
  substitudteacher: string[]
  assistant: string[]
  assistantSubstituteTeacher: string[]
  coordinator: string[]
  repleacementTeacher: string[]
  reviewerTeachers: string[]
  supervisor: string[]
  teacher: string[]
  tutor: string[]
}

export interface PersonFeedbackSummary {
  _id: string
  start: string
  end: string
  courseName: string
  feedbackCount: number | null
  normalized: number | null
  rating: number | null
  role: PersonRoles
  courseNumber: number
}

export class PersonFeedbackSummary {
  _id: string
  start: string
  end: string
  courseName: string
  feedbackCount: number | null
  normalized: number | null
  rating: number | null
  role: PersonRoles
  courseNumber: number
  constructor (
    _id: string,
    start: string,
    end: string,
    courseName: string,
    feedbackCount: number | null,
    normalized: number | null,
    rating: number | null,
    role: PersonRoles,
    courseNumber: number
  ) {
    this._id = _id
    this.start = start
    this.end = end
    this.courseName = courseName
    this.feedbackCount = feedbackCount
    this.normalized = normalized
    this.rating = rating
    this.role = role
    this.courseNumber = courseNumber
  }
}
