export enum ToastSeverities {
  Error = 'error',
  Success = 'success',
  Info = 'info',
  Warn = 'warn'
}

export enum ToastLife {
  Default = 3000
}

export enum ToastSummaries {
  Info = 'Información',
  Error = '¡Error!',
  Warn = 'Advertencia',
  Copied = 'Copiado',
  Deleted = 'Eliminado',
  Editing = 'Editando',
  Edited = 'Editado',
  Saved = 'Guardado',
  Success = 'Realizado con éxito',
  SuccessfullyDone = 'Realizado con éxito',
}
