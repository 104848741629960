
import { defineComponent, onBeforeMount, ref } from 'vue'
import { useRoute } from 'vue-router'
import { StaffSquadsAPI } from '@/api/staff-squads.api'
import { getProducts } from '@/api/products.api'
import { StaffSquadProductDto } from '@/models/staff-squads/interfaces/StaffSquadProduct.dto'
import { StaffSquadDto } from '@/models/staff-squads/interfaces/StaffSquad.dto'
import { Forms } from '@/models/enums'
import { ToastLife, ToastSeverities } from '@/models/components/toast/enums'
import Dropdown from 'primevue/dropdown'
import InputText from 'primevue/inputtext'
import DataTable from 'primevue/datatable'
import { useToast } from 'primevue/usetoast'
import Panel from 'primevue/panel'
import { Product } from '@/models/www/Products'
import { RouteNames } from '@/router/route-names'
import router from '@/router'

export default defineComponent({
  components: {
    Dropdown,
    InputText,
    DataTable,
    Panel
  },
  setup () {
    const isLoading = ref(true)
    const allProducts = ref<Product[]>([])
    const squad = ref<StaffSquadDto>()
    const products = ref<Array<StaffSquadProductDto>>([])
    const selectedProduct = ref()
    const toast = useToast()
    const { params } = useRoute()

    onBeforeMount(async () => {
      const mock = {} as StaffSquadDto
      squad.value = params.squadId === Forms.new ? mock : await StaffSquadsAPI.findById(params.squadId as string)
      products.value = (squad.value?.products || [])
      const { remotes } = await getProducts()
      allProducts.value = remotes
      isLoading.value = false
    })

    return {
      isLoading,
      squad,
      allProducts,
      products,
      selectedProduct,
      onClickBack () {
        router.push({ name: RouteNames.STAFF_SQUADS })
      },
      onClickAddProduct (item: StaffSquadProductDto) {
        products.value.push(item)
      },
      onClickRemoveProduct (item: StaffSquadProductDto) {
        products.value = products.value.filter(product => product._id !== item._id)
      },
      async onSubmit () {
        try {
          if (!products.value?.length) {
            toast.add({
              summary: 'Necesitas seleccionar al menos un curso',
              severity: ToastSeverities.Error,
              life: ToastLife.Default
            })
            return
          }
          const { _id: squadId } = await StaffSquadsAPI.upsert(Object.assign({}, squad.value, { products: products.value.map(({ _id }) => _id) }))
          toast.add({
            summary: 'Guardado',
            severity: ToastSeverities.Success,
            life: ToastLife.Default
          })
          if (!squad.value?._id) router.push({ name: RouteNames.STAFF_SQUAD, params: { squadId } })
        } catch (e: any) {
          toast.add({
            summary: 'Error al guardar',
            detail: e.message,
            severity: ToastSeverities.Error,
            life: ToastLife.Default
          })
        }
      },
      async onClickRemove () {
        try {
          if (!confirm('Seguro?')) return
          if (squad.value?._id) await StaffSquadsAPI.remove(squad.value._id)
          toast.add({
            summary: 'Borrado',
            severity: ToastSeverities.Success,
            life: ToastLife.Default
          })
          router.push({ name: RouteNames.STAFF_SQUADS })
        } catch (e: any) {
          toast.add({
            summary: 'Error al guardar',
            detail: e.message,
            severity: ToastSeverities.Error,
            life: ToastLife.Default
          })
        }
      }
    }
  }
})
