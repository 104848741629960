import { ContractStatus } from '@/models/contracts'
import { CSSColors } from '@/models/enums'

export function contractStatusTextColor (value: string | ContractStatus): CSSColors {
  switch (value) {
    case ContractStatus.PendingSignature:
      return CSSColors.RedBrain
    case ContractStatus.Signed:
      return CSSColors.GreenBrain
    case ContractStatus.Expired:
      return CSSColors.YellowBrain
    case ContractStatus.Rejected:
      return CSSColors.Black
    case ContractStatus.PreSigned:
      return CSSColors.RedBrain
    case ContractStatus.Cancelled:
      return CSSColors.RedBrain
    default:
      return CSSColors.Black
  }
}
