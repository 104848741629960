import { findPersons, findPersonById } from '@/api/people.api'
import { Person } from './Person'

export class MapPerson {
  private _mapper: Map<string, Person>
  constructor () {
    this._mapper = new Map()
  }

  async setPerson (ids: string[]): Promise<void> {
    this._mapper = await findPersons(ids)
  }

  async getPerson (id: string): Promise<Person> {
    let person = this._mapper.get(id)
    if (!person) {
      person = await findPersonById(id)
      this._mapper.set(id, person)
    }
    return person
  }

  Itarator (): Map<string, Person> {
    return this._mapper
  }
}
