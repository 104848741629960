import usersApi from '@/api/users.api'
import { UserRoles } from '@/models/user-session/enums'
import { ApplicationUser } from '@/models/user-session/interfaces/ApplicationUser'
import { UserSessionState } from '@/models/user-session/interfaces/UserSessionState'
import axios from 'axios'

import { defineStore } from 'pinia'//

const notLoggedUser: ApplicationUser = {
  _id: '',
  enable: false,
  name: '',
  email: '',
  createdAt: '',
  updatedAt: '',
  area: '',
  avatar: '',
  position: '',
  roles: UserRoles.notLoggedUser
}

const missingApiToken = ''

const CurrentUserKey = 'current-user'
const TokenKey = 'current-token'

function getStoredCurrentUser (): ApplicationUser | undefined {
  const userJson = localStorage.getItem(CurrentUserKey)
  return userJson ? (JSON.parse(userJson) as ApplicationUser) : undefined
}

function getStoredToken (): string | undefined {
  const token = localStorage.getItem(TokenKey)
  return token || undefined
}

function updateAxios (apiToken: string) {
  if (apiToken) {
    axios.defaults.headers.common.Authorization = `Bearer ${apiToken}` // TODO: What is Bearer
  } else {
    delete axios.defaults.headers.common.Authorization
  }
}

//
export const useUserSessionStore = defineStore({
  // unique id of the store across your application
  id: 'user-session',
  state () {
    const userSession: UserSessionState = {
      currentUser: getStoredCurrentUser() || notLoggedUser,
      apiToken: getStoredToken() || missingApiToken,
      loginError: undefined
    }

    updateAxios(userSession.apiToken)

    return userSession
  },
  getters: {
    isAuthenticated (): boolean {
      return !!this.apiToken
    }
  },
  actions: {
    async login (userName: string, password: string) {
      try {
        this.loginError = undefined
        const userSession = await usersApi.login(userName, password)

        this.currentUser = userSession.user
        this.apiToken = userSession.token

        updateAxios(this.apiToken)

        localStorage.setItem(CurrentUserKey, JSON.stringify(userSession.user))
        localStorage.setItem(TokenKey, userSession.token)
      } catch (err) {
        console.log(`Login failed with: ${err}`)
        this.loginError = err
        this.apiToken = missingApiToken
      }
    },

    async logout () {
      await usersApi.logout()
      localStorage.removeItem(CurrentUserKey)
      localStorage.removeItem(TokenKey)
      this.apiToken = missingApiToken
      this.loginError = undefined
    }
  }
})
