import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-71731cfa")
const _hoisted_1 = { key: 1 }
const _hoisted_2 = {
  key: 1,
  class: "p-grid p-nogutter"
}
const _hoisted_3 = {
  key: 0,
  class: "p-col-2"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingScreen = _resolveComponent("LoadingScreen")!
  const _component_ModuleHeader = _resolveComponent("ModuleHeader")!
  const _component_ToggleButton = _resolveComponent("ToggleButton")!
  const _component_StructureEdit = _resolveComponent("StructureEdit")!
  const _component_PeopleChart = _resolveComponent("PeopleChart")!
  const _component_ZoomPanel = _resolveComponent("ZoomPanel")!
  const _component_PositionEdit = _resolveComponent("PositionEdit")!
  const _component_Panel = _resolveComponent("Panel")!

  return (!_ctx.positions.length)
    ? (_openBlock(), _createBlock(_component_LoadingScreen, { key: 0 }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ModuleHeader, { title: "Organigrama" }),
        _createVNode(_component_Panel, { header: "Organigrama" }, {
          icons: _withCtx(() => [
            false
              ? (_openBlock(), _createBlock(_component_ToggleButton, {
                  key: 0,
                  modelValue: _ctx.graphMode,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.graphMode = $event)),
                  onIcon: "pi pi-list",
                  offIcon: "pi pi-sitemap"
                }, null, 8, ["modelValue"]))
              : _createCommentVNode("", true),
            (_ctx.orgChartEditEnabled)
              ? (_openBlock(), _createBlock(_component_ToggleButton, {
                  key: 1,
                  modelValue: _ctx.editMode,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.editMode = $event)),
                  onIcon: "pi pi-pencil",
                  offIcon: "pi pi-pencil"
                }, null, 8, ["modelValue"]))
              : _createCommentVNode("", true)
          ]),
          default: _withCtx(() => [
            (!_ctx.graphMode)
              ? (_openBlock(), _createBlock(_component_StructureEdit, { key: 0 }))
              : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("div", {
                    class: _normalizeClass(!_ctx.editMode ? 'p-col-12' : 'p-col-10')
                  }, [
                    _createVNode(_component_ZoomPanel, {
                      wheelZoomEnabled: false,
                      initialZoomPercentage: 80
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_PeopleChart, {
                          onSelected: _ctx.onSelected,
                          editMode: _ctx.editMode,
                          positions: _ctx.positions,
                          centerOn: _ctx.selectedPosition
                        }, null, 8, ["onSelected", "editMode", "positions", "centerOn"])
                      ]),
                      _: 1
                    })
                  ], 2),
                  (_ctx.editMode)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        (_ctx.selectedPosition)
                          ? (_openBlock(), _createBlock(_component_PositionEdit, {
                              key: 0,
                              position: _ctx.selectedPosition,
                              people: _ctx.activePeople,
                              positions: _ctx.positions,
                              onSave: _ctx.save,
                              onDelete: _ctx.deletePosition
                            }, null, 8, ["position", "people", "positions", "onSave", "onDelete"]))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true)
                ]))
          ]),
          _: 1
        })
      ]))
}