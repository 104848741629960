export enum FeedbackCharacter {
  Positive = '+',
  Neutral = ' ',
  Negative = '-',
}

export enum EvaluateSummary {
  general = 'general',
  teacher = 'teacher',
  tutor = 'tutor',
  content = 'content',
  staff = 'staff',
  nps = 'nps'
}

export enum CourseFeedbackTag {
  AfterClass = 'afterclass',
  B2c = 'b2c',
  B2b = 'b2b',
  Workshop = 'workshop',
  Intensive = 'intensive',
  Nps = 'nps',
}

export enum CourseTypeTag {
  B2b = 'B2B',
  Workshop = 'W',
  Intensive = 'INT'
}

export enum ClassAverageClassification {
  Good = 'good',
  Bad = 'bad'
}
