import { Rank } from '@/models/components/tables/Row'
import { PersonRoles } from '@/models/person/enums'
import { FeedbackEntry } from '../../interfaces/dto/FeedbackEntry'
import { FeedbackOriginalFormData } from '../../interfaces/dto/FeedbackOriginalFormData'
import { FeedbackSchedule } from '../../interfaces/dto/FeedbackSchedule'
import { PersonFeedbackEntry } from '../../interfaces/dto/PersonFeedbackEntry'

export interface Profile {
    firstName: string
    lastName: string
    avatar: string
}

export interface Info {
    profile: Profile
}

export interface Comment {
    _id: string
    personId: string
    role: PersonRoles
    courseId: string
    status: string
    v1CourseId: string
    schedule: FeedbackSchedule
    entries: Array<PersonFeedbackEntry>
    contentFeedback: FeedbackEntry
    npsFeedback: FeedbackEntry
    original: FeedbackOriginalFormData
    comment: string[]
    userInfo: Info
}

export class CommentSummary {
    _id: string
    comments: Comment
    info: Info
    constructor (_id: string, comments: Comment, info: Info) {
      this._id = _id
      this.comments = comments
      this.info = info
    }
}

export class CommentNPS {
    _id: string
    personId: string
    role: PersonRoles
    courseId: string
    status: string
    v1CourseId: string
    schedule: FeedbackSchedule
    entries: Array<PersonFeedbackEntry>
    contentFeedback: FeedbackEntry
    npsFeedback: FeedbackEntry
    original: FeedbackOriginalFormData
    comment: string[]
    infoUser: Info
    constructor (
      _id: string,
      personId: string,
      role: PersonRoles,
      courseId: string,
      status: string,
      v1CourseId: string,
      schedule: FeedbackSchedule,
      entries: Array<PersonFeedbackEntry>,
      contentFeedback: FeedbackEntry,
      npsFeedback: FeedbackEntry,
      original: FeedbackOriginalFormData,
      comment: string[],
      infoUser: Info
    ) {
      this._id = _id
      this.personId = personId
      this.role = role
      this.courseId = courseId
      this.status = status
      this.v1CourseId = v1CourseId
      this.schedule = schedule
      this.entries = entries
      this.contentFeedback = contentFeedback
      this.npsFeedback = npsFeedback
      this.original = original
      this.comment = comment
      this.infoUser = infoUser
    }
}

export interface Detractors{
  value: number,
  perc: number
}

export interface Passives{
  value: number,
  perc: number
}

export interface Promoters{
  value: number,
  perc: number
}

export interface Score{
  amount: number,
  value: number
}

export interface ICommentsNPSInfo{
  comments: CommentNPS[]
  detractors: Detractors
  passives: Passives
  promoters: Promoters
  score: Score
  ranks: Rank[]
}

export class CommentsNPSInfo {
  comments: CommentNPS[]
  detractors: Detractors
  passives: Passives
  promoters: Promoters
  score: Score
  ranks: Rank[]
  constructor (
    comments: CommentNPS[],
    detractros: Detractors,
    passives: Passives,
    promoters: Promoters,
    score: Score,
    ranks: Rank[]
  ) {
    this.comments = comments
    this.detractors = detractros
    this.passives = passives
    this.promoters = promoters
    this.score = score
    this.ranks = ranks
  }
}

export class NPSComment {
    _id: string
    comments: Comment
    info: Info
    constructor (_id: string, comments: Comment, info: Info) {
      this._id = _id
      this.comments = comments
      this.info = info
    }
}
