import { ContractStatus } from '@/models/contracts'

export function contractStatus (value: string | ContractStatus): string {
  switch (value) {
    case ContractStatus.PendingSignature:
      return 'PENDIENTE'
    case ContractStatus.Signed:
      return 'FIRMADO'
    case ContractStatus.Expired:
      return 'EXPIRADO'
    case ContractStatus.Rejected:
      return 'RECHAZADO'
    case ContractStatus.PreSigned:
      return 'PRE-FIRMADO'
    case ContractStatus.Cancelled:
      return 'CANCELADO'
    default:
      return 'DESCONOCIDO'
  }
}
