import { createApp } from 'vue'
import axios from 'axios'
import App from './App.vue'
import router from './router'
import PrimeVue from 'primevue/config'
import ToastService from 'primevue/toastservice'
import ConfirmationService from 'primevue/confirmationservice'
import { registerCommonComponents } from './commonComponents'
import { createPinia } from 'pinia'
// import 'primevue/resources/themes/saga-blue/theme.css' // theme
import '@/assets/styles/theme/theme.scss' // theme
import 'primevue/resources/primevue.min.css' // core css
import 'primeflex/primeflex.css' // Support for Flex
import 'primeicons/primeicons.css' // icons
import primeVueLocale from './primevueLocalization'
import '@/assets/ch-common.css'
// import '@/assets/ch-core'
// import '@/assets/styles/variables.scss'
import '@/assets/styles/styles.scss'
import { registerCommonDirectives } from './commonDirectives'
import { useI18nStore } from '@/store/modules/commons/i18n-store'
const { hostname, port, protocol } = window.location

axios.defaults.withCredentials = false // Set this to false until we use cookies to handle session against the ch-api

// TODO mover !!
// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error)
  }
)

const app = createApp(App)
  .use(createPinia())
  .use(router)
  .use(PrimeVue, {
    locale: primeVueLocale,
    zIndex: {
      modal: 2600, // dialog, sidebar
      overlay: 1000, // dropdown, overlaypanel
      menu: 1000, // overlay menus
      tooltip: 1100 // tooltip
    }
  })
  .use(ToastService)
  .use(ConfirmationService)
  .use(useI18nStore().getI18n)
// Vue.config.devtools = true

registerCommonComponents(app)
registerCommonDirectives(app)

app.mount('#app')
