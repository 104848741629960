import { CountryAPI } from '@/api/countries.api'
import { Country } from '@/models/countries/classes/Country'
import { StoreIds } from '@/store/StoreIds'
import { defineStore } from 'pinia' //

interface CountriesState {
  isLoading: boolean
  countries: Country[]
  error: unknown
}

export const useCountriesStore = defineStore({
  id: StoreIds.CountriesStore,
  state: (): CountriesState => ({
    isLoading: false,
    countries: [],
    error: null
  }),
  getters: {
    countriesSortedByNames: (state) => state.countries.sort(
      (c1, c2) => c1.name > c2.name ? 1 : -1 // para que aparezcan ordenados por nombre
    ),
    allCodes: (state) => state.countries.map(c => c.code).join(',')
  },
  actions: {
    async loadCountries () {
      try {
        this.isLoading = true
        this.countries = await CountryAPI.findAll()
      } catch (error) {
        this.error = error
      } finally {
        this.isLoading = false
      }
    }
  }
})
