import { Directive } from '@vue/runtime-core'

const ParentClass: Directive = {
  mounted (el, binding) {
    if (Array.isArray(binding.value)) {
      for (let i = 0; i < binding.value.length; i++) {
        binding.value[i] && el.parentElement?.classList.add(binding.value[i])
      }
    } else if (typeof binding.value === 'string') {
      el.parentElement?.classList.add(binding.value)
    } else {
      throw new Error('Invalid value type for v-parentclass. Expected string[] or string.')
    }
  },
  updated (el, binding) {
    if (Array.isArray(binding.value)) {
      el.parentElement && (el.parentElement.classList.value = binding.value.join(' ').trim())
    } else if (typeof binding.value === 'string') {
      el.parentElement?.classList.replace(binding.oldValue, binding.value)
    } else {
      throw new Error('Invalid value type for v-parentclass. Expected string[] or string.')
    }
  }
}

export default ParentClass
