import { useUserSessionStore } from '@/store/modules/commons/user-session-store'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { h, resolveComponent } from 'vue'
import Home from '@/views/Home.vue'
import ErrorScreen from '@/views/Error.vue'
import RecruitmentRoutes from '@/views/recruitment/routes'
import EducationRoutes from '../views/education/routes'
import CMSRoutes from '../views/cms/routes'
import { CommonRouteNames } from './route-names'
import { useLocaleStore } from '@/store/modules/commons/locale-store'
import CoderplusRoutes from '@/views/coderplus/routes'
import TalentRoutes from '@/views/talent/routes'
import JBRoutes from '@/views/jobBank/routes'
import CommunityRoutes from '@/views/community/routes'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: CommonRouteNames.LOGIN_PAGE,
    component: () => import('@/views/Login.vue'),
    meta: {
      guest: true
    }
  },
  {
    path: '/home',
    component: () => import('@/layouts/NoSideMenuLayout.vue'),
    children: [
      {
        path: '',
        name: CommonRouteNames.HOME_PAGE,
        component: Home
      }
    ]
  },
  {
    path: '/',
    name: 'FullLayout',
    component: () => import('@/layouts/FullLayout.vue'),
    children: [
      {
        path: 'recruitment',
        name: 'Recruitment',
        component: {
          name: 'RecruitmentRoutes',
          render: () => h(resolveComponent('router-view'))
        },
        children: RecruitmentRoutes
      },
      {
        path: 'coderplus',
        name: 'CoderPlus',
        component: {
          name: 'CoderplusRoutes',
          render: () => h(resolveComponent('router-view'))
        },
        children: CoderplusRoutes
      },
      {
        path: 'education',
        name: 'Education',
        component: {
          name: 'EducationRoutes',
          render: () => h(resolveComponent('router-view'))
        },
        children: EducationRoutes
      },
      {
        path: 'community',
        name: 'Community',
        component: {
          name: 'CommunityRoutes',
          render: () => h(resolveComponent('router-view'))
        },
        children: CommunityRoutes
      },
      {
        path: 'cms',
        name: 'CMS',
        component: {
          name: 'CMSRoutes',
          render: () => h(resolveComponent('router-view'))
        },
        children: CMSRoutes
      },
      {
        path: 'talents',
        name: 'Talents',
        component: {
          name: 'TalentRoutes',
          render: () => h(resolveComponent('router-view'))
        },
        children: TalentRoutes
      },
      {
        path: 'job-bank',
        name: 'JobBank',
        component: {
          name: 'JBRoutes',
          render: () => h(resolveComponent('router-view'))
        },
        children: JBRoutes
      },
      {
        path: 'error',
        component: ErrorScreen,
        name: CommonRouteNames.ERROR_SCREEN,
        props: true
      },
      {
        path: '',
        redirect: { path: '/home' }
      }
    ]
  },
  {
    path: '/:notFound(.*)',
    redirect: '/home'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const session = useUserSessionStore()
  const locale = useLocaleStore()
  if (to.matched.some(record => record.meta.guest)) {
    if (to.name === 'Login' && session.isAuthenticated) {
      next({
        path: '/home'
      })
      return
    }
    next()
  } else if (session.isAuthenticated) {
    await locale.fetchLocale()
    next()
  } else {
    next({
      name: CommonRouteNames.LOGIN_PAGE,
      params: { nextUrl: to.fullPath }
    })
  }
})

export default router
