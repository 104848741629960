
import { CSSColors } from '@/models/enums'
import { defineComponent, computed, toRefs } from 'vue'

export default defineComponent({
  props: {
    disabled: {
      type: Boolean,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    trueLabel: {
      type: String,
      required: true
    },
    falseLabel: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const { trueLabel, falseLabel } = toRefs(props)
    const options = [
      { name: trueLabel.value, value: true },
      { name: falseLabel.value, value: false }
    ]
    return {
      options,
      CSSColors
    }
  }
})
