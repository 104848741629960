import { CoursePlan } from '@/models/academic-definitions/classes/CoursePlan'
import { ColumnFields } from '@/models/components/tables/enums'
import { Country } from '@/models/countries/classes/Country'
import { CourseType } from '@/models/enums'

export interface ICourseFilters {
  countries: { value: Country[] | null }
  startMonth: { value: Date | string | null }
  endMonth: { value: Date | string | null }
  courseType: { value: CourseType[] | null }
  coursePlans: { value: CoursePlan[] | null }
  teacherAssigned: { value: boolean[] | null }
  tutorsCompleted: { value: boolean[] | null }
  coordinatorAssigned: { value: boolean[] | null }
  isSoldOut: { value: boolean | null }
  isCancelled: { value: boolean | null }
  days: {value: number[] | null}
  columns: { value: ColumnFields[] | null }
}

export class CourseFilters implements ICourseFilters {
  countries: { value: Country[] | null }
  startMonth: { value: Date | string | null }
  endMonth: { value: Date | string | null }
  courseType: { value: CourseType[] | null }
  coursePlans: { value: CoursePlan[] | null }
  teacherAssigned: { value: boolean[] | null }
  tutorsCompleted: { value: boolean[] | null }
  coordinatorAssigned: { value: boolean[] | null }
  isSoldOut: { value: boolean | null }
  isCancelled: { value: boolean | null }
  days: { value: number[] | null }
  columns: { value: ColumnFields[] | null }

  constructor (filters?: ICourseFilters) {
    this.countries = filters?.countries || { value: null }
    this.startMonth = filters?.startMonth || { value: null }
    this.endMonth = filters?.endMonth || { value: null }
    this.courseType = filters?.courseType || { value: null }
    this.coursePlans = filters?.coursePlans || { value: null }
    this.teacherAssigned = filters?.teacherAssigned || { value: null }
    this.tutorsCompleted = filters?.tutorsCompleted || { value: null }
    this.coordinatorAssigned = filters?.coordinatorAssigned || { value: null }
    this.isSoldOut = filters?.isSoldOut || { value: null }
    this.isCancelled = filters?.isCancelled || { value: null }
    this.days = filters?.days || { value: null }
    this.columns = filters?.columns || { value: null }
  }
}
