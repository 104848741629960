import { INPSClassSummary } from '../interfaces/INPSClassSummary'
import { ClassFeedbackSummary } from './ClassFeedbackSummary'

export class NPSClassSummary implements INPSClassSummary {
  classId: string
  contentRating?: number
  teacherRating?: number
  tutorRating?: number

  constructor (from: ClassFeedbackSummary) {
    this.classId = from.classId
    this.contentRating = from.contentSummary !== null ? from.contentSummary.rating?.value : undefined
    this.tutorRating = from.tutorSummary !== null ? from.tutorSummary.rating?.value : undefined
    this.teacherRating = from.teacherSummary !== null ? from.teacherSummary.rating?.value : undefined
  }
}
