
<template>
  <div :class="containerClass">
    <slot>
      <span class="p-avatar-text" v-if="label">{{ label }}</span>
      <span :class="iconClass" v-else-if="icon"></span>
      <img :src="image" v-else-if="image && !fallback">
      <object v-else-if="image && fallback" :data="image" :type="type">
        <img :src="fallback">
      </object>
    </slot>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export default {
  props: {
    label: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    image: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: 'normal'
    },
    shape: {
      type: String,
      default: 'square'
    },
    fallback: {
      type: String,
      default: null
    }
  },
  computed: {
    containerClass () {
      return ['p-avatar p-component', {
        'p-avatar-image': this.image != null,
        'p-avatar-circle': this.shape === 'circle',
        'p-avatar-lg': this.size === 'large',
        'p-avatar-xl': this.size === 'xlarge'
      }]
    },
    type () {
      return this.image?.endsWith('.png') ? 'image/png' : 'image/jpeg'
    },
    iconClass () {
      return ['p-avatar-icon', this.icon]
    }
  }
}
</script>

<style>
</style>
