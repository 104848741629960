export enum UserRoles {
  notLoggedUser = '',
  SuperAdmin = 'superadmin',
  Admin = 'admin',
  SalesExecutive= 'salesExecutive',
  partner= 'partner',
  finance= 'finance',
  support= 'support',
  mentor= 'mentor'
}
