
import {
  OrgStructureNodeDto,
  OrgStructureNodeDtoBase
} from '../../../models/organization'
import { findEditableOrganizationTree } from '@/api/people.api'
import Column from 'primevue/column'
import TreeTable from 'primevue/treetable'
import { defineComponent, onMounted, ref } from 'vue'

interface TreeNode {
  key: string
  data: OrgStructureNodeDtoBase
  children?: Array<TreeNode>
  style?: string
  styleClass?: string
  leaf?: boolean
}

function convertToTreeNodes (
  positions: Array<OrgStructureNodeDto> | undefined
): Array<TreeNode> | undefined {
  return positions?.map(
    position =>
      ({
        key: position._id,
        styleClass: 'position',
        data: {
          _id: position._id,
          name: position.name,
          people: position.people
        },
        children: convertToTreeNodes(position.children)
      } as TreeNode)
  )
}

export default defineComponent({
  name: 'StructureEdit',
  components: {
    Column,
    TreeTable
  },
  setup () {
    const roots = ref<Array<TreeNode>>([])
    const expandedKeys = ref<{ [index: string]: boolean }>({})

    onMounted(async () => {
      const positions = await findEditableOrganizationTree()
      roots.value = convertToTreeNodes(positions) || []
      expandAll()
    })

    const expandAll = () => {
      for (const node of roots.value) {
        expandNode(node)
      }

      expandedKeys.value = { ...expandedKeys.value }
    }

    const collapseAll = () => {
      expandedKeys.value = {}
    }

    const expandNode = (node: TreeNode) => {
      if (node.children && node.children.length) {
        expandedKeys.value[node.key] = true

        for (const child of node.children) {
          expandNode(child)
        }
      }
    }

    return {
      roots,
      expandedKeys,
      collapseAll,
      expandAll
    }
  }
})
