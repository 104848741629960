import { App } from '@vue/runtime-core'
import BadgeDirective from 'primevue/badgedirective'
import StyleClass from 'primevue/styleclass'
import Tooltip from 'primevue/tooltip'
import ParentClass from './directives/ParentClass'
import ParentStyle from './directives/ParentStyle'

/**
 * Register directives used in all the application
 * @param app the application to register components to
 */
export function registerCommonDirectives (app: App): void {
  app.directive('tooltip', Tooltip)
  app.directive('badge', BadgeDirective)
  app.directive('styleclass', StyleClass)
  app.directive('parentclass', ParentClass)
  app.directive('parentstyle', ParentStyle)
}
