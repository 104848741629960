import { EducationRouteNames, CMSRouteNames } from '@/router/route-names'

enum CardId { // area-card --> Para que tenga los estilos definidos en el componente
  Education = 'education-card',
  Recruitment = 'recruitment-card',
  Talent = 'talent-card',
  Growth = 'growth-card',
  CX = 'customer-experience-card',
  Finances = 'finances-card',
  CMS = 'cms-card'
}

enum CardImg {
  Education = require('@/assets/icons/home/educacion.svg'),
  Recruitment = require('@/assets/icons/home/hr.svg'),
  Talent = require('@/assets/icons/home/talent.svg'),
  Finances = require('@/assets/icons/home/finances.svg'),
  CMS = require('@/assets/icons/home/cms.svg')
}

export interface IWorkAreaCard{
  title: string
  visible: boolean
  img: CardImg | null
  to: Record<string, string>
  id: CardId | null
}

export const cardOptions:IWorkAreaCard[] = [
  {
    title: 'Educación',
    visible: true,
    img: CardImg.Education,
    to: { name: EducationRouteNames.COURSES },
    id: CardId.Education
  },
  {
    title: 'Recruitment (Próximamente)',
    visible: true,
    img: CardImg.Recruitment,
    to: { name: '' },
    id: CardId.Recruitment
  },
  {
    title: 'Talento (Próximamente)',
    visible: false,
    img: CardImg.Talent,
    to: { name: '' },
    id: CardId.Talent
  },
  {
    title: 'Finanzas (Próximamente)',
    visible: false,
    img: CardImg.Finances,
    to: { name: '' },
    id: CardId.Finances
  },
  {
    title: 'CMS',
    visible: true,
    img: CardImg.CMS,
    to: { name: CMSRouteNames.TRANSLATIONS },
    id: CardId.CMS
  }
]
