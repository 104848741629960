import { IAssignedStaff, IClassLink } from '../interfaces'
import { Calendar } from '../classes/Calendar'
import { ClassId } from '@/utils/types'

export class Class {
  _id: ClassId
  additional: boolean
  classPlan?: string
  name: string
  studentAttendances: string[]
  staffAttendances: string[]
  assignedStaff: IAssignedStaff[]
  calendar: Calendar
  links: IClassLink[]
  expirationDay: string
  constructor (
    _id: string,
    additional: boolean,
    name: string,
    studentAttendances: string[],
    staffAttendances: string[],
    assignedStaff: IAssignedStaff[],
    calendar: Calendar,
    links: IClassLink[],
    expirationDay: string,
    classPlan?: string
  ) {
    this._id = _id
    this.additional = additional
    this.classPlan = classPlan
    this.name = name
    this.studentAttendances = studentAttendances
    this.staffAttendances = staffAttendances
    this.assignedStaff = assignedStaff
    this.calendar = calendar
    this.links = links
    this.expirationDay = expirationDay
  }
}
