import { ICurrency } from '@/models/countries/interfaces/Currency'
import { useCountriesStore } from '@/store/modules/commons/countries-store'
import { CountriesFlags } from '../enums'

export class Country {
  id: string
  code: string
  currency: ICurrency
  enable: boolean
  name: string
  timezone: string
  updatedAt: string

  constructor (
    id: string,
    code: string,
    currency: ICurrency,
    enable: boolean,
    name: string,
    timezone: string,
    updatedAt: string
  ) {
    this.id = id
    this.code = code
    this.currency = currency
    this.enable = enable
    this.name = name
    this.timezone = timezone
    this.updatedAt = updatedAt
  }

  static getIconUrlFromCountryCode (code:string): string {
    const flag = new Map(Object.entries(CountriesFlags)).get(code)
    return flag as string || ''
  }

  static getCountryNameFromCode (code:string): string {
    const countries = useCountriesStore().countries
    return countries.find(c => c.code === code)?.name || ''
  }
}
