
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'

import { useUserSessionStore } from '@/store/modules/commons/user-session-store'
import { cardOptions, IWorkAreaCard } from '@/models/components/home/workAreaCards'
import WorkAreaCard from '@/components/home/WorkAreaCard.vue'

export default defineComponent({
  components: { WorkAreaCard },
  setup () {
    const session = useUserSessionStore()
    const router = useRouter()
    const options = ref<IWorkAreaCard[]>(cardOptions)
    return {
      options,
      router,
      session
    }
  }
})
