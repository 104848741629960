import { RouteRecordRaw } from 'vue-router'
const CoderPlusRoutes: Array<RouteRecordRaw> = [
  {
    path: 'videos',
    name: 'Videos',
    component: () => import('@/views/coderplus/videos/CoderPlusVideos.vue')
  },
  {
    path: 'videos/:videoId',
    name: 'VideoDetail',
    component: () => import('@/views/coderplus/videos/CoderPlusUpdate.vue')
  },
  {
    path: 'create',
    name: 'Create',
    component: () => import('@/views/coderplus/videos/CoderPlusCreate.vue')
  },
  {
    path: '',
    redirect: { name: 'Videos' }
  }
]

export default CoderPlusRoutes
