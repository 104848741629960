export enum RouteNames {
  HR_TEACHER_PROFILE = 'HrTeacherProfile',
  HR_TEACHER_PROFILE_GENERAL = 'PersonProfile', // 'HrTeacherProfileGeneral',
  HR_TEACHER_PROFILE_COMMENTS = 'HrTeacherProfileComments',
  HR_TEACHER_PROFILE_PERSONAL_DATA = 'HrTeacherProfileData',
  EDUCATION_CAMADAS_ENDED_PERFORMANCE = 'EducationCourseEndedPerformance',
  STAFF_SQUADS = 'StaffSquads',
  STAFF_SQUAD = 'StaffSquad',
  STAFF_LIST = 'StaffList'
}

export enum CommonRouteNames {
  HOME_PAGE = 'HomePage',
  LOGIN_PAGE = 'LoginPage',
  ERROR_SCREEN = 'ErrorScreen'
}

export enum EducationRouteNames {
  COURSES = 'Courses',
  COURSES_PERFORMANCE = 'CoursesPerformance',
  COURSES_FINISHED_PERFORMANCE = 'CoursesFinishedPerformance',
  COURSES_PLANNING = 'CoursesPlanning',
  COURSE_DETAIL = 'CourseDetail',
  COURSE_DETAIL_GENERAL = 'CourseDetailGeneral',
  COURSE_DETAIL_CLASSES = 'CourseDetailClasses',
  COURSE_DETAIL_AFTER_CLASSES = 'CourseDetailAfterClasses',
  COURSE_DETAIL_NPS = 'CourseDetailNps',
  COURSE_DETAIL_TUTOR_ASSIGNMENT = 'CourseDetailTutorAssignment',
  COURSE_DETAIL_STAFF = 'CourseDetailStaff',
  CONTENT = 'Content',
  CONTENT_COURSES = 'ContentCourses',
  CONTENT_CAREERS = 'ContentCareers',
  CONTENT_COURSES_DETAIL = 'ContentCoursesDetail',
  CONTENT_COURSES_CREATE = 'ContentCoursesCreate',
  CONTENT_COURSES_CREATE_ABOUT = 'ContentCoursesCreateAbout',
  CONTENT_CAREERS_DETAIL = 'ContentCareersDetail',
  CONTENT_COURSES_DETAIL_GENERAL_INFO = 'ContentCoursesDetailGeneralInfo',
  CONTENT_COURSES_DETAIL_SYLLABUM = 'ContentCoursesDetailSyllabum',
  CONTENT_COURSES_DETAIL_SYLLABUM_DETAIL = 'ContentCoursesDetailSyllabumDetail',
  CONTENT_CAREERS_DETAIL_GENERAL_INFO = 'ContentCareersDetailGeneralInfo',
  CONTENT_CAREERS_CREATE = 'ContentCareersCreate',
  CONTENT_CAREERS_CREATE_ABOUT = 'ContentCareersCreateAbout',
  STAFF_FOLLOWUP = 'StaffFollowUp',
  STAFF_FOLLOWUP_TEACHERS = 'StaffFollowUpTeachers',
  STAFF_FOLLOWUP_TUTORS = 'StaffFollowUpTutors',
  STAFF_FOLLOWUP_COORDINATORS = 'StaffFollowUpCoordinators',
  STAFF_FOLLOWUP_TEACHERS_ONBOARDING = 'StaffFollowUpTeachersOnboarding',
  STAFF_FOLLOWUP_TEACHERS_TRACING = 'StaffFollowUpTeachersTracing',
  STAFF_FOLLOWUP_TUTORS_REGULAR = 'StaffFollowUpTutorsRegular',
  STAFF_FOLLOWUP_TUTORS_CUSTOM = 'StaffFollowUpTutorsCustom',
  STAFF_FOLLOWUP_TEACHERS_TRACING_REINFORCEMENT = 'StaffFollowUpTeachersTracingReinforcement',
  PERSON_CREATE = 'PersonCreate',
  PERSON_PROFILE = 'PersonProfile',
  PERSON_PROFILE_GENERAL = 'PersonProfile',
  PERSON_PROFILE_HISTORY = 'PersonProfileHistory',
  PERSON_PROFILE_PROFESSIONAL = 'PersonProfileProfessional',
  PERSON_PROFILE_ACTIVITY = 'PersonProfileActivityRegister',
  STUDENTS_DETAILS = 'Students'
}

export enum CoderPlusNames {
  CODERPLUS= 'CoderPlus',
  CREATE= 'Create'
}

export enum CMSRouteNames {
  TRANSLATIONS = 'CMS-TRANSLATIONS',
  TRANSLATIONS_EDIT = 'CMS-TRANSLATIONS-EDIT',
  BANNERS = 'CMS-BANNERS',
  EVENTS = 'CMS-EVENTS',
  PRODUCT_BRANDS = 'CMS-PRODUCT_BRANDS',
  PRODUCT_CATEGORIES = 'CMS-PRODUCT_CATEGORIES',
  ALLIANCES = 'CMS-ALLIANCES',
  SVG_UPLOAD = 'CMS-SVG_UPLOAD'
}

export enum TalentRouteNames {
  REPORTS = 'Reports'
}

export enum JobBankRouteNames {
  COMPANIES = 'Companies',
  JOB_OFFERS = 'JobOffers',
  JOB_SCRAPER = 'JobOffersScraper',
  JOB_SCRAPER_LIST = 'JobOffersScraperList',
  JOB_OFFERS_LIST = 'JobOffersList',
  CREATE_COMPANY = 'CreateCompany',
  COMPANIES_LIST = 'CompaniesList',
  S_GENERALINFO = 'StepperGeneral',
  S_CONTACTINFO = 'StepperContact',
  S_PROFILE = 'StepperProfile',
  S_PROFILE_CREATED = 'ProfileCreated',
  CREATE_JOB_OFFER = 'CreateJobOffer',
  OFFERINFO = 'OfferInfo',
  COMPANYINFO = 'CompanyInfo',
  PUBLISHJOBOFFER = 'PublishJobOffer',
  PUBLISHEDJOBOFFER = 'PublishedJobOffer',
  EDIT_JOBBANK = 'EditJobBank',
  EDIT_JOBBANK_PREVIEW = 'EditJobBankPreview',
  EDIT_GENERALINFO = 'EditGeneralInfo',
  EDIT_CONTACTINFO = 'EditContactInfo',
  EDIT_PROFILE = 'EditProfile',
  EDIT_COMPANY = 'EditCompany',
  EDIT_JOBOFFER = 'EditJobOffer',
  EDIT_JOBOFFER2 = 'EditJobOffer2',
  EDIT_JOBOFFER_PREVIEW = 'EditJobOfferPreview',
  EDIT_OFFERINFO = 'EditOfferInfo',
  EDIT_COMPANYINFO = 'EditCompanyInfo',
  EDIT_PUBLISHJOBOFFER = 'EditPublishJobOffer',
  EDIT_PUBLISHEDJOBOFFER = 'EditPublishedJobOffer',
  EDIT_PROFILE_EDITED = 'ProfileEdited'
}

export enum StaffRouteNames {
  TEACHERS = 'Teachers',
  TUTORS = 'Tutors',
  COORDINATORS = 'Coordinators'
}

export enum CommunityRouteNames {
  REFERALS = 'ReferralsList'
}
