const locale = {
  startsWith: 'Comienza con',
  contains: 'Contiene',
  notContains: 'No contiene',
  endsWith: 'Termina con',
  equals: 'Igual a',
  notEquals: 'No igual a',
  noFilter: 'Sin Filtro',
  lt: 'Menor que',
  lte: 'Menor o igual a',
  gt: 'Mayor que',
  gte: 'Mayor o igual a',
  dateIs: 'La fecha es',
  dateIsNot: 'La fecha no es',
  dateBefore: 'Anterior a',
  dateAfter: 'Posterior a',
  clear: 'Limpiar',
  apply: 'Aplicar',
  matchAll: 'Corresponder todos',
  matchAny: 'Corresponder cualquiera',
  addRule: 'Agregar regla',
  removeRule: 'Quitar regla',
  accept: 'Sí',
  reject: 'No',
  choose: 'Elegir',
  upload: 'Cargar',
  cancel: 'Cancelar',
  dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
  dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vir', 'Sab'],
  dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
  monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
  monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
  today: 'Hoy',
  weekHeader: 'Wk',
  firstDayOfWeek: 0,
  dateFormat: 'dd/mm/yy',
  weak: 'Débil',
  medium: 'Medio',
  strong: 'Fuerte',
  passwordPrompt: 'Ingrese un contraseña',
  emptyFilterMessage: 'No se encontraron resultados',
  emptyMessage: 'No hay opciones disponibles'
}

export default locale
