
import StaffTable from '../components/StaffTable.vue'
import FilterConfiguration from '../components/FilterConfiguration.vue'
import { defineComponent, computed } from 'vue'
import { TypeFilter } from '@/models/custom-filters/enum'
import { useStaffList } from '../components/hook/useStaffList'
import { PersonRoles } from '@/models/person/enums'

export default defineComponent({
  components: {
    StaffTable,
    FilterConfiguration
  },
  setup (_, { emit }) {
    emit('onTabChange', { index: 0 })
    const contextId = 'emails.activity-record'
    const p : PersonRoles = PersonRoles.Coordinator
    const {
      staff,
      loading,
      filters,
      courseFilters,
      totalRecords,
      firstVal,
      allColumns,
      searchTxt,
      selectedCustomFilter,
      selectedCustomView,
      courseViews,
      isFiltered,
      displayCreateFilter,
      filterNameInput,
      allCoursePlans,
      saveFilters,
      updateFilterListened,
      showConfirmDeleteFilter,
      removeActiveFilter,
      toggleCreateFilter,
      cleanAndUpdateFiltersBySelector,
      changeSelectedColumns,
      updateSearchTxt,
      onPage
    } = useStaffList(p)

    const dialogText = computed(() => {
      const isFilter = displayCreateFilter.value.type === TypeFilter.Filter
      return ({
        title: isFilter ? 'Guardar filtro' : 'Guardar vista',
        newCustomFilter: isFilter ? !selectedCustomFilter.value : !selectedCustomView.value,
        placeholder: isFilter ? 'Nombre de filtro' : 'Nombre de la vista',
        updatingTxt: isFilter ? `Actualizando filtro "${selectedCustomFilter.value?.name}"` : `Actualizando filtro "${selectedCustomView.value?.name}"`,
        disabledBtn: isFilter ? (!filterNameInput.value && !selectedCustomFilter.value) : (!filterNameInput.value && !selectedCustomView.value)
      })
    })

    return {
      staff,
      totalRecords,
      firstVal,
      courseFilters,
      allColumns,
      filters,
      contextId,
      loading,
      searchTxt,
      selectedCustomFilter,
      selectedCustomView,
      courseViews,
      isFiltered,
      displayCreateFilter,
      filterNameInput,
      dialogText,
      allCoursePlans,
      updateFilterListened,
      showConfirmDeleteFilter,
      toggleCreateFilter,
      cleanAndUpdateFiltersBySelector,
      changeSelectedColumns,
      removeActiveFilter,
      updateSearchTxt,
      onPage,
      saveFiltersTypeFilter: (type: TypeFilter = TypeFilter.Filter) => saveFilters(type)
    }
  }
})
