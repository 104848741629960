
import { computed, defineComponent, onMounted, watch } from 'vue'
import { DateTime, Settings } from 'luxon'
import ConfirmDialog from 'primevue/confirmdialog'
import Toast from 'primevue/toast'
import { useUserSessionStore } from '@/store/modules/commons/user-session-store'
import { useCountriesStore } from './store/modules/commons/countries-store'
import { useLocaleStore } from './store/modules/commons/locale-store'
export default defineComponent({
  components: {
    ConfirmDialog,
    Toast
  },
  setup () {
    const localeStore = useLocaleStore()
    const baseLocale = computed<string | null>(() =>
      localeStore.locale
        ? localeStore.locale.baseLocale?.replace('_', '-')
        : null
    )
    const session = useUserSessionStore()
    const countriesActive = useCountriesStore()
    watch(
      baseLocale,
      () => (Settings.defaultLocale = baseLocale.value || 'es-ES')
    )

    onMounted(async () => {
      await countriesActive.loadCountries()
    })
    return {
      session
    }
  }
})
