
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    showRemoveButton: {
      type: Boolean,
      default: false
    }
  },
  setup (_props, { emit }) {
    const onRemove = () => {
      emit('onRemove')
    }

    return { onRemove }
  }
})
