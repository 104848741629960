
import { computed, defineComponent } from 'vue'

export default defineComponent({
  props: {
    text: String,
    class: String,
    backgroundColor: String,
    fontSize: String,
    rounded: Boolean,
    textColor: {
      type: String,
      default: '#000000'
    },
    padding: {
      type: String,
      default: '3px'
    },
    border: String
  },
  setup (props) {
    const classRef = computed(() => props.class)
    const styles = computed(() => {
      const style = {
        backgroundColor: props.backgroundColor,
        textColor: props.textColor,
        padding: props.padding,
        fontSize: props.fontSize,
        borderRadius: props.rounded ? '18px' : '3px',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        letterSpacing: '-0.02em',
        marginLeft: '0.625rem',
        justifyContent: 'center',
        border: props.border ? props.border : ''
      }
      return style
    })
    return {
      styles,
      classRef
    }
  }
})
