export const Language = new Map<string, string>([
  ['es', 'es_ES'],
  ['ar', 'es_AR'],
  ['mx', 'es_MX'],
  ['cl', 'es_CL'],
  ['co', 'es_CO'],
  ['br', 'pt_BR'],
  ['pe', 'es_PE'],
  ['us', 'es_US']
])
