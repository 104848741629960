import { PersonRoles } from '../enums'
import { IdentityDocument } from '../interfaces/IdentityDocument'
import { SocialNetworks } from '../interfaces/SocialNetworks'
import { Interview } from '../interfaces/Interview'
import { MapPerson } from './MapPerson'
import { getDirectDownloadLink } from '@/utils/helpers'

export class Person {
  id: string
  name: string
  lastname?: string
  email: string
  avatar: string
  area: string
  enabled: boolean
  responsabilities?: number
  rol: number
  roles: PersonRoles[]
  socialNetworks: SocialNetworks
  identityDocument: IdentityDocument
  phone: string
  country: string
  activeRole?: PersonRoles
  unenroll?: boolean
  interview?: Interview
  cvFileLink?: string | null
  get fullName (): string {
    return this.name + ' ' + this.lastname
  }

  constructor (
    _id: string,
    name: string,
    email: string,
    avatar: string,
    area: string,
    enabled: boolean,
    rol: number,
    roles: PersonRoles[],
    socialNetworks: SocialNetworks,
    identityDocument: IdentityDocument,
    phone: string,
    country: string,
    responsabilities?: number,
    lastname?: string,
    activeRole?: PersonRoles,
    unenroll?: boolean,
    interview?: Interview,
    cvFileLink?: string | null
  ) {
    this.id = _id
    this.name = name
    this.lastname = lastname
    this.email = email
    this.avatar = avatar
    this.area = area
    this.avatar = avatar
    this.enabled = enabled
    this.responsabilities = responsabilities
    this.rol = rol
    this.roles = roles
    this.socialNetworks = socialNetworks
    this.identityDocument = identityDocument ?? {
      identity: null,
      identityType: null
    }
    this.phone = phone
    this.country = country
    this.activeRole = activeRole
    this.unenroll = unenroll
    this.interview = interview ?? {
      rejected: false,
      applied: false,
      approved: false,
      unenroll: false,
      notNow: false
    }
    this.cvFileLink = cvFileLink
      ? getDirectDownloadLink(cvFileLink)
      : cvFileLink
  }

  static async getPersonsById (ids: string[]): Promise<Map<string, Person>> {
    const mapper = new Map<string, Person>()
    const mapperPerson: MapPerson = new MapPerson()
    for (const id of ids) {
      const personInMap = mapper.get(id)
      if (!personInMap) {
        try {
          const person = await mapperPerson.getPerson(id)
          mapper.set(id, person)
        } catch (err) {
          console.log(err)
        }
      }
    }
    return mapper
  }

  static parsedRole (rol: PersonRoles): string | PersonRoles {
    // cambiar hay roles deprecados
    switch (rol) {
      case PersonRoles.SuperAdmin:
        return 'Superadmin'
      case PersonRoles.Admin:
        return 'Admin'
      case PersonRoles.SalesExecutive:
        return 'Ejecutivo de ventas'
      case PersonRoles.Partner:
        return 'Partner'
      case PersonRoles.Finance:
        return 'Finanzas'
      case PersonRoles.Support:
        return 'Soporte'
      case PersonRoles.Mentor:
        return 'Mentor'
      case PersonRoles.Tutor:
        return 'Tutor'
      case PersonRoles.Student:
        return 'Estudiante'
      case PersonRoles.Teacher:
        return 'Profesor'
      case PersonRoles.Subtitute:
        return 'Substituto'
      case PersonRoles.Recruiter:
        return 'Recruiter'
      case PersonRoles.Free:
        return 'Freelance'
      case PersonRoles.Corporate:
        return 'Corporate'
      case PersonRoles.TrainingManager:
        return 'Training Manager'
      case PersonRoles.Supervisor:
        return 'Supervisor'
      case PersonRoles.Assistant:
        return 'Asistente'
      case PersonRoles.Coordinator:
        return 'Coordinador'
      case PersonRoles.Influencer:
        return 'Influencer'
      case PersonRoles.SubstitudTeacher:
        return 'Profesor sustituto'
      case PersonRoles.ReplacementTeacher:
        return 'Profesor de reemplazo'
      case PersonRoles.ReviewerTeachers:
        return 'Reviewer Teachers'
      case PersonRoles.AssistantSubstituteTeacher:
        return 'Assistant Substitute Teacher'
      case PersonRoles.Empty:
        return ''
      default:
        return rol
    }
  }
}
