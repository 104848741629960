import { CMSRouteNames } from '@/router/route-names'
import { RouteRecordRaw } from 'vue-router'

const cmsRoutes: Array<RouteRecordRaw> = [
  {
    path: 'banners',
    component: () => import('./SiteBannersListView.vue'),
    name: CMSRouteNames.BANNERS
  },
  {
    path: 'translations',
    component: () => import('@/views/cms/translations/TranslationsHome.vue'),
    name: CMSRouteNames.TRANSLATIONS
  },
  {
    path: 'translations/:context',
    component: () => import('@/views/cms/translations/TranslationsEdit.vue'),
    name: CMSRouteNames.TRANSLATIONS_EDIT
  },
  {
    path: 'eventos',
    component: () => import('@/views/cms/EventsHome.vue'),
    name: CMSRouteNames.EVENTS
  },
  {
    path: 'empresas-co-creadoras',
    component: () => import('@/views/cms/product-brands/ProductBrandsHome.vue'),
    name: CMSRouteNames.PRODUCT_BRANDS
  },
  {
    path: 'categorias-de-producto',
    component: () =>
      import('@/views/cms/product-categories/ProductCategoriesHome.vue'),
    name: CMSRouteNames.PRODUCT_CATEGORIES
  },
  {
    path: 'alianzas',
    component: () => import('@/views/cms/alliances/AlliancesHome.vue'),
    name: CMSRouteNames.ALLIANCES
  },
  {
    path: 'carga-de-svg',
    component: () => import('@/views/cms/svg-upload/SvgUploadHome.vue'),
    name: CMSRouteNames.SVG_UPLOAD
  }
]

export default cmsRoutes
