import { RouteRecordRaw } from 'vue-router'
import { EducationRouteNames } from '@/router/route-names'

const educationRoutes: Array<RouteRecordRaw> = [
  {
    path: 'courses',
    component: () => import('@/views/education/courses/Courses.vue'),
    name: EducationRouteNames.COURSES,
    redirect: () => ({ name: EducationRouteNames.COURSES_PLANNING }),
    children: [
      {
        path: 'active',
        component: () =>
          import('@/views/education/courses/children/CoursePerformance.vue'),
        name: EducationRouteNames.COURSES_PERFORMANCE
      },
      {
        path: 'planning',
        component: () =>
          import('@/views/education/courses/children/CoursesPlanning.vue'),
        name: EducationRouteNames.COURSES_PLANNING
      },
      {
        path: 'finished',
        component: () =>
          import(
            '@/views/education/courses/children/CoursesFinishedPerformance.vue'
          ),
        name: EducationRouteNames.COURSES_FINISHED_PERFORMANCE
      }
    ]
  },
  {
    path: 'performance/:courseId',
    name: EducationRouteNames.COURSE_DETAIL,
    component: () =>
      import('@/views/education/courses/children/details/Course.vue'),
    redirect: to => ({
      name: EducationRouteNames.COURSE_DETAIL_GENERAL,
      params: {
        courseId: to.params.courseId
      }
    }),
    children: [
      {
        path: 'general',
        name: EducationRouteNames.COURSE_DETAIL_GENERAL,
        component: () =>
          import(
            '@/views/education/courses/children/details/tabs/General.tab.vue'
          )
      },
      {
        component: () =>
          import(
            '@/views/education/courses/children/details/tabs/Classes.tab.vue'
          ),
        path: 'classes',
        name: EducationRouteNames.COURSE_DETAIL_CLASSES
      },
      {
        component: () =>
          import(
            '@/views/education/courses/children/details/tabs/TutorAssignment.vue'
          ),
        name: EducationRouteNames.COURSE_DETAIL_TUTOR_ASSIGNMENT,
        path: 'assignment'
      },
      {
        component: () =>
          import('@/views/education/courses/children/details/tabs/NPS.tab.vue'),
        name: EducationRouteNames.COURSE_DETAIL_NPS,
        path: 'nps'
      },
      {
        path: 'staff',
        name: EducationRouteNames.COURSE_DETAIL_STAFF,
        component: () =>
          import(
            '@/views/education/courses/children/details/tabs/Staff.tab.vue'
          )
      }
    ]
  },
  {
    path: 'content',
    component: () => import('@/views/education/content/Content.vue'),
    name: EducationRouteNames.CONTENT,
    children: [
      {
        path: 'courseplans',
        component: () => import('@/views/education/content/tabs/Courses.vue'),
        name: EducationRouteNames.CONTENT_COURSES
      },
      {
        path: 'careerplans',
        component: () => import('@/views/education/content/tabs/Careers.vue'),
        name: EducationRouteNames.CONTENT_CAREERS
      },
      {
        path: '',
        redirect: {
          name: EducationRouteNames.CONTENT_COURSES
        }
      }
    ]
  },
  {
    path: 'content/courseplans/create',
    component: () =>
      import('@/views/education/content/detail/CoursePlanDetail.vue'),
    name: EducationRouteNames.CONTENT_COURSES_CREATE,
    children: [
      {
        path: '',
        component: () =>
          import('@/views/education/content/detail/tabs/GeneralInfo.vue'),
        name: EducationRouteNames.CONTENT_COURSES_CREATE_ABOUT
      }
    ]
  },
  {
    path: 'content/courseplans/:planId',
    component: () =>
      import('@/views/education/content/detail/CoursePlanDetail.vue'),
    name: EducationRouteNames.CONTENT_COURSES_DETAIL,
    children: [
      {
        path: 'syllabum',
        component: () =>
          import('@/views/education/content/detail/tabs/Syllabum.vue'),
        name: EducationRouteNames.CONTENT_COURSES_DETAIL_SYLLABUM
      },
      {
        path: 'general',
        component: () =>
          import('@/views/education/content/detail/tabs/GeneralInfo.vue'),
        name: EducationRouteNames.CONTENT_COURSES_DETAIL_GENERAL_INFO
      },
      {
        path: '',
        redirect: {
          name: EducationRouteNames.CONTENT_COURSES_DETAIL_SYLLABUM
        }
      }
    ]
  },
  {
    path: 'content/careerplans/create',
    component: () =>
      import('@/views/education/content/detail/CareerPlanDetail.vue'),
    name: EducationRouteNames.CONTENT_CAREERS_CREATE,
    children: [
      {
        path: '',
        component: () =>
          import('@/views/education/content/detail/tabs/GeneralInfo.vue'),
        name: EducationRouteNames.CONTENT_CAREERS_CREATE_ABOUT
      }
    ]
  },
  {
    path: 'content/careerplans/:planId',
    component: () =>
      import('@/views/education/content/detail/CareerPlanDetail.vue'),
    name: EducationRouteNames.CONTENT_CAREERS_DETAIL,
    children: [
      {
        path: 'general',
        component: () =>
          import('@/views/education/content/detail/tabs/GeneralInfo.vue'),
        name: EducationRouteNames.CONTENT_CAREERS_DETAIL_GENERAL_INFO
      },
      {
        path: '',
        redirect: {
          name: EducationRouteNames.CONTENT_CAREERS_DETAIL_GENERAL_INFO
        }
      }
    ]
  },
  {
    path: 'content/courseplans/:planId/syllabum/:syllabusId',
    component: () =>
      import('@/views/education/content/detail/tabs/detail/SyllabusDetail.vue'),
    name: EducationRouteNames.CONTENT_COURSES_DETAIL_SYLLABUM_DETAIL
  },
  {
    path: 'staff',
    component: () => import('@/views/education/staff/FollowUp.vue'),
    name: EducationRouteNames.STAFF_FOLLOWUP,
    children: [
      {
        path: '',
        redirect: {
          name: EducationRouteNames.STAFF_FOLLOWUP_TEACHERS
        }
      },
      {
        path: 'teachers',
        /* component: () => import('@/views/education/staff/tabs/Teachers.vue'), */
        component: () =>
          import('@/views/education/staff/tabs/tabs/Tracing.vue'),
        name: EducationRouteNames.STAFF_FOLLOWUP_TEACHERS,
        children: [
          {
            path: 'onboarding',
            name: EducationRouteNames.STAFF_FOLLOWUP_TEACHERS_ONBOARDING,
            component: () =>
              import('@/views/education/staff/tabs/tabs/OnBoarding.vue')
          },
          {
            path: 'tracing',
            name: EducationRouteNames.STAFF_FOLLOWUP_TEACHERS_TRACING,
            component: () =>
              import('@/views/education/staff/tabs/tabs/Tracing.vue')
          }
        ]
      },
      {
        path: 'tutors',
        name: EducationRouteNames.STAFF_FOLLOWUP_TUTORS,
        // component: () => import('@/views/education/staff/tabs/Tutors.vue'),
        component: () => import('@/views/education/staff/tabs/tabs/TutorsRegular.vue'),
        children: [
          {
            path: 'regular',
            name: EducationRouteNames.STAFF_FOLLOWUP_TUTORS_REGULAR,
            component: () =>
              import('@/views/education/staff/tabs/tabs/TutorsRegular.vue')
          },
          {
            path: 'personalizado',
            name: EducationRouteNames.STAFF_FOLLOWUP_TUTORS_CUSTOM,
            component: () =>
              import('@/views/education/staff/tabs/tabs/TutorsRegular.vue')
          }
        ]
      },
      {
        path: 'coordinators',
        name: EducationRouteNames.STAFF_FOLLOWUP_COORDINATORS,
        component: () =>
          import('@/views/education/staff/tabs/tabs/Coordinators.vue')
      }
    ]
  },
  {
    path: 'staff/teachers/tracing/:teacherId',
    name: 'activityRegister',
    component: () => import('@/views/education/staff/tabs/TeachersActivity.vue')
  },
  {
    path: 'staff/tutors/tracing/:teacherId',
    name: 'activityRegisterTutors',
    component: () => import('@/views/education/staff/tabs/TutorsActivity.vue')
  },
  {
    path: 'students',
    name: EducationRouteNames.STUDENTS_DETAILS,
    component: () => import('@/views/education/students/StudentsDetails.vue'),
    children: [
      {
        path: '',
        redirect: {
          name: EducationRouteNames.STUDENTS_DETAILS
        }
      }
    ]
  },
  {
    path: 'students/create',
    name: 'createStudent',
    component: () => import('@/views/education/students/CreateStudent.vue')
  },
  {
    path: 'person/create',
    name: EducationRouteNames.PERSON_CREATE,
    component: () =>
      import('@/views/education/persons/person/PersonCreate.vue'),
    children: [
      {
        path: '',
        redirect: {
          name: EducationRouteNames.PERSON_CREATE
        }
      }
    ]
  },
  {
    path: 'persons/:personId',
    component: () => import('@/views/education/persons/person/Person.vue'),
    children: [
      {
        path: '',
        component: () =>
          import('@/views/education/persons/person/tabs/General.vue'),
        name: EducationRouteNames.PERSON_PROFILE_GENERAL
      },
      {
        path: 'history',
        component: () =>
          import('@/views/education/persons/person/tabs/History.vue'),
        name: EducationRouteNames.PERSON_PROFILE_HISTORY
      },
      {
        path: 'professional',
        component: () =>
          import(
            '@/views/education/persons/person/tabs/ProfessionalProfile.vue'
          ),
        name: EducationRouteNames.PERSON_PROFILE_PROFESSIONAL
      },
      {
        path: 'activity',
        component: () =>
          import(
            '@/views/education/persons/person/tabs/ActivityRegister.vue'
          ),
        name: EducationRouteNames.PERSON_PROFILE_ACTIVITY
      }
    ]
  },
  {
    path: '',
    redirect: { name: EducationRouteNames.COURSES_PERFORMANCE }
  }
]

export default educationRoutes
