import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "zoomPanelContainerRef",
    class: "zoom-panel",
    onMousewheel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.wheelZoomEnabled && _ctx.onZoom)),
    onMousemove: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.move && _ctx.move(...args))),
    onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isDown = false)),
    onMouseup: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isDown = false)),
    onMousedown: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onMouseDownToDrag && _ctx.onMouseDownToDrag(...args))),
    draggable: "true"
  }, [
    _createElementVNode("div", {
      ref: "zoomPanelContentRef",
      class: "zoom-panel-content",
      style: _normalizeStyle({
          transform: `scale(${_ctx.scaleValue})`,
          transformOrigin: '0% 0% 0px'
        })
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 4)
  ], 544))
}