export enum TableCore {
  Staff ='staff',
  Students ='students'
}

export enum OnboardingStageEnum{
  KickOff = 'Kick-Off',
  WarmUp = 'Warm-Up'
}

export enum TracingState{
  SEND = 'Enviar',
  SENT = 'Enviado',
  ALL = 'Todos'
}

export enum TracingTags{
  EXPLAINSLOWLY = 'Explicar más lento y de manera clara',
  EXAMPLES = 'Brindar mas ejemplos y casos',
  QUESTIONS = 'Contestar las preguntas',
  EXPLAINEFFICIENTLY = 'Gestionar más eficientemente los tiempos'

}

export enum tracingActions{
  ACTUATE = 'ACCIONAR',
  CALL = 'Call',
  MATERIAL = 'Material',
  APPROACH = 'Acercamiento',
  IMPROVED = 'Mejoró',
  REPEATED = 'Se repite',
  FIRST_TIME = 'Primera Vez',
  FEEDBACK = 'Feedback',
  ADVISORY = 'Asesoría',
}

export enum TracingStage{
  FINAL = 'Final de comisión',
  START = 'Comienzo de comisión',
  MID = 'Mitad de comisión',
  ALL = 'Todos'
}

export enum StudentsService{
  CV = 'Armá un CV que destaque',
  LINKEDIN = 'Descubrí el potencial de Linkedin',
  PORTFOLIO = 'Creá un portfolio genial',
  STRATEGY = 'Estrategia de búsqueda laboral',
  INTERVIEW = 'Preparate para tener excelentes entrevistas',
  PROPOSAL = 'Presupuestá y creá propuestas atractivas'
}

export enum ColumnFields {
  Person = 'person',
  Course = 'course',
  CoursePlan = 'coursePlan',
  Student = 'student',
  Teacher = 'teacher',
  Rating = 'rtg',
  Total = 'total',
  Tags = 'tags',
  TagText = 'text',
  Comments = 'comments',
  Countries = 'countries',
  Type = 'type',
  Versions = 'versions',
  Client = 'client',
  Duplicate = 'duplicate',
  ExpiresAt = 'expiresAt',
  Url = 'url',
  AfiliationDate = 'afiliationDate',
  Stage = 'stage',
  ActionButtons = 'actionButtons',
  Published = 'published',
  OnboardingVideos = 'videos',
  Etapas = 'stages',
  OnboardingActions = 'onboardingActions',
  OnboardingStatus = 'onboardingStatus',
  OnboardingStages = 'onboardingStages',
  TracingType = 'TracingType',
  TracingStage = 'TracingStage',
  TracingRating = 'TracingRating',
  TracingState = 'TracingState',
  TracingTags = 'TracingTags',
  TracingActions = 'TracingActions',
  CoordinatorReport = 'CoordinatoReport',
  TutorsRTGWeek = 'TutorsRTGWeek',
  TutorsRTGComision = 'TutorsRTGComision',
  StudentsName = 'studentsName',
  StudentsAComissions = 'studentsAComissions',
  StudentsCarrerino = 'studentsCarrerino',
  StudentsCategory = 'studentsCategory',
  StudentsAService = 'stuentsAService',
  Id ='id',
  Name = 'name',
  Start = 'start',
  End = 'end',
  DaysAndHours = 'daysAndHours',
  StudentCapacity = 'studentCapacity',
  StaffAssignment = 'staffAssignment',
  Status = 'status',
  CourseNumber = 'courseNumber',
  ClassNumber = 'classNumber',
  TeacherSummary = 'teacherSummary',
  TeacherSummaryTotal = 'teacherSummaryTotal',
  TutorSummary = 'tutorSummary',
  TutorSummaryTotal = 'tutorSummaryTotal',
  CoordinatorSummary = 'coordinatorSummary',
  CoordinatorSummaryTotal = 'coordinatorSummaryTotal',
  NpsSummary = 'npsSummary',
  ContentSummary = 'ContentSummary',
  ContentSummaryTotal = 'ContentSummaryTotal',
  ActiveCommissionsCount = 'activeCommissionsCount',
  FinishedAverage = 'finishedAverage',
  LastCommissionRating = 'lastCommissionRating',
  ColName = 'studentName',
  ColCarr = 'studentCarr',
  ColCat = 'studentCat',
  ColSer = 'studentServ',
  ComissionTeacher= 'comissionTeacher',
  ComissionCorrectionTF = 'comissionCorrectionTF',
  ComissionLimitDate = 'comissionLimitDate',
  ComissionGraduated = 'comissionGraduated',
  Warmup = 'warmup',
  Kickoff = 'kickoff'
}
