export enum PersonRoles {
  SuperAdmin = 'superadmin',
  Admin = 'admin',
  SalesExecutive = 'salesExecutive',
  Partner = 'partner',
  Finance = 'finance',
  Support = 'support',
  Mentor = 'mentor',
  Tutor = 'tutor',
  Student = 'student',
  Teacher = 'teacher',
  Subtitute= 'substitute',
  Recruiter = 'recruiter',
  Free = 'free',
  Corporate = 'corporate',
  TrainingManager = 'trainingManager',
  Supervisor = 'supervisor',
  Assistant = 'assistant',
  Coordinator = 'coordinator',
  Influencer = 'influencer',
  SubstitudTeacher = 'substitudteacher',
  ReplacementTeacher = 'repleacementTeacher',
  ReviewerTeachers = 'reviewerTeachers',
  AssistantSubstituteTeacher = 'assistantSubstituteTeacher',
  Empty=''
}

export enum PlatformUserRoleType {
  Student = 2,
  Staff = 3,
}
