import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-42fe5691")
const _hoisted_1 = { class: "loading-screen" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CoderSpinner = _resolveComponent("CoderSpinner")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_CoderSpinner)
    ])
  ]))
}