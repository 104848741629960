
import { computed, defineComponent, onBeforeMount, PropType, ref, toRefs, watch } from 'vue'
import { CSSColors } from '@/models/enums'
import { Country } from '@/models/countries/classes/Country'
import { EducationRouteNames } from '@/router/route-names'
import { ColumnFields } from '@/models/components/tables/enums'
import { Column } from '@/models/components/tables/Column'
import { IStaffFilters, StaffFilters } from '@/models/custom-filters/filters/StaffFilters'
import { SetMail } from '@/models/email-sending/SetMail'
import { CoursePlan } from '@/models/academic-definitions/classes/CoursePlan'
import { FilterMatchMode } from 'primevue/api'
import { StaffData, CourseDates, Course } from '@/models/staff-list/classes/StaffList'
import { StaffFiltersProps, CoursePerformanceFiltersProps } from '@/models/custom-filters/filters/enums'
import Slider from 'primevue/slider'
import CountrySelector from '@/components/courses/selectors/CountrySelector.vue'
import EmailSending from '@/components/modals/EmailSending.vue'
import CoursePlanSelector from '@/components/educationPlans/selectors/CoursePlanSelector.vue'

export default defineComponent({
  components: {
    CountrySelector,
    EmailSending,
    CoursePlanSelector,
    Slider
  },
  props: {
    staff: {
      require: true,
      type: Object as PropType<StaffData>
    },
    loading: {
      type: Boolean
    },
    allCoursePlans: {
      type: Array as PropType<CoursePlan[]>,
      required: true
    },
    allColumns: {
      require: true,
      type: Map as PropType<Map<ColumnFields, Column>>
    },
    selectedColumns: {
      require: false,
      type: Array as PropType<ColumnFields[]>
    },
    customFilterActive: {
      type: Object as PropType<StaffFilters>,
      require: false
    },
    contextId: {
      type: String,
      require: true
    }
  },
  emits: ['updateFilters', 'page'],
  setup (props, { emit }) {
    const { selectedColumns, allColumns, customFilterActive } = toRefs(props)
    const coursesFilter = ref<CoursePlan[]>([])
    const showEmailSendingModal = ref<boolean>(false)
    const staffData = computed(() => props.staff?.data)
    const recordsPerPage = computed(() => props.staff?.limit)
    const totalRecord = computed(() => props.staff?.count)
    const firstRecord = computed(() => props.staff?.skip)
    const setMail = new SetMail()
    const mapDays = new Map([[0, 'Dom'], [1, 'Lun'], [2, 'Mar'], [3, 'Mier'], [4, 'Jue'], [5, 'Vie'], [6, 'Sab']])
    const wuDone = ref<boolean>()
    const wuUnrealized = ref<boolean>()
    const koDone = ref<boolean>()
    const koUnrealized = ref<boolean>()

    const filters = ref<IStaffFilters>({
      countries: { value: null, matchMode: FilterMatchMode.CONTAINS },
      coursePlans: { value: null },
      activeCommissionsCount: { value: null },
      finishedAverage: { value: [null, null] },
      lastCommissionRating: { value: [null, null] },
      warmup: { value: null },
      kickoff: { value: null },
      columns: { value: null }
    } as IStaffFilters)
    const matchFilterWithCustomFilterActive = () => {
      filters.value.countries.value = customFilterActive.value?.countries.value ?? null
      filters.value.activeCommissionsCount.value = customFilterActive.value?.activeCommissionsCount.value ?? null
      filters.value.finishedAverage.value = customFilterActive.value?.finishedAverage.value ?? [null, null]
      filters.value.lastCommissionRating.value = customFilterActive.value?.lastCommissionRating.value ?? [null, null]
      filters.value.coursePlans.value = customFilterActive.value?.coursePlans.value ?? null
      filters.value.warmup.value = customFilterActive.value?.warmup.value ?? null
      filters.value.kickoff.value = customFilterActive.value?.kickoff.value ?? null
      wuDone.value = customFilterActive.value?.warmup.value as boolean
      koDone.value = customFilterActive.value?.kickoff.value as boolean
      filters.value.columns.value = customFilterActive.value?.columns?.value
        ? customFilterActive.value?.columns.value
        : allColumns.value
          ? Array.from(allColumns.value.keys())
          : null
    }
    watch(wuDone, () => {
      wuUnrealized.value = !wuDone.value
      filters.value.warmup.value = wuDone.value as boolean
    })
    watch(wuUnrealized, () => {
      wuDone.value = !wuUnrealized.value
    })
    watch(koDone, () => {
      koUnrealized.value = !koDone.value
      filters.value.kickoff.value = koDone.value as boolean
    })
    watch(koUnrealized, () => {
      koDone.value = !koUnrealized.value
    })
    const openEmailSendingModal = (mail: string, country: string, userId: string) => {
      showEmailSendingModal.value = !showEmailSendingModal.value
      setMail.country = country
      setMail.toMail = mail
      setMail.userId = userId
    }
    const getToolTip = (dates: Array<CourseDates>) => {
      let tool = ''
      if (dates) {
        if (dates.length > 0) {
          dates.forEach(date => {
            tool += mapDays.get(date.dayFrom) + ' de ' + date.timeFrom + ' a ' + date.timeTo + '\n'
          })
          return tool
        } else {
          tool = 'Sin horarios'
        }
      }
      return tool
    }
    const getStyleColumn = (courses: Array<Course>, course: Course) => {
      let css = 'tdColumnStaffData'
      if (courses.length > 1) {
        if (courses[courses.length - 1] !== course) {
          css = 'tdColumnStaffDataMulti'
        }
      }
      return css
    }

    onBeforeMount(matchFilterWithCustomFilterActive)
    watch(selectedColumns, matchFilterWithCustomFilterActive)

    const updateFilters = (f: IStaffFilters): void => {
      emit('updateFilters', f)
    }

    const cleanFilters = (fp: StaffFiltersProps[]): void => {
      fp.map(p => {
        (filters.value[p]).value = null
        return 0
      })
      emit('updateFilters', filters.value)
    }

    const cleanSlider = (fp: StaffFiltersProps[]): void => {
      fp.map(p => {
        (filters.value[p]).value = [null, null]
        return 0
      })
      emit('updateFilters', filters.value)
    }

    const sliderLabel = (slider: Array<any>) => {
      const sliderSet = new Set(slider.concat([null]))
      if (sliderSet.size === 1) {
        return ''
      }
      return slider
    }

    const formatDate = (dateKickOff: string) => {
      const d = new Date(dateKickOff)
      let month = String(d.getMonth() + 1)
      let day = String(d.getDate())
      const year = String(d.getFullYear())

      if (month.length < 2) month = '0' + month
      if (day.length < 2) day = '0' + day

      return `${day}/${month}/${year}`
    }

    return {
      openEmailSendingModal,
      showEmailSendingModal,
      setMail,
      Country,
      EducationRouteNames,
      ColumnFields,
      filters,
      CoursePerformanceFiltersProps,
      coursesFilter,
      staffData,
      recordsPerPage,
      firstRecord,
      totalRecord,
      getToolTip,
      getStyleColumn,
      updateFilters,
      cleanFilters,
      StaffFiltersProps,
      cleanSlider,
      sliderLabel,
      formatDate,
      wuDone,
      wuUnrealized,
      koDone,
      koUnrealized
    }
  },
  methods: {
    getBackgroundColor: (rating: number): string => {
      if (rating >= 4.7) {
        return CSSColors.GreenBrain
      } else if (rating > 3) {
        return CSSColors.YellowBrain
      } else {
        return CSSColors.RedBrain
      }
    }
  }
})
