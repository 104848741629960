import { IHomeworkDeliveryStats } from '@/models/course/builder/student.builder'
import { Calendar } from '@/models/course/classes/Calendar'
import { Class } from '@/models/course/classes/Class'
import { Course } from '@/models/course/classes/Course'
import { CoursesByPersonIdResponse } from '@/models/course/classes/CoursesByPersonIdResponse'
import { CourseStatus } from '@/models/course/enums'
import {
  ICoursesResponse,
  ICoursesResponseProcessed
} from '@/models/interfaces/dto'
import {
  ICourse,
  ICourseCertificate,
  ICoursesByPersonIdResponse
} from '@/models/course/interfaces'
import { ClassId, StudentId, TutorId } from '@/utils/types'
import { Api } from './api'
import { CRUD } from './crud'

class CourseApi implements CRUD<Course> {
  private readonly baseURL: string

  constructor() {
    this.baseURL = process.env.ACADEMIC_API_ENDPOINT
  }

  public async findById(id: string): Promise<Course> {
    const course = await Api.get<ICourse>(`${this.baseURL}/v1/courses/${id}`)
    return this.convertDTOToCourse(course)
  }

  public async findAll(): Promise<Course[]> {
    const res = await Api.get<ICourse[]>(`${this.baseURL}/v1/courses`)
    return res.map((c) => this.convertDTOToCourse(c))
  }

  public async findAllByStatus(
    query: Record<string, string | boolean | number | Date>
  ): Promise<ICoursesResponseProcessed> {
    const res = await Api.get<ICoursesResponse>(
      `${this.baseURL}/v1/courses/findAllByStatus`,
      query
    )
    return {
      ...res,
      data: res.data.map(this.convertDTOToCourse)
    }
  }

  public async findQtyByStatus(status: CourseStatus): Promise<number> {
    return await Api.get<number>(`${this.baseURL}/v1/courses/qty/${status}`)
  }

  public async getCourseHomeworkStatsByStudent(
    courseId: string
  ): Promise<Map<string, IHomeworkDeliveryStats>> {
    const dto = await Api.get<Record<string, IHomeworkDeliveryStats>>(
      `${this.baseURL}/v1/course/${courseId}/homework-delivery-stats`
    )
    return new Map(Object.entries(dto))
  }

  private convertDTOToCourse(course: ICourse): Course {
    return new Course(
      course._id,
      course.id,
      course.v1CourseId,
      course.enable,
      course.country,
      course.status,
      course.name,
      course.statusChangedAt,
      course.classesTaught,
      course.classes.map(
        (c) =>
          new Class(
            c._id,
            c.additional,
            c.name,
            c.studentAttendances,
            c.staffAttendances,
            c.assignedStaff,
            new Calendar(
              c.calendar.start,
              c.calendar.end,
              c.calendar.days,
              c.calendar.day,
              c.calendar.startHour,
              c.calendar.endHour
            ),
            c.links,
            c.expirationDay,
            c.classPlan
          )
      ),
      course.afterClasses.map(
        (c) =>
          new Class(
            c._id,
            c.additional,
            c.name,
            c.studentAttendances,
            c.staffAttendances,
            c.assignedStaff,
            new Calendar(
              c.calendar.start,
              c.calendar.end,
              null,
              c.calendar.day,
              c.calendar.startHour,
              c.calendar.endHour
            ),
            c.links,
            c.expirationDay,
            c.classPlan
          )
      ),
      course.studentCapacity,
      course.program,
      course.zoom,
      course.powerBi,
      course.toolsLinks,
      new Calendar(
        course.calendar.start,
        course.calendar.end,
        course.calendar.days,
        null,
        course.calendar.startHour,
        course.calendar.endHour
      ),
      course.configTags,
      course.tutoring
    )
  }

  public async findByPersonId(
    personId: string
  ): Promise<CoursesByPersonIdResponse> {
    const res = await Api.get<ICoursesByPersonIdResponse>(
      `${this.baseURL}/v1/courses/summaries/by-person-id/${personId}`
    )
    return new CoursesByPersonIdResponse(res.student, res.staff)
  }

  public async findClassesAndTutoringAttendancesByCourseId(
    courseId: string
  ): Promise<Record<ClassId, Record<TutorId, StudentId[]>>> {
    return Api.get<Record<ClassId, Record<TutorId, StudentId[]>>>(
      `${this.baseURL}/v1/course-classes/${courseId}/tutoring-attendances`
    )
  }
}

export const CourseAPI = new CourseApi()

const baseURL = process.env.ACADEMIC_API_ENDPOINT

async function getCertificatesByCourseId(
  id: string
): Promise<ICourseCertificate[]> {
  return await Api.get<ICourseCertificate[]>(
    `${baseURL}/v1/courses/certificates/course/${id}`
  )
}

export { getCertificatesByCourseId }
