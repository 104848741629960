import { TracingState, TracingStage, tracingActions } from '@/models/components/tables/enums'
import { AssignationOrigin, AssignationType, ContentStage, OnBoardingStage, PlannedStage, StaffRole, WarmUpStage } from '../enums'
import { LevelInfo } from './Level'
import { CamadaInfo } from './Camada'
import { Rating } from './Rating'
import { UserInfo } from './UserInfo'

export class Tags {
  value: number
  title: string
  constructor (
    value: number,
    title: string
  ) {
    this.value = value
    this.title = title
  }
}

export class TracingTags {
  positive: Tags[]
  negative: Tags[]
  neutral: Tags[]
  constructor (
    positive: Tags[],
    negative: Tags[],
    neutral: Tags[]
  ) {
    this.positive = positive
    this.negative = negative
    this.neutral = neutral
  }
}

class CoordinatorsTags {
  teacher: TracingTags
  tutor: TracingTags
  constructor (
    teacher: TracingTags,
    tutor: TracingTags
  ) {
    this.teacher = teacher
    this.tutor = tutor
  }
}

export class StaffTracing {
  person: string
  course: number
  assignationDate: Date
  assignationType: AssignationType
  assignationOrigin: AssignationOrigin
  responsable: string
  kickOffDate: Date | string
  onBoardingStage: OnBoardingStage
  warmupStage: WarmUpStage | string
  contentStage: ContentStage
  plannedStage: PlannedStage
  comment: string
  kickOffDone: boolean
  tracingState: TracingState
  tracingTags: TracingTags
  tracingActions: tracingActions
  tracingStage: PlannedStage
  rating: Rating
  weekRTG: Rating
  comRTG: Rating
  createdAt: Date
  updatedAt: Date
  userId: string
  camadaId: string
  levelId: string
  levelInfo: LevelInfo
  role: StaffRole
  userInfo: UserInfo
  camadaInfo: CamadaInfo
  coordinatorTags: CoordinatorsTags
  __v: number
  _id: string
  constructor (
    person: string,
    course: number,
    assignationDate: Date,
    assignationType: AssignationType,
    assignationOrigin: AssignationOrigin,
    responsable: string,
    kickOffDate: Date | string,
    onBoardingStage: OnBoardingStage,
    warmupStage: WarmUpStage,
    contentStage: ContentStage,
    plannedStage: PlannedStage,
    comment: string,
    kickOffDone: boolean,
    tracingState: TracingState,
    tracingTags: TracingTags,
    tracingActions: tracingActions,
    tracingStage: PlannedStage,
    userId: string,
    levelId: string,
    levelInfo: LevelInfo,
    camadaId: string,
    rating: Rating,
    weekRTG: Rating,
    comRTG: Rating,
    createdAt: Date,
    updatedAt: Date,
    role: StaffRole,
    userInfo: UserInfo,
    camadaInfo: CamadaInfo,
    coordinatorTags: CoordinatorsTags,
    __v: number,
    _id: string
  ) {
    this.person = person
    this.course = course
    this.assignationDate = assignationDate
    this.assignationType = assignationType
    this.assignationOrigin = assignationOrigin
    this.responsable = responsable
    this.kickOffDate = kickOffDate
    this.onBoardingStage = onBoardingStage
    this.warmupStage = warmupStage
    this.contentStage = contentStage
    this.plannedStage = plannedStage
    this.comment = comment
    this.kickOffDone = kickOffDone
    this.tracingState = tracingState
    this.tracingTags = tracingTags
    this.tracingActions = tracingActions
    this.tracingStage = tracingStage
    this.rating = rating
    this.role = role
    this.weekRTG = weekRTG
    this.comRTG = comRTG
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.__v = __v
    this._id = _id
    this.userId = userId
    this.camadaId = camadaId
    this.levelId = levelId
    this.levelInfo = levelInfo
    this.userInfo = userInfo
    this.camadaInfo = camadaInfo
    this.coordinatorTags = coordinatorTags
  }
}
