import { TypeFilter, TableName } from '../enum'

export type FilterValue<T> = { value: null | T }
export type FilterProps = Record<string, FilterValue<unknown>>

export class CustomFilter {
  filterProps: FilterProps
  userId: string
  typeFilter: TypeFilter
  tableName: TableName
  deleted?: boolean
  name?: string
  _id?: string
  __v?: number
  updatedAt?: Date
  createdAt?: Date

  constructor (
    filterProps: FilterProps,
    userId: string,
    typeFilter: TypeFilter,
    tableName: TableName,
    name?: string,
    deleted?: boolean,
    _id?: string, /* Opcionales ya que pueden estar vacías a la hora de la creación */
    __v?: number,
    updatedAt?: Date,
    createdAt?: Date
  ) {
    let newFilterProps = filterProps
    if (typeFilter === TypeFilter.Filter) {
      delete newFilterProps.columns
    } else {
      newFilterProps = { columns: filterProps.columns } as FilterProps
    }
    this.filterProps = newFilterProps
    this.userId = userId
    this.typeFilter = typeFilter
    this.tableName = tableName
    this.deleted = deleted
    this.name = name
    this._id = _id
    this.__v = __v
    this.updatedAt = updatedAt
    this.createdAt = createdAt
  }
}

export class CustomFilterByType {
  _id: TypeFilter
  data: Array<CustomFilter>
  constructor (
    _id: TypeFilter,
    data: Array<CustomFilter>
  ) {
    this._id = _id
    this.data = data
  }
}
