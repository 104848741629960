
import { defineComponent, onMounted, ref, reactive, Ref } from 'vue'
import { ContractRoles, ContractTypeOption, ContractGroups, ContractStatus, ContractType } from '@/models/contracts'
import { contractRole, contractGroup, contractStatus, contractStatusTextColor } from '../../../helpers'
import { FilterMatchMode } from 'primevue/api'
import { DataTableFilterEvent, DataTableFilterMeta, DataTablePageEvent } from 'primevue/datatable'
import { formatDate } from '@/utils/formatDate'
import { FormatDates } from '@/models/enums'
import { useUserSessionStore } from '@/store/modules/commons/user-session-store'
import { useToastService } from '@/hooks/useToastService'
import { required } from '@vuelidate/validators'
import { ValidationArgs, useVuelidate } from '@vuelidate/core'
import Toolbar from 'primevue/toolbar'
import ContractsApi from '@/api/contracts.api'
import Menu from 'primevue/menu'
import { AxiosError } from 'axios'

interface SearchParams extends Record<string, unknown> {
  skip: number;
  limit: number;
}

type ContractTypeForm = Omit<ContractType, 'id' | 'createdAt' | 'updatedAt' | 'role' | 'active' | 'roleAlias' | 'typeAlias'> & { role: ContractRoles | null }
type ContractTypeFormValidation = Record<keyof ContractTypeForm, ValidationArgs>

export default defineComponent({
  components: {
    Toolbar
  },
  setup () {
    const actionMenu = ref<Menu>()
    const contractTypes = ref<ContractType[]>([])
    const loading = ref(true)
    const displayDialog = ref(false)
    const typeOptions = ref<ContractTypeOption[]>([])
    const session = useUserSessionStore()
    const toast = useToastService()
    const roleOptions = ref([])
    const loadingRoleOptions = ref(false)
    const creatingContractType = ref(false)
    const totalRecords = ref(0)
    const params = reactive<SearchParams>({
      skip: 0,
      limit: 50
    })
    const filters = ref<DataTableFilterMeta>({
      role: {
        value: null, matchMode: FilterMatchMode.IN
      },
      group: {
        value: null, matchMode: FilterMatchMode.IN
      },
      type: {
        value: null,
        matchMode: FilterMatchMode.IN
      }
    })
    const formInitialState = {
      createdBy: session.currentUser._id,
      monthlyLife: null,
      group: '',
      type: '',
      role: null
    }
    const formValue = reactive<ContractTypeForm>({ ...formInitialState })

    const editingContractType = ref<ContractType | null>(null)
    const v$ = useVuelidate<ContractTypeFormValidation, ContractTypeForm>({
      createdBy: { required },
      group: { required },
      role: { required },
      type: { required },
      monthlyLife: {}
    }, formValue)
    const loadContracts = async () => {
      try {
        loading.value = true
        const { total, data } = await ContractsApi.getContractTypes(params)
        typeOptions.value = await ContractsApi.getContractTypeOptions()
        totalRecords.value = total
        contractTypes.value = data
      } catch (error) {
        console.error(error)
        toast.showError('Error al cargar los contratos')
      } finally {
        loading.value = false
      }
    }
    const onPage = (ev: DataTablePageEvent) => {
      params.skip = ev.first
      params.limit = ev.rows
      loadContracts()
    }
    const onFilter = (ev: DataTableFilterEvent) => {
      const { status, role, group, type } = ev.filters as unknown as Record<string, Ref>
      params.status = status.value
      params.roles = role.value
      params.groups = group.value
      params.types = type.value
      loadContracts()
    }
    const loadRoleOptions = async (group: string, type: string) => {
      try {
        loadingRoleOptions.value = true
        toast.showInfo('Cargando opciones de rol')
        roleOptions.value = (await ContractsApi.getContractTypeRoles({ group, type })).roles
      } catch (error) {
        toast.showError('Error al cargar las opciones disponibles para el campo de Rol, vuelva a intentarlo...')
      } finally {
        loadingRoleOptions.value = false
      }
    }
    const onHideDialog = () => {
      editingContractType.value = null
      formValue.group = formInitialState.group
      formValue.monthlyLife = formInitialState.monthlyLife
      formValue.type = formInitialState.type
      formValue.role = formInitialState.role
      roleOptions.value = []
    }
    onMounted(loadContracts)
    return {
      actionMenu,
      editingContractType,
      contractRole,
      typeOptions,
      contractGroup,
      contractTypes,
      contractStatus,
      ContractStatus,
      contractStatusTextColor,
      filters,
      formatDate,
      displayDialog,
      FormatDates,
      loadContracts,
      loading,
      onFilter,
      onPage,
      params,
      totalRecords,
      formValue,
      v$,
      loadingRoleOptions,
      creatingContractType,
      groupOptions: [
        {
          label: 'Regular',
          value: 'Regular'
        },
        {
          label: 'English Academy',
          value: 'EnglishAcademy'
        }
      ],
      roleOptions,
      roleFilterOptions: Object.values(ContractRoles)
        .map((role) => ({ name: contractRole(role), value: role })),
      groupFilterOptions: Object.values(ContractGroups)
        .map((group) => ({ name: contractGroup(group), value: group })),
      loadRoleOptions,
      async edit (item: ContractType) {
        editingContractType.value = item
        formValue.group = item.group
        formValue.monthlyLife = item.monthlyLife
        formValue.type = item.type
        await loadRoleOptions(item.group, item.type)
        formValue.role = item.role
        displayDialog.value = true
      },
      async upsertContractType () {
        try {
          creatingContractType.value = true
          if (!editingContractType.value) {
            await ContractsApi.createContractType({
              ...formValue,
              active: true,
              createdBy: session.currentUser._id
            })
            toast.showSuccessfullyDone('Creado')
            displayDialog.value = false
            onHideDialog()
            loadContracts()
          } else {
            const contractTypeUpdated = await ContractsApi.updateContractType(editingContractType.value.id, formValue)
            contractTypes.value = contractTypes.value.map(
              (ct) => ct.id === editingContractType.value?.id ? contractTypeUpdated : ct
            )
            toast.showSuccessfullyDone('Actualizado')
            displayDialog.value = false
            onHideDialog()
          }
        } catch (error) {
          console.error(JSON.stringify(error))
          const defaultMessage = 'Error al crear el tipo contrato, intente nuevamente'
          if ((error as any).isAxiosError) {
            const errorAxios = error as AxiosError
            toast.showError(errorAxios.response?.data?.message || defaultMessage)
          } else {
            toast.showError(defaultMessage)
          }
        } finally {
          creatingContractType.value = false
        }
      },
      onHideDialog
    }
  }
})
