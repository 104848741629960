import { RouteRecordRaw } from 'vue-router'
import { JobBankRouteNames } from '@/router/route-names'

const JBRoutes: Array<RouteRecordRaw> = [
  {
    path: 'companies',
    name: JobBankRouteNames.COMPANIES,
    component: () => import('@/views/jobBank/Companies/Companies.vue'),
    redirect: () => ({ name: JobBankRouteNames.COMPANIES_LIST }),
    children: [
      {
        path: 'main',
        name: JobBankRouteNames.COMPANIES_LIST,
        component: () => import('@/views/jobBank/Companies/CompaniesList.vue')
      },
      {
        path: 'view/:jobBankId',
        name: JobBankRouteNames.EDIT_JOBBANK,
        component: () => import('@/views/jobBank/children/EditJobBank.vue'),
        redirect: to => ({
          name: JobBankRouteNames.EDIT_JOBBANK_PREVIEW,
          params: {
            jobBankId: to.params.jobBankId
          }
        }),
        children: [
          {
            path: 'preview',
            name: JobBankRouteNames.EDIT_JOBBANK_PREVIEW,
            component: () =>
              import('@/views/jobBank/children/EditJobBankPreview.vue')
          },
          {
            path: 'edit',
            name: JobBankRouteNames.EDIT_COMPANY,
            component: () =>
              import('@/views/jobBank/Companies/CreateCompany.vue'),
            redirect: () => ({ name: JobBankRouteNames.EDIT_GENERALINFO }),
            children: [
              {
                path: 'general-info',
                name: JobBankRouteNames.EDIT_GENERALINFO,
                component: () =>
                  import('@/views/jobBank/Companies/SGeneralInfo.vue')
              },
              {
                path: 'contact-info',
                name: JobBankRouteNames.EDIT_CONTACTINFO,
                component: () =>
                  import('@/views/jobBank/Companies/SContactInfo.vue')
              },
              {
                path: 'profile',
                name: JobBankRouteNames.EDIT_PROFILE,
                component: () =>
                  import('@/views/jobBank/Companies/SProfile.vue')
              },
              {
                path: 'profile-edited',
                name: JobBankRouteNames.EDIT_PROFILE_EDITED,
                component: () =>
                  import('@/views/jobBank/Companies/SProfileCreated.vue')
              }
            ]
          }
        ]
      },
      {
        path: 'create',
        name: JobBankRouteNames.CREATE_COMPANY,
        component: () => import('@/views/jobBank/Companies/CreateCompany.vue'),
        redirect: () => ({ name: JobBankRouteNames.S_GENERALINFO }),
        children: [
          {
            path: 'general-info',
            name: JobBankRouteNames.S_GENERALINFO,
            component: () =>
              import('@/views/jobBank/Companies/SGeneralInfo.vue')
          },
          {
            path: 'contact-info',
            name: JobBankRouteNames.S_CONTACTINFO,
            component: () =>
              import('@/views/jobBank/Companies/SContactInfo.vue')
          },
          {
            path: 'profile',
            name: JobBankRouteNames.S_PROFILE,
            component: () => import('@/views/jobBank/Companies/SProfile.vue')
          },
          {
            path: 'profile-created',
            name: JobBankRouteNames.S_PROFILE_CREATED,
            component: () =>
              import('@/views/jobBank/Companies/SProfileCreated.vue')
          }
        ]
      }
    ]
  },
  {
    path: 'job-offers',
    name: JobBankRouteNames.JOB_OFFERS,
    component: () => import('@/views/jobBank/JobOffer/JobOffers.vue'),
    redirect: () => ({ name: JobBankRouteNames.JOB_OFFERS_LIST }),
    children: [
      {
        path: 'main',
        name: JobBankRouteNames.JOB_OFFERS_LIST,
        component: () => import('@/views/jobBank/JobOffer/JobOffersList.vue')
      },
      {
        path: 'view/:jobOfferId',
        name: JobBankRouteNames.EDIT_JOBOFFER,
        component: () => import('@/views/jobBank/children/EditJobOffer.vue'),
        redirect: to => ({
          name: JobBankRouteNames.EDIT_JOBOFFER_PREVIEW,
          params: {
            jobOfferId: to.params.jobOfferId
          }
        }),
        children: [
          {
            path: 'preview',
            name: JobBankRouteNames.EDIT_JOBOFFER_PREVIEW,
            component: () =>
              import('@/views/jobBank/children/EditJobOfferPreview.vue')
          },
          {
            path: 'edit',
            name: JobBankRouteNames.EDIT_JOBOFFER2,
            component: () =>
              import('@/views/jobBank/JobOffer/CreateJobOffer.vue'),
            redirect: () => ({ name: JobBankRouteNames.EDIT_OFFERINFO }),
            children: [
              {
                path: 'offer-info',
                name: JobBankRouteNames.EDIT_OFFERINFO,
                component: () =>
                  import('@/views/jobBank/JobOffer/OfferInfo.vue')
              },
              {
                path: 'company-info',
                name: JobBankRouteNames.EDIT_COMPANYINFO,
                component: () =>
                  import('@/views/jobBank/JobOffer/CompanyInfo.vue')
              },
              {
                path: 'publish',
                name: JobBankRouteNames.EDIT_PUBLISHJOBOFFER,
                component: () =>
                  import('@/views/jobBank/JobOffer/PublishJobOffer.vue')
              },
              {
                path: 'published',
                name: JobBankRouteNames.EDIT_PUBLISHEDJOBOFFER,
                component: () =>
                  import('@/views/jobBank/JobOffer/PublishedJobOffer.vue')
              }
            ]
          }
        ]
      },
      {
        path: 'create',
        name: JobBankRouteNames.CREATE_JOB_OFFER,
        component: () => import('@/views/jobBank/JobOffer/CreateJobOffer.vue'),
        redirect: () => ({ name: JobBankRouteNames.OFFERINFO }),
        children: [
          {
            path: 'offer-info',
            name: JobBankRouteNames.OFFERINFO,
            component: () => import('@/views/jobBank/JobOffer/OfferInfo.vue')
          },
          {
            path: 'company-info',
            name: JobBankRouteNames.COMPANYINFO,
            component: () => import('@/views/jobBank/JobOffer/CompanyInfo.vue')
          },
          {
            path: 'publish',
            name: JobBankRouteNames.PUBLISHJOBOFFER,
            component: () =>
              import('@/views/jobBank/JobOffer/PublishJobOffer.vue')
          },
          {
            path: 'published',
            name: JobBankRouteNames.PUBLISHEDJOBOFFER,
            component: () =>
              import('@/views/jobBank/JobOffer/PublishedJobOffer.vue')
          }
        ]
      }
    ]
  },
  {
    path: 'Job-Offers-Scraper',
    name: JobBankRouteNames.JOB_SCRAPER,
    component: () => import('@/views/jobBank/JobOffer/JobOffersScraper.vue'),
    redirect: () => ({ name: JobBankRouteNames.JOB_SCRAPER_LIST }),
    children: [
      {
        path: 'main',
        name: JobBankRouteNames.JOB_SCRAPER_LIST,
        component: () => import('@/views/jobBank/JobOffer/JobOffersScraperList.vue')
      }]
  }
]

export default JBRoutes
