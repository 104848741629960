import { Country } from '@/models/countries/classes/Country'
import { StaffMember } from '@/models/course/builder/staffMember.builder'
import { FormatDates } from '@/models/enums'
import { PersonRoles } from '@/models/person/enums'
import { formatDate } from '@/utils/formatDate'
import { _concatMaps } from '@/utils/mapper'
import { ClassAverageClassification, CourseFeedbackTag, CourseTypeTag, FeedbackCharacter } from '../../enums'
import { CourseAverageRating } from '../../interfaces/dto/CourseAverageRating'
import { CourseFeedbackRating } from '../../interfaces/dto/CourseFeedbackRating'
import { IFeedbackSummary, IFeedbackSummaryTotal } from '../interfaces/IFeedbackSummary'
import { INPSClassSummary } from '../interfaces/INPSClassSummary'
import { IStaffNameAndAverageRating } from '../interfaces/IStaffNameAndAverageRating'
import { AverageSummary } from './AverageSummary'
import { CourseDates } from './CourseDates'
import { FeedbackSummary, FeedbackSummaryTotal } from './FeedbackSummary'
import { NPSClassSummary } from './NPSClassSummary'

export class ClassFeedbackSummary {
  className: string
  classId: string
  classNumber: number
  totalClass: number
  contentSummary: FeedbackSummary | null
  contentSummaryTotal: number
  totalDeliveries: number
  totalFinalDeliveries: number
  totalGraduates: number
  courseId: string
  courseNumber: number
  courseName: string
  country: string
  start: Date
  end: Date
  date: Date
  limitDate: Date
  days: number[]
  classDays: string[]
  courseDates: CourseDates[]
  tags: CourseFeedbackTag[]
  personSummary: Map<string, FeedbackSummary>
  personSummaryTotal: Map<string, FeedbackSummaryTotal>
  teacherSummary: FeedbackSummary | null
  teacherSummaryTotal: number | null
  tutorSummary: FeedbackSummary | null
  tutorSummaryTotal: number | null
  coordinatorSummary: FeedbackSummary | null
  coordinatorSummaryTotal: number
  npsSummary: FeedbackSummary | null
  _id: string
  get latinAmericaDates (): { start: string, end: string, limitDate: string } {
    return {
      start: formatDate(this.start, FormatDates.LatinAmerica),
      end: formatDate(this.end, FormatDates.LatinAmerica),
      limitDate: formatDate(this.limitDate, FormatDates.LatinAmerica)
    }
  }

  get parsedTags (): CourseTypeTag[] {
    const courseTypes = new Set(Object.keys(CourseTypeTag).map(k => k.toLowerCase()))
    return this.tags?.filter(t => courseTypes.has(t)).map(t => {
      const opts = {
        [CourseFeedbackTag.B2b]: CourseTypeTag.B2b,
        [CourseFeedbackTag.Workshop]: CourseTypeTag.Workshop,
        [CourseFeedbackTag.Intensive]: CourseTypeTag.Intensive
      }
      return opts[(t as CourseFeedbackTag.B2b | CourseFeedbackTag.Workshop | CourseFeedbackTag.Intensive)]
    })
  }

  get courseProgress (): number {
    return this.classDays?.filter(day => new Date(day) < new Date()).length
  }

  constructor (
    className: string,
    classId: string,
    classNumber: number,
    totalClass: number,
    contentSummary: IFeedbackSummary,
    contentSummaryTotal: number,
    totalDeliveries: number,
    totalFinalDeliveries: number,
    totalGraduates: number,
    courseId: string,
    courseNumber: number,
    courseName: string,
    country: string,
    date: Date,
    start: Date,
    end: Date,
    limitDate: Date,
    personSummary: Record<string, IFeedbackSummary>,
    personSummaryTotal: Record<string, IFeedbackSummaryTotal>,
    teacherSummary: IFeedbackSummary,
    teacherSummaryTotal: number,
    tutorSummary: IFeedbackSummary,
    tutorSummaryTotal: number,
    coordinatorSummary: IFeedbackSummary,
    coordinatorSummaryTotal: number,
    npsSummary: IFeedbackSummary,
    _id: string,
    days: number[],
    tags: CourseFeedbackTag[],
    classDays: string[],
    courseDates: CourseDates[]
  ) {
    this.className = className
    this.classId = classId
    this.classNumber = classNumber
    this.totalClass = totalClass
    this.contentSummary = contentSummary
      ? new FeedbackSummary(contentSummary)
      : null
    this.contentSummaryTotal = contentSummaryTotal
    this.totalDeliveries = totalDeliveries
    this.totalFinalDeliveries = totalFinalDeliveries
    this.totalGraduates = totalGraduates
    this.npsSummary = npsSummary
      ? new FeedbackSummary(npsSummary)
      : null
    this.courseNumber = courseNumber
    this.courseId = courseId
    this.country = country
    this.date = date
    this.courseName = courseName
    this.start = start
    this.end = end
    this.limitDate = limitDate
    this._id = _id
    this.personSummary = new Map(
      Object.values(personSummary || []).map(s => [s._id, new FeedbackSummary(s)])
    )
    this.personSummaryTotal = new Map(
      Object.values(personSummaryTotal || []).map(s => [s._id, new FeedbackSummaryTotal(s)])
    )
    this.teacherSummary = teacherSummary
      ? new FeedbackSummary(teacherSummary)
      : null
    this.teacherSummaryTotal = teacherSummaryTotal
    this.tutorSummary = tutorSummary ? new FeedbackSummary(tutorSummary) : null
    this.tutorSummaryTotal = tutorSummaryTotal
    this.coordinatorSummary = coordinatorSummary ? new FeedbackSummary(coordinatorSummary) : null
    this.coordinatorSummaryTotal = coordinatorSummaryTotal
    this.days = days
    this.tags = tags
    this.classDays = classDays
    this.courseDates = courseDates
  }

  get classAverageClassification (): ClassAverageClassification {
    return this.classAverage >= 4.7 ? ClassAverageClassification.Good : ClassAverageClassification.Bad
  }

  get allNames (): string {
    const arrayFrom = Array.from(this.personSummary.values())
    const allPersonNames = arrayFrom.map(fs => fs.name).join('|')
    return allPersonNames
  }

  get classAverage (): number {
    const feedbacksSummaries = (this.teacherSummary?.rating?.value || 0) + (this.tutorSummary?.rating?.value || 0) + (this.contentSummary?.rating?.value || 0) + (this.npsSummary?.rating?.value || 0)
    const feedbacksCount = (this.teacherSummary?.rating ? 1 : 0) + (this.tutorSummary?.rating ? 1 : 0) + (this.contentSummary?.rating ? 1 : 0) + (this.npsSummary?.rating ? 1 : 0)
    return feedbacksSummaries / feedbacksCount
  }

  public getStaffSummariesByRole (role: PersonRoles): FeedbackSummary[] {
    return Array.from(this.personSummary.values()).filter(s => s.role === role)
  }

  static empty (): Array<ClassFeedbackSummary> {
    return []
  }

  static getAverage (summaries: Array<ClassFeedbackSummary>): AverageSummary {
    const summariesProccesed = summaries.reduce((summaries, summary) => {
      const contentSummary = summary.contentSummary?.ratingsByStudents?.reduce((contentSummary, rating) => {
        contentSummary.ratingSummation += rating
        contentSummary.feedbackCount++
        return contentSummary
      }, { ratingSummation: 0, feedbackCount: 0 } as CourseFeedbackRating)

      summaries.contentSummary.ratingSummation += contentSummary?.ratingSummation || 0
      summaries.contentSummary.feedbackCount += contentSummary?.feedbackCount || 0

      const teacherSummary = summary.teacherSummary?.ratingsByStudents?.reduce((teacherSummary, rating) => {
        teacherSummary.ratingSummation += rating
        teacherSummary.feedbackCount++
        return teacherSummary
      }, { ratingSummation: 0, feedbackCount: 0 } as CourseFeedbackRating)

      summaries.teacherSummary.ratingSummation += teacherSummary?.ratingSummation || 0
      summaries.teacherSummary.feedbackCount += teacherSummary?.feedbackCount || 0

      const tutorSummary = summary.tutorSummary?.ratingsByStudents?.reduce((tutorSummary, rating) => {
        tutorSummary.ratingSummation += rating
        tutorSummary.feedbackCount++
        return tutorSummary
      }, { ratingSummation: 0, feedbackCount: 0 } as CourseFeedbackRating)

      summaries.tutorSummary.ratingSummation += tutorSummary?.ratingSummation || 0
      summaries.tutorSummary.feedbackCount += tutorSummary?.feedbackCount || 0
      return summaries
    }, {
      contentSummary: { value: 0, feedbackCount: 0, ratingSummation: 0 },
      teacherSummary: { value: 0, feedbackCount: 0, ratingSummation: 0 },
      tutorSummary: { value: 0, feedbackCount: 0, ratingSummation: 0 }
    } as {
      contentSummary: { value: number, feedbackCount: number, ratingSummation: number },
      teacherSummary: { value: number, feedbackCount: number, ratingSummation: number },
      tutorSummary: { value: number, feedbackCount: number, ratingSummation: number },
    })

    summariesProccesed.contentSummary.value = (summariesProccesed.contentSummary.ratingSummation / summariesProccesed.contentSummary.feedbackCount) || 0
    summariesProccesed.teacherSummary.value = (summariesProccesed.teacherSummary.ratingSummation / summariesProccesed.teacherSummary.feedbackCount) || 0
    summariesProccesed.tutorSummary.value = (summariesProccesed.tutorSummary.ratingSummation / summariesProccesed.tutorSummary.feedbackCount) || 0

    return new AverageSummary(
      { value: summariesProccesed.contentSummary.value },
      { value: summariesProccesed.teacherSummary.value },
      { value: summariesProccesed.tutorSummary.value }
    )
  }

  /**
   * Returns a Map with avg rating by class.
   * @param summaries
   * @param teacher is optional, in case evaluating teacher
   * @returns Map<string, number>
   */
  static getAverageByClass (
    summaries: ClassFeedbackSummary[],
    teacher?: StaffMember
  ): CourseAverageRating {
    const courseAverageRating: CourseAverageRating = { classes: new Map(), course: { rating: 0 } }
    const mapClass = new Map<string, number | null>()
    const accumulated: CourseFeedbackRating = { ratingSummation: 0, feedbackCount: 0 }
    for (const summary of summaries) {
      if (!teacher) {
        mapClass.set(summary.classId, summary.contentSummary?.rating?.value || null)
        summary.contentSummary?.ratingsByStudents?.map((rating) => {
          accumulated.ratingSummation += rating
          accumulated.feedbackCount++
          return rating
        })
      } else {
        const person = summary.personSummary.get(teacher?.user?.personId as string)
        mapClass.set(
          summary.classId,
          person?.rating
            ?.value || null
        )
        person?.ratingsByStudents?.map((rating) => {
          accumulated.ratingSummation += rating
          accumulated.feedbackCount++
          return rating
        })
      }
    }
    courseAverageRating.course.rating = (accumulated.ratingSummation / accumulated.feedbackCount) || 0
    courseAverageRating.classes = mapClass
    return courseAverageRating
  }

  /**
   * Return all the tags text in use in an array of summaries.
   * The search is performed based on a feedback character
   * @param summaries
   * @param feedbackCharacter
   * @param id
   * @returns
   */
  static getAllTagsTexts (
    summaries: ClassFeedbackSummary[],
    feedbackCharacter: FeedbackCharacter,
    id?: string
  ): Array<string> {
    const mapTags: Map<string, string> = new Map()
    for (const summary of summaries) {
      const allTags =
      id && feedbackCharacter === FeedbackCharacter.Positive
        ? summary.personSummary.get(id)?.tagsFeedbacks?.numberTags.get(FeedbackCharacter.Positive)
        : !id && feedbackCharacter === FeedbackCharacter.Positive
            ? summary.contentSummary?.tagsFeedbacks?.numberTags.get(FeedbackCharacter.Positive)
            : id && feedbackCharacter === FeedbackCharacter.Negative
              ? _concatMaps(
                (summary.personSummary
                  .get(id)
                  ?.tagsFeedbacks?.numberTags.get(
                    FeedbackCharacter.Negative
                  ) as Map<string, number>) || new Map(),
                (summary.personSummary
                  .get(id)
                  ?.tagsFeedbacks?.numberTags.get(
                    FeedbackCharacter.Neutral
                  ) as Map<string, number>) || new Map()
              )
              : _concatMaps(
                (summary.contentSummary?.tagsFeedbacks?.numberTags.get(
                  FeedbackCharacter.Negative
                ) as Map<string, number>) || new Map(),
                (summary.contentSummary?.tagsFeedbacks?.numberTags.get(
                  FeedbackCharacter.Neutral
                ) as Map<string, number>) || new Map()
              )
      if (allTags) {
        Array.from(allTags.keys()).map(key => {
          const tagExist = mapTags.get(key)
          if (!tagExist) {
            mapTags.set(key, key)
          }
          return 0
        })
      }
    }
    return Array.from(mapTags.keys())
  }

  static getdetailFeedbackCountByClass (
    summaries: ClassFeedbackSummary[],
    teacherId?: string
  ): Map<string, Record<string, number>> {
    const mapByClass: Map<string, Record<string, number>> = new Map()
    for (const summary of summaries) {
      if (!teacherId) {
        mapByClass.set(
          summary._id,
          ClassFeedbackSummary._getObjectDetailFeedbackCount(
            summary.contentSummary?.detailFeedbackCount || new Map()
          )
        )
      } else {
        mapByClass.set(
          summary._id,
          ClassFeedbackSummary._getObjectDetailFeedbackCount(
            summary.personSummary.get(teacherId)?.detailFeedbackCount ||
              new Map()
          )
        )
      }
    }
    return mapByClass
  }

  static _getObjectDetailFeedbackCount (
    dfc: Map<string, number>
  ): Record<string, number> {
    return {
      negativeTags: dfc.get(FeedbackCharacter.Negative) || 0,
      neutralTags: dfc.get(FeedbackCharacter.Neutral) || 0,
      positiveTags: dfc.get(FeedbackCharacter.Positive) || 0
    }
  }

  static countTagInAll (mapClasses: Map<string, number>): number {
    if (Array.from(mapClasses.values()).length !== 0) {
      return Array.from(mapClasses.values()).reduce(
        (accumulator, currentValue) => accumulator + currentValue
      )
    } else {
      return 0
    }
  }

  static getTagCountByClass (
    tagText: string,
    summaries: ClassFeedbackSummary[],
    feedbackCharacter: FeedbackCharacter,
    id?: string
  ): Map<string, number> {
    const mapByClass: Map<string, number> = new Map()
    for (const summary of summaries) {
      const checkInSummary =
        id && feedbackCharacter === FeedbackCharacter.Positive
          ? summary.personSummary
            .get(id)
            ?.tagsFeedbacks?.numberTags.get(FeedbackCharacter.Positive)
            ?.get(tagText)
          : id && feedbackCharacter === FeedbackCharacter.Negative
            ? (summary.personSummary
                .get(id)
                ?.tagsFeedbacks?.numberTags.get(FeedbackCharacter.Negative)
                ?.get(tagText) || 0) +
            (summary.personSummary
              .get(id)
              ?.tagsFeedbacks?.numberTags.get(FeedbackCharacter.Neutral)
              ?.get(tagText) || 0)
            : !id && feedbackCharacter === FeedbackCharacter.Positive
                ? summary.contentSummary?.tagsFeedbacks?.numberTags
                  .get(FeedbackCharacter.Positive)
                  ?.get(tagText)
                : (summary.contentSummary?.tagsFeedbacks?.numberTags
                    .get(FeedbackCharacter.Negative)
                    ?.get(tagText) || 0) +
            (summary.contentSummary?.tagsFeedbacks?.numberTags
              .get(FeedbackCharacter.Neutral)
              ?.get(tagText) || 0)
      if (checkInSummary) {
        mapByClass.set(summary._id, checkInSummary)
      }
    }
    return mapByClass
  }

  static getStaffNamesAndAverage (summaries: ClassFeedbackSummary[], role: PersonRoles): IStaffNameAndAverageRating[] {
    const staffWithRating = []
    summaries = summaries.filter((summary) => {
      return summary.npsSummary === null || summary.npsSummary === undefined
    }).sort((prev, value) => prev.classNumber - value.classNumber)
    const summary = summaries.slice(-1)[0]
    if (summary) {
      for (const person of summary.personSummary.values()) {
        staffWithRating.push({
          id: person._id,
          name: person.name,
          lastname: person.lastname,
          role: person.role,
          avgRating: person.rating?.value || 0
        })
      }
    }
    return staffWithRating.filter(s => s.role === role)
  }

  static parseToNPSClassSummaries (summaries: ClassFeedbackSummary[]): NPSClassSummary[] {
    const mapShortSummariesByClass: Map<string, INPSClassSummary> = new Map()
    summaries.map((summary) => mapShortSummariesByClass.set(summary.classId, new NPSClassSummary(summary)))
    return Array.from(mapShortSummariesByClass.values())
  }

  static filterByCountry (totalCourses: ClassFeedbackSummary[], selectedCountries?: Country[]): ClassFeedbackSummary[] {
    return !selectedCountries
      ? totalCourses
      : selectedCountries.length === 0
        ? totalCourses
        : totalCourses.filter(course => selectedCountries.some(country => country.code === course.country))
  }
}
