import { Api } from './api'
import { CRUD } from './crud'
import { StaffSquadDto } from '@/models/staff-squads/interfaces/StaffSquad.dto'

const baseURL = process.env.ACADEMIC_API_ENDPOINT
const ENDPOINT = `${baseURL}/v1/staff-squads`

class StaffSquadsApi implements CRUD<StaffSquadDto> {
  async findAll(): Promise<StaffSquadDto[]> {
    return await Api.get<StaffSquadDto[]>(ENDPOINT)
  }

  async findById(squadId: string): Promise<StaffSquadDto> {
    return await Api.get<StaffSquadDto>(`${ENDPOINT}/${squadId}`)
  }

  async upsert(squad: StaffSquadDto): Promise<StaffSquadDto> {
    return squad?._id ? this.update(squad) : this.create(squad)
  }

  async create(squad: StaffSquadDto): Promise<StaffSquadDto> {
    return await Api.post<StaffSquadDto>(ENDPOINT, squad)
  }

  async update(squad: StaffSquadDto): Promise<StaffSquadDto> {
    return await Api.put<StaffSquadDto>(`${ENDPOINT}/${squad._id}`, squad)
  }

  async remove(squadId: string): Promise<void> {
    return await Api.del<void>(`${ENDPOINT}/${squadId}`)
  }
}

export const StaffSquadsAPI = new StaffSquadsApi()
