export function toggleElementsFromArray<T> (element: T, array: T[]): T[] {
  if (!array) throw new Error('An array is required in toggleElementsFromArray')
  const set = new Set(array)
  set.has(element) ? set.delete(element) : set.add(element)
  return Array.from(set)
}

export function getGoogleDriveDirectDownloadLink (link: string): string {
  const id = link.split('/')[5]
  console.log(`https://drive.google.com/uc?export=download&id=${id}`)
  if (!id) {
    console.warn(`Could not get file ID from ${link}`)
    return link
  }
  return `https://drive.google.com/uc?id=${id}&export=download`
}

export function getDropboxDirectDownloadLink (link: string): string {
  const id = link.split('/')[4]
  if (!id) {
    console.warn(`Could not get file ID from ${link}`)
    return link
  }
  return `https://www.dropbox.com/s/${id}?dl=1`
}

export function getDirectDownloadLink (link: string): string {
  type Provider = 'dropbox' | 'drive'
  const providers: Provider[] = ['dropbox', 'drive']
  const provider = providers.find(p => link.includes(p))
  const converter: Record<Provider, (link: string) => string> = {
    drive: getGoogleDriveDirectDownloadLink,
    dropbox: getDropboxDirectDownloadLink
  }
  if (!converter || !provider) {
    console.warn(`Could not convert "${link}" to direct download link`)
    return link
  }
  return converter[provider](link)
}
