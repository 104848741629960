import { App } from '@vue/runtime-core'

import Avatar from '@/components/avatar/Avatar' // Use 'primevue/avatar' when fixed
import ChTag from '@/components/commons/boxes/ChTag.vue'
import LoadingScreen from '@/components/commons/LoadingScreen.vue'
import ModuleHeader from '@/components/commons/boxes/ModuleHeader.vue' // Use 'primevue/avatar' when fixed
import BrainSpinner from '@/components/commons/BrainSpinner.vue' // Use 'primevue/avatar' when fixed
import Center from '@/components/commons/boxes/Center.vue' // Use 'primevue/avatar' when fixed
import CoderBadge from '@/components/commons/badges/CoderBadge.vue'
import Button from 'primevue/button'
import Card from 'primevue/card'
import Tag from 'primevue/tag'
import DataView from 'primevue/dataview'
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions'
import Badge from 'primevue/badge'
import Dropdown from 'primevue/dropdown'
import Paginator from 'primevue/paginator'
import MultiSelect from 'primevue/multiselect'
import InputText from 'primevue/inputtext'
import Message from 'primevue/message'
import Panel from 'primevue/panel'
import TabPanel from 'primevue/tabpanel'
import TabView from 'primevue/tabview'
import TabMenu from 'primevue/tabmenu'
import Checkbox from 'primevue/checkbox'
import Dialog from 'primevue/dialog'
import Toolbar from 'primevue/toolbar'
import Textarea from 'primevue/textarea'
import RadioButton from 'primevue/radiobutton'
import Column from 'primevue/column'
import ColumnGroup from 'primevue/columngroup'
import Row from 'primevue/row'
import DataTable from 'primevue/datatable'
import ToggleButton from 'primevue/togglebutton'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import { Chart } from 'chart.js'
import Divider from 'primevue/divider'
import Calendar from 'primevue/calendar'
import InputNumber from 'primevue/inputnumber'
import TieredMenu from 'primevue/tieredmenu'
import Menu from 'primevue/menu'
import ConfirmDialog from 'primevue/confirmdialog'
import TrueOrFalseSelector from '@/components/commons/selectors/TrueOrFalseSelector.vue'
import DaysOfWeekSelector from '@/components/commons/selectors/DaysOfWeekSelector.vue'
/**
 * Register componentes used in all the application
 * @param app the application to register components to
 */
export function registerCommonComponents (app: App): void {
  app.component('Avatar', Avatar)
  app.component('Button', Button)
  app.component('Badge', Badge)
  app.component('ChTag', ChTag)
  app.component('Card', Card)
  app.component('Tag', Tag)
  app.component('Divider', Divider)
  app.component('DataView', DataView)
  app.component('Dropdown', Dropdown)
  app.component('Paginator', Paginator)
  app.component('MultiSelect', MultiSelect)
  app.component('DataViewLayoutOptions', DataViewLayoutOptions)
  app.component('InputText', InputText)
  app.component('Message', Message)
  app.component('TabPanel', TabPanel)
  app.component('TabMenu', TabMenu)
  app.component('Panel', Panel)
  app.component('TabView', TabView)
  app.component('Toolbar', Toolbar)
  app.component('ToggleButton', ToggleButton)
  app.component('Chart', Chart)
  app.component('DataTable', DataTable)
  app.component('Accordion', Accordion)
  app.component('AccordionTab', AccordionTab)
  app.component('Column', Column)
  app.component('ColumnGroup', ColumnGroup)
  app.component('Row', Row)
  app.component('ModuleHeader', ModuleHeader)
  app.component('Center', Center)
  app.component('BrainSpinner', BrainSpinner)
  app.component('LoadingScreen', LoadingScreen)
  app.component('Textarea', Textarea)
  app.component('Dialog', Dialog)
  app.component('Checkbox', Checkbox)
  app.component('RadioButton', RadioButton)
  app.component('Calendar', Calendar)
  app.component('InputNumber', InputNumber)
  app.component('TieredMenu', TieredMenu)
  app.component('Menu', Menu)
  app.component('ConfirmDialog', ConfirmDialog)
  app.component('CoderBadge', CoderBadge)
  app.component('TrueOrFalseSelector', TrueOrFalseSelector)
  app.component('DaysOfWeekSelector', DaysOfWeekSelector)
}
