import { MapPerson } from '@/models/person/classes/MapPerson'
import { PersonRoles } from '@/models/person/enums'
import { CourseFeedbackByPerson } from '../interfaces/CourseFeedBackByPerson'
import { ICourseFeedback } from '../interfaces/dto/CourseFeedback'
import { FeedbackEntry } from '../interfaces/dto/FeedbackEntry'
import { FeedbackOriginalFormData } from '../interfaces/dto/FeedbackOriginalFormData'
import { FeedbackSchedule } from '../interfaces/dto/FeedbackSchedule'
import { PersonFeedbackEntry } from '../interfaces/dto/PersonFeedbackEntry'
import { IPersonFeedbacks } from '../interfaces/IPersonFeedbacks'
import { EntriesFeedbacks } from './EntriesFeedbacks'

export class CourseFeedback {
  personId: string
  role: PersonRoles
  courseId: string
  status: string
  v1CourseId: string
  schedule: FeedbackSchedule
  entries: Array<PersonFeedbackEntry>
  contentFeedback: FeedbackEntry
  npsFeedback?: FeedbackEntry
  original: FeedbackOriginalFormData
  comment: string[]

  constructor (from: ICourseFeedback) {
    this.personId = from.personId
    this.role = from.role
    this.courseId = from.courseId
    this.status = from.status
    this.v1CourseId = from.v1CourseId
    this.schedule = from.schedule
    this.entries = from.entries
    this.contentFeedback = from.contentFeedback
    this.npsFeedback = from.npsFeedback
    this.original = from.original
    this.comment = from.comment
  }

  static async parseToFeedbackByPerson (
    courseFeedbacks: Array<CourseFeedback>
  ): Promise<CourseFeedbackByPerson> {
    const mapper: CourseFeedbackByPerson = new Map()
    const mapperPerson: MapPerson = new MapPerson()
    await mapperPerson.setPerson(courseFeedbacks.map(c => c.personId))

    for (const feedback of courseFeedbacks) {
      const personInMap = mapper.get(feedback.personId)
      const classId: string = feedback.schedule.id
      const entryFeedback: EntriesFeedbacks = new EntriesFeedbacks(feedback)
      const person = await mapperPerson.getPerson(feedback.personId)
      if (personInMap) {
        personInMap.feedbacks.set(classId, entryFeedback)
        personInMap.person = person
        mapper.set(feedback.personId, personInMap)
      } else {
        const classMapper: Map<string, EntriesFeedbacks> = new Map([
          [classId, entryFeedback]
        ])
        mapper.set(feedback.personId, <IPersonFeedbacks>(
          (<unknown>{ person, feedbacks: classMapper })
        ))
      }
    }
    return mapper
  }

  static getStaffFromFeedbacks (fbp: CourseFeedbackByPerson): string[] {
    const teachersMentionedInFeedbacks: string[] = []
    for (const [id, personAndFeedbacks] of fbp) {
      for (const feedback of personAndFeedbacks.feedbacks.values()) {
        if (feedback.entries) {
          feedback.entries.map(e => teachersMentionedInFeedbacks.push(e.personId))
        }
      }
    }
    return Array.from(new Set(teachersMentionedInFeedbacks))
  }
}
