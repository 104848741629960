import { FormatDates } from '@/models/enums'
import { DateTime } from 'luxon'

export function formatDate (date: string | Date, format: FormatDates = FormatDates.LatinAmericaShort): string {
  if (typeof date === 'string') {
    return DateTime.fromISO(date).toFormat(format)
  } else {
    return DateTime.fromJSDate(date).toFormat(format)
  }
}
