import { Country } from '@/models/countries/classes/Country'
import { ICountry } from '@/models/countries/interfaces/Country'
import { CountryRes } from '@/models/jobBank/interfaces/locations/locations'
import { Api } from './api'
import { CRUD } from './crud'

class CountryApi implements CRUD<Country> {
  private readonly baseURL: string

  constructor() {
    this.baseURL = `${process.env.API_GATEWAY}/translation/api`
  }

  public async findAll(): Promise<Country[]> {
    const data = await Api.get<ICountry[]>(`${this.baseURL}/v1/countries`)
    return data.map(
      (d) =>
        new Country(
          d.id,
          d.code,
          d.currency,
          d.enable,
          d.name,
          d.timezone,
          d.updatedAt
        )
    )
  }
}
class JobBankApi {
  private readonly baseURL: string

  constructor() {
    this.baseURL = process.env.TALENT_API_ENDPOINT
  }

  public async findAll(): Promise<CountryRes> {
    return Api.get(`${this.baseURL}/countries`)
  }
}

export const CountryAPI = new CountryApi()
export const JobBankCountriesAPI = new JobBankApi()
