import { RouteRecordRaw } from 'vue-router'
import Structure from '@/views/recruitment/people/Structure.vue'
import StaffSquads from '@/views/recruitment/staff-squads/StaffSquads.vue'
import StaffSquad from '@/views/recruitment/staff-squads/StaffSquad.vue'
import StaffList from '@/views/recruitment/staff-list/StaffList.vue'
import { RouteNames, StaffRouteNames } from '@/router/route-names'
import TabTeachers from '@/views/recruitment/staff-list/tabs/Teachers.tab.vue'
import TabTutors from '@/views/recruitment/staff-list/tabs/Tutors.tab.vue'
import TabCoordinators from '@/views/recruitment/staff-list/tabs/Coordinators.tab.vue'
import Contracts from '@/views/recruitment/contracts/Contracts.vue'
import ContractReports from '@/views/recruitment/contracts/tabs/reports/ContractReports.vue'
import NewEnrollmentContracts from '@/views/recruitment/contracts/tabs/reports/tabs/NewEnrollmentContracts.vue'
import CommissionContracts from '@/views/recruitment/contracts/tabs/reports/tabs/CommissionContracts.vue'
import ContractTypes from '@/views/recruitment/contracts/tabs/reports/tabs/ContractTypes.vue'

const RecruitmentRoutes: Array<RouteRecordRaw> = [
  {
    path: 'structure',
    name: 'Structure',
    component: Structure
  },
  {
    path: 'squads',
    name: RouteNames.STAFF_SQUADS,
    component: StaffSquads
  },
  {
    path: 'squads/:squadId',
    name: RouteNames.STAFF_SQUAD,
    component: StaffSquad
  },
  {
    path: 'list',
    name: RouteNames.STAFF_LIST,
    component: StaffList,
    redirect: () => ({ name: StaffRouteNames.TEACHERS }),
    children: [
      {
        path: 'profesores',
        name: StaffRouteNames.TEACHERS,
        component: TabTeachers
      },
      {
        path: 'tutores',
        name: StaffRouteNames.TUTORS,
        component: TabTutors
      },
      {
        path: 'coordinadores',
        name: StaffRouteNames.COORDINATORS,
        component: TabCoordinators
      }
    ]
  },
  {
    path: 'contracts',
    name: 'Contracts',
    component: Contracts,
    children: [
      {
        path: 'types',
        component: ContractTypes,
        name: 'ContractTypes'
      },
      {
        path: 'reports',
        name: 'ContractReports',
        component: ContractReports,
        children: [
          {
            path: 'annual',
            name: 'NewEnrollmentContracts',
            component: NewEnrollmentContracts
          },
          {
            path: 'commission',
            name: 'CommissionContracts',
            component: CommissionContracts
          },
          {
            path: '',
            redirect: {
              name: 'NewEnrollmentContracts'
            }
          }
        ]
      },
      {
        path: '',
        redirect: {
          name: 'ContractTypes'
        }
      }
    ]
  },
  {
    path: '',
    redirect: { name: 'Structure' }
  }
]

export default RecruitmentRoutes
