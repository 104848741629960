
import { defineComponent } from 'vue'
import ProgressSpinner from 'primevue/progressspinner'
import { CSSColors } from '@/models/enums'
export default defineComponent({
  components: {
    ProgressSpinner
  },
  setup () {
    return { CSSColors }
  }
})
