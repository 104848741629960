
import { defineComponent, PropType } from 'vue'
import { RouteLocationRaw } from 'vue-router'

export default defineComponent({
  props: {
    showGoBack: {
      type: Boolean,
      default: true
    },
    backTo: {
      type: Object as PropType<RouteLocationRaw>
    },
    title: {
      type: String
    }
    // backToComponentName: {
    //   type: RouterLink
    // }
  }
})
