import { StaffRouteNames } from '@/router/route-names'
import { MenuItem } from 'primevue/menuitem'

export function useCourseMenuItems (): MenuItem[] {
  return [
    {
      label: 'Profesores',
      to: {
        replace: true,
        name: StaffRouteNames.TEACHERS
      }
    },
    {
      label: 'Tutores',
      to: {
        replace: true,
        name: StaffRouteNames.TUTORS
      }
    },
    {
      label: 'Coordinadores',
      to: {
        replace: true,
        name: StaffRouteNames.COORDINATORS
      }
    }
  ]
}
