import { Api } from './api'
import { DataRecord } from '@/models/activity-record/ActivityRecord'

class ActivityRecordApi {
  private readonly baseURL: string

  constructor() {
    this.baseURL = process.env.ACADEMIC_API_ENDPOINT
  }

  public async activityRecord(dataRecord: DataRecord): Promise<void> {
    await Api.post<void>(`${this.baseURL}/v1/activity-record`, dataRecord)
  }
}

export const ActivityRecordAPI = new ActivityRecordApi()
