import { CoursePlan } from '@/models/academic-definitions/classes/CoursePlan'
import { ICoursePlan } from '@/models/academic-definitions/interfaces/CoursePlan'
import { Api } from '../api'
import { CRUD } from '../crud'

class CoursePlanApi implements CRUD<CoursePlan> {
  private readonly baseURL: string

  constructor() {
    this.baseURL = process.env.ACADEMIC_API_ENDPOINT
  }

  private convertDtoToCoursePlan(dto: ICoursePlan): CoursePlan {
    return new CoursePlan(
      dto.id,
      dto.v1Id,
      dto.countries,
      dto.information,
      dto.links,
      dto.published,
      dto.display,
      dto.config,
      dto.createdAt,
      dto.updatedAt,
      dto.__v
    )
  }

  public async findById(id: string): Promise<CoursePlan> {
    const data = await Api.get<ICoursePlan>(
      `${this.baseURL}/v1/academic-definitions/coursePlan/${id}`
    )
    return this.convertDtoToCoursePlan(data)
  }

  public async findAll(params = {}): Promise<CoursePlan[]> {
    const data = await Api.get<ICoursePlan[]>(
      `${this.baseURL}/v1/academic-definitions/coursePlan`,
      params
    )
    return data.map((dto) => this.convertDtoToCoursePlan(dto))
  }

  public async findAllFollowing(
    query: Record<string, string | boolean | number | Date | string[]>
  ): Promise<CoursePlan[]> {
    const data = await Api.get<ICoursePlan[]>(
      `${this.baseURL}/v1/academic-definitions/coursePlan`,
      query
    )
    return data.map((dto) => this.convertDtoToCoursePlan(dto))
  }

  public async create(coursePlan: Partial<CoursePlan>): Promise<CoursePlan> {
    return await Api.post(
      `${this.baseURL}/v1/academic-definitions/coursePlan/create`,
      coursePlan
    )
  }

  public async update(
    coursePlan: Partial<CoursePlan>,
    id: string
  ): Promise<CoursePlan> {
    return await Api.patch(
      `${this.baseURL}/v1/academic-definitions/coursePlan/${id}`,
      coursePlan
    )
  }
}

export const CoursePlanAPI = new CoursePlanApi()
