export enum FormatDates {
  LatinAmerica = 'dd/MM/yy',
  LatinAmericaShort = 'dd/MM'
}

export enum CSSColors {
  White = '#FFFFFF',
  GreenBrain = '#A3C883',
  RedBrain = '#F88383',
  YellowBrain = '#FBE08F',
  PurpleBrain = '#5140AA',
  PrimaryColor = '#2C323C',
  LightblueBrain = '#37B1CC',
  FreeLightBlue = '#74D1DF99',
  Gray400 = '#F3F3F3',
  Gray700 = '#D1D1D1',
  LightRedBrain = '#F1B9E8',
  Black='#000000'
}

export enum QueryFormat {
  Minimal = 'Minimal',
  Middle = 'Middle',
  Small = 'Small',
  Full = 'Full'
}

export enum TypeStudents {
  Payment = 'PAYMENT',
  Free = 'FREE',
  Inactive = 'INACTIVE',
  Unenroll = 'UNENROLL',
  Reenroll = 'REENROLL',
  Pending = 'PENDING',
  Leads = 'LEADS',
  Removed = 'REMOVED',
  Warning = 'WARNING',
  Alert = 'ALERT'
}

export enum StudentTypeAlert {
  WarnDelivers = 'warn-delivers',
  AlertDelivers = 'alert-delivers',
  AlertAttendance = 'alert-attendance',
  WarnAttendance = 'warn-attendance'
}

export enum TitleAlign {
  Start = 'start',
  Center = 'center',
  End = 'end'
}

export enum DoughnutChartLabelType {
  Progress = 'progress',
  Table = 'table'
}

export enum DayOfTheWeek {
  Sunday = 'Domingo',
  Monday = 'Lunes',
  Tuesday = 'Martes',
  Wednesday = 'Miércoles',
  Thursday = 'Jueves',
  Friday = 'Viernes',
  Saturday = 'Sábado'
}

export enum CoursePerformanceColumnsWidth {
  CourseNumber = '10%', // 10
  CourseName = '13%', // 23
  ClassNumber = '6%', // 29
  CourseDate = '8%', // 45 (*2 dates...)
  TeacherRating = '15%', // 60
  TutorRating = '15%', // 75
  ContentRating = '10%', // 85
  CoordinatorRating = '15%', // 100
  NPSRating = '15%',
  CourseNameFinished = '25%',
  CourseDateFinished = '15%'
}

export enum CourseType {
  B2b = 'b2b',
  B2c = 'b2c',
  Workshop = 'workshop',
  Intensive = 'intensive'
}

export enum CountriesISO {
  AR = 'ar',
  ES = 'es',
  MX = 'mx',
  BR = 'br',
  CL = 'cl',
  CO = 'co',
  UY = 'uy',
  US = 'us'
}

export enum Forms {
  new = 'nuevo'
}

export enum TypeMembers {
  TUTOR = 'TUTOR', // assistantTeachers, assistantSubstituteTeachers
  TUTOR_SYNCHRONOUS = 'TUTOR_SYNCHRONOUS', // syncAssistants // tutor sincronico
  TUTOR_REVIEWER = 'TUTOR_REVIEWER', // reviewerAssistants// tutor corrector, ver si es el mejor nombre
  TUTOR_CODER_ASK = 'TUTOR_CODER_ASK', // coderAskAssistants,
  TEACHER = 'TEACHER', // teachers, replacementTeachers, reviewerTeachers
  COORDINATOR = 'COORDINATOR', // coordinatorTeachers
  STUDENTS = 'STUDENTS',
  SUBTITUTE_TEACHERS = 'SUBTITUTE_TEACHERS', // substituteTeachers
  SPECIALIST_TEACHERS = 'SPECIALIST_TEACHERS', // specialistTeachers,
  UNENROLL = 'UNENROLL'
}

export enum TypeUnenrollments {
  resignation = 'Renuncia al Staff',
  fired = 'Despido',
  unenroll = 'Baja voluntaria de la comision',
  performanceUnenroll = 'Baja de la comision por performance',
  courseChange = 'Cambio de comision',
  humanMistake = 'Error humano'
}

export enum ResignationMotives {
  revenue = 'Remuneración',
  agreement = 'Contrato (Monotributo)',
  dedication = 'Declara exceso de trabajo en tutoría'
}

export enum UnenrollMotives {
  revenue = 'Remuneración',
  agreement = 'Contrato (Monotributo)',
  dedication = 'Declara exceso de trabajo en tutoría'
}

export enum FiredMotives {
  lowRating = 'Mal rate',
  answersOffTime = 'Respuestas fuera de término',
  reviewsOffTime = 'Devoluciones fuera de tiempo',
  lackOfReviews = 'Falta de claridad en correcciones o respuestas',
  lackOfParticipation = 'Falta de participación en clase',
  lackOfSupport = 'Falta de soporte a estudiantes'
}

export enum PerformanceMotives {
  lowRating = 'Mal rate',
  answersOffTime = 'Respuestas fuera de término',
  reviewsOffTime = 'Devoluciones fuera de tiempo',
  lackOfReviews = 'Falta de claridad en correcciones o respuestas',
  lackOfParticipation = 'Falta de participación en clase',
  lackOfSupport = 'Falta de soporte a estudiantes'
}

export enum CourseChangeMotives {
  personal = 'Personales (facultad, vacaciones, enfermedad)',
  newWork = 'Nuevo trabajo',
  workOverlap = 'Superposición con horarios de otro trabajo',
  unknow = 'No especifica',
  other = 'Otro (escribir cual)'
}

export enum HumanMistakeMotives {
  workOverlap = 'Superposición con horarios de otro trabajo',
  unknow = 'No especifica',
  other = 'Otro (escribir cual)'
}
