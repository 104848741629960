import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-d-flex p-ai-center country-item" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiSelect = _resolveComponent("MultiSelect")!

  return (_openBlock(), _createBlock(_component_MultiSelect, {
    panelClass: "overlay-gray",
    showToggleAll: false,
    disabled: _ctx.disabled,
    options: _ctx.countries,
    optionLabel: "name",
    placeholder: "Seleccionar país",
    display: "chip"
  }, {
    option: _withCtx(({ option }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          src: _ctx.Country.getIconUrlFromCountryCode(option.code),
          class: "p-mr-3",
          width: "18"
        }, null, 8, _hoisted_2),
        _createElementVNode("div", null, _toDisplayString(option.name), 1)
      ])
    ]),
    _: 1
  }, 8, ["disabled", "options"]))
}