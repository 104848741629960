import { IContext } from '@/models/translations/TranslationContext'
import { TranslationEntry } from '@/models/translations/TranslationEntry.model'
import { TranslationLanguage } from '@/models/translations/TranslationLanguage.model'
import { Api } from './api'

const baseURL = `${process.env.API_GATEWAY}/translation/api`

async function getTranslationsLanguages(): Promise<TranslationLanguage[]> {
  return Api.get<TranslationLanguage[]>(`${baseURL}/v1/translation-languages`)
}

async function getTranslationsEntries(
  context: string
): Promise<TranslationEntry[]> {
  return (
    await Api.get<TranslationEntry[]>(
      `${baseURL}/v1/translation-entries/context/${context}`
    )
  ).map(fixMissingData)
}

async function getTranslationsEntriesByContextAndKey(
  context: string,
  key: string
): Promise<TranslationEntry[]> {
  return (
    await Api.get<TranslationEntry[]>(
      `${baseURL}/v1/translation-entries/find/by-context-and-key?contextId=${context}&templateKey=${key}`
    )
  ).map(fixMissingData)
}

async function getTranslationsEntry(
  id: string
): Promise<TranslationEntry | undefined> {
  const entry = await Api.get<TranslationEntry | undefined>(
    `${baseURL}/v1/translation-entries/${id}`
  )
  return entry && fixMissingData(entry)
}

async function addTranslationEntry(
  context: string,
  key: string
): Promise<TranslationEntry> {
  return Api.post(`${baseURL}/v1/translation-entries`, { context, key })
}

async function updateTranslationDraft(
  entry: TranslationEntry
): Promise<TranslationEntry> {
  const entry2 = await Api.put<TranslationEntry>(
    `${baseURL}/v1/translation-entries/${entry.id}/draft`,
    entry
  )
  return fixMissingData(entry2)
}

async function updateTranslationKey(
  entry: TranslationEntry,
  key: string
): Promise<TranslationEntry> {
  const entry2 = await Api.put<TranslationEntry>(
    `${baseURL}/v1/translation-entries/${entry.id}/update-key`,
    { key }
  )
  return fixMissingData(entry2)
}

async function deleteTranslationEntry(
  entry: TranslationEntry
): Promise<TranslationEntry> {
  await Api.del<TranslationEntry>(
    `${baseURL}/v1/translation-entries/${entry.id}`
  )
  return fixMissingData(entry)
}

async function publishTranslationEntry(
  entry: TranslationEntry
): Promise<TranslationEntry> {
  const entry2 = await Api.put<TranslationEntry>(
    `${baseURL}/v1/translation-entries/${entry.id}/publish`,
    {}
  )
  return fixMissingData(entry2)
}

async function getTranslationsContexts(): Promise<IContext[]> {
  return await Api.get<IContext[]>(`${baseURL}/v1/translations/context`)
}

async function saveTranslationsContext(
  context: Partial<IContext>
): Promise<IContext> {
  return await Api.post<IContext>(`${baseURL}/v1/translations/context`, context)
}

/**
 * This is only for development because it lost the `draft` field while doing tests.
 * To allow doing debugging, the field was completed here.
 * This can be removed after the defect were fixed
 * @param {TranslationEntry} entry an entry to fix
 * @return {TranslationEntry} the same entry if it has the draft field, or a new completed one
 */
function fixMissingData(entry: TranslationEntry): TranslationEntry {
  return entry.draft ? entry : { ...entry, draft: {} }
}

async function clearTranslationsCache(): Promise<void> {
  await Api.del<void>(`${baseURL}/v1/translations/cache`)
}

export {
  addTranslationEntry,
  getTranslationsLanguages,
  getTranslationsEntriesByContextAndKey,
  getTranslationsEntry,
  getTranslationsEntries,
  getTranslationsContexts,
  saveTranslationsContext,
  updateTranslationDraft,
  publishTranslationEntry,
  updateTranslationKey,
  deleteTranslationEntry,
  clearTranslationsCache
}
