import { Directive } from '@vue/runtime-core'

const ParentStyle: Directive = {
  mounted (el, binding) {
    const bvalue = binding.value
    for (const key in bvalue.style) {
      el.style[key] = bvalue.style[key]
    }
  },
  updated (el, binding) {
    const bvalue = binding.value
    for (const key in bvalue.style) {
      el.parentElement.style[key] = bvalue.style[key]
    }
  }
}

export default ParentStyle
