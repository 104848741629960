export enum ConfigurationTag {
  CanAssistantsReviewPreIntegratorTp = 'canAssistantsReviewPreIntegratorTp',
  CanAssistantsReviewIntegratorTp = 'canAssistantsReviewIntegratorTp',
  Corporate = 'corporate',
  Display = 'display',
  ForceDailyFeedbacks = 'forceDailyFeedbacks',
  Free = 'free',
  HasPostulationsLocked = 'hasPostulationsLocked',
  HasPreIntegratorTpWithSoftRewiew = 'hasPreIntegratorTpWithSoftRewiew',
  HasTeachersPostulationsLocked = 'hasTeachersPostulationsLocked',
  NeedCoordinator = 'needCoordinator',
  Onboarding = 'onboarding',
  Published = 'published',
  Recorded = 'recorded',
  Remote = 'remote',
  RemoteRecorded = 'remoteRecorded',
  SoldOut = 'soldOut',
  UseStreamChat = 'useStreamChat',
  Workshop = 'workshop',
  Intensive = 'intensive',
  HasTutoringGroups= 'hasTutoringGroups'
}

export enum CourseHours {
  Start = 'start',
  End = 'end'
}

export enum StudentCourseModality {
  Beca='BECA',
  Pro='PRO'
}

export enum MaxStudentsByStaff {
  Tutors=25,
}

export enum LinkType {
  Presentation = 'presentation',
  LiveVideo = 'liveVideo',
  RecordingVideo = 'recordingVideo',
  Slack = 'slack',
  TeachersSharedFolder = 'teachersSharedFolder',
  SharedFolder = 'sharedFolder',
}

export enum StudentUnenrollmentReason {
  Personal = 'PERSONAL',
  Change = 'CHANGE',
  Teacher = 'TEACHER',
  MaterialStudy = 'MATERIAL_STUDY',
  Methodology = 'METHODOLOGY',
  NotMatchRequriments = 'NOT_MATCH_REQURIMENTS',
  Reenroll = 'REENROLL',
  AmountInFavor = 'AMOUNTINFAVOR',
  Refund = 'REFUND',
  RefundByQuality = 'REFUNDBYQUALITY',
  Regret = 'REGRET',
  Other = 'OTHER',
}

export enum StaffUnenrollmentReason {
  personal = 'PERSONAL', // 'Personales (facultad, vacaciones, enfermedad)',
  newWork = 'NEWWORK', // 'Nuevo trabajo',
  workOverlap = 'WORKOVERLAP', // 'Superposición con horarios de otro trabajo',
  courseIssues = 'COURSEISSUES', // 'Problemas en la camada',
  courseOverlap = 'COURSEOVERLAP', // 'Se superpone con otra camada',
  lowPerformance = 'LOWPERFORMANCE', // 'Baja performance',
  // Motivos de renuncia al Staff:
  revenue = 'REVENUE', // 'Remuneración',
  agreement = 'AGREEMENT', // 'Contrato (Monotributo)',
  dedication = 'DEDICATION', // 'Declara exceso de trabajo en tutoría',
  // Motivos de despido:
  lowRating = 'LOWRATING', // 'Mal rate',
  answersOffTime = 'ANSWERSOFFTIME', // 'Respuestas fuera de término',
  reviewsOffTime = 'REVIEWSOFFTIME', // 'Devoluciones fuera de tiempo',
  lackOfReviews = 'LACKOFREVIEWS', // 'Falta de claridad en correcciones o respuestas',
  lackOfParticipation = 'LACKOFPARTICIPATION', // 'Falta de participación en clase',
  lackOfSupport = 'LACKOFSUPPORT', // 'Falta de soporte a estudiantes',
  unknow = 'UNKNOW', // 'No especifica',
  other = 'OTHER', // 'Otro (escribir cual)'
}

export enum StaffUnenrollmentType {
  Unenroll = 'UNENROLL',
  Resignation = 'RESIGNATION',
  Fired = 'FIRED',
  PerformanceUnenroll = 'PERFORMANCEUNENROLL',
  CourseChange = 'COURSECHANGE',
  HumanMistake = 'HUMANMISTAKE',
}

export enum CourseStatus {
  InProgress = 'inProgress',
  Finished = 'finished',
  NotStarted = 'notStarted',
  Unknown = 'unknown',
  Cancelled = 'cancelled',
}

export enum LabelLinks {
  slack = 'Slack',
  sharedFolderLink = 'Carpeta Compartida',
  teachersSharedFolderLink = 'Carpeta Docente'
}

export enum CommentTarget {
  Person = 'person',
  Course = 'course'
}
