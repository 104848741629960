import {
  CustomFilter,
  CustomFilterByType
} from '@/models/custom-filters/classes/CustomFilter'
import { TableName } from '@/models/custom-filters/enum'
import { ICustomFilter } from '@/models/custom-filters/interfaces/ICustomFilter'
import { Api } from './api'
import { CRUD } from './crud'

class CustomFilterApi implements CRUD<CustomFilter> {
  private readonly baseURL: string

  constructor() {
    this.baseURL = process.env.ACADEMIC_API_ENDPOINT
  }

  private convertDTOtoCustomFilter(dto: ICustomFilter): CustomFilter {
    return new CustomFilter(
      dto.filterProps,
      dto.userId,
      dto.typeFilter,
      dto.tableName,
      dto.name,
      dto.deleted,
      dto._id,
      dto.__v,
      dto.updatedAt,
      dto.createdAt
    )
  }

  async findAllByTableName(
    userId: string,
    name: TableName
  ): Promise<CustomFilterByType[]> {
    const filters = await Api.get<CustomFilterByType[]>(
      `${this.baseURL}/v1/customFilters/${userId}/${name}`
    )
    return filters
  }

  async findAllByUserId(id: string): Promise<Map<TableName, CustomFilter[]>> {
    const data = await Api.get<Record<TableName, ICustomFilter[]>>(
      `${this.baseURL}/v1/customFilters/${id}`
    )
    const keyValues = Object.entries(data)
    const mapper = new Map<TableName, CustomFilter[]>()
    for (const keyValueArray of keyValues) {
      const customFilters = keyValueArray[1].map((customFilter) =>
        this.convertDTOtoCustomFilter(customFilter)
      )
      mapper.set(keyValueArray[0] as TableName, customFilters)
    }
    return mapper
  }

  async upsert(f: CustomFilter): Promise<CustomFilter> {
    return await Api.put(`${this.baseURL}/v1/customFilters`, f)
  }

  async delete(id: string): Promise<CustomFilter> {
    return await Api.del(`${this.baseURL}/v1/customFilters/${id}`)
  }
}

export const CustomFiltersAPI = new CustomFilterApi()
