import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-268fca41")
const _hoisted_1 = { class: "p-d-flex p-jc-center p-ai-center h100 position-relative" }
const _hoisted_2 = ["src"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ProgressSpinner),
    _createElementVNode("img", {
      src: require('@/assets/icons/coderhouse/icon-96.png'),
      alt: "coderhouse-logo"
    }, null, 8, _hoisted_2)
  ]))
}