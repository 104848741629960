
import { MenuItem } from 'primevue/menuitem'
import { defineComponent } from 'vue'
export default defineComponent({
  setup () {
    return {
      menuItems: [
        {
          label: 'Anuales',
          to: {
            name: 'NewEnrollmentContracts'
          }
        },
        {
          label: 'Comisiones',
          to: {
            name: 'CommissionContracts'
          }
        }
      ] as MenuItem[]
    }
  }
})
