import { ContractStatus } from './contract-status.enum'

export interface ICommissionAssignmentContract {
  commissionNumber: number
  courseName: string
  firstName: string
  id: string
  group: string
  lastName: string
  role: string
  startsIn: string
  status: ContractStatus
}

export class CommissionAssignmentContract implements ICommissionAssignmentContract {
  constructor (
    public commissionNumber: number,
    public courseName: string,
    public firstName: string,
    public id: string,
    public lastName: string,
    public role: string,
    public startsIn: string,
    public status: ContractStatus,
    public group: string
  ) {}
}
