export function _objectToMapper<V> (obj: Record<string, V>): Map<string, V> {
  return new Map<string, V>(
    Object.keys(obj).map<[string, V]>((key: string) => [key, obj[key]])
  )
}

export function _concatMaps<V> (
  map1: Map<string, V>,
  map2: Map<string, V>
): Map<string, V> {
  return new Map([...Array.from(map1.entries()), ...Array.from(map2.entries())])
}
