export class SendingData {
  name?: string
  email: string
  constructor (
    name: string,
    email: string
  ) {
    this.name = name
    this.email = email
  }
}

export class EmailData {
  to: SendingData[]
  from: SendingData
  subject: string
  html: string
  constructor (
    to: SendingData[],
    from: SendingData,
    subject: string,
    html: string
  ) {
    this.to = to
    this.from = from
    this.subject = subject
    this.html = html
  }
}
