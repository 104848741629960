import { EnvironmentState } from '@/models/environmentState'
import { defineStore } from 'pinia'

export const useEnvironmentStore = defineStore({
  // unique id of the store across your application
  id: 'environment',
  state () {
    const environmentState: EnvironmentState = {
      environment: process.env.NODE_ENV || '',
      isOrgChartEditEnabled: process.env.ORGCHART_EDIT === 'enabled'
    }
    return environmentState
  },
  getters: {
    isProduction (): boolean {
      return this.environment === 'production'
    },
    orgChartEditEnabled (): boolean {
      return this.isOrgChartEditEnabled || true
    }
  },
  actions: {
    // Agregar las necesarias
  }
})
