export class SetMail {
  userId: string
  country: string
  toMail: string
  constructor (
  ) {
    this.userId = ''
    this.country = ''
    this.toMail = ''
  }
}
