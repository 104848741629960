import { ConfigurationTag, MaxStudentsByStaff } from '../enums'
import { IClassLink, ICountry, IProgram, IStudentCapacity, IZoomAccountData, IPowerBiTeachersAccountData, ITutoringGroups } from '../interfaces'
import { Class } from './Class'
import { Calendar } from './Calendar'
import { CourseTypeConvertion } from '../types'
import { DateTime, Interval } from 'luxon'
import { Tutoring } from './Tutoring'

export class Course {
  _id: string
  id: number
  v1CourseId: string
  enable: boolean
  country: ICountry
  status: string
  name: string
  statusChangedAt: string
  classesTaught: number
  classes: Class[]
  afterClasses: Class[]
  studentCapacity: IStudentCapacity
  program: IProgram
  zoom: IZoomAccountData
  powerBi: IPowerBiTeachersAccountData
  toolsLinks: IClassLink[]
  calendar: Calendar
  configTags: ConfigurationTag[]
  tutoring: Tutoring[]

  get configTagsSet (): Set<ConfigurationTag> {
    return new Set(this.configTags)
  }

  get typeTags (): ConfigurationTag[] {
    return this.configTags.filter(c => c === ConfigurationTag.Intensive || c === ConfigurationTag.Corporate || c === ConfigurationTag.Workshop)
  }

  get finished (): boolean {
    return new Date(this.calendar.end) < new Date()
  }

  get minTutorsRequired (): number {
    /* La cantidad de tutores depende de la capacidad de estudiantes de la comisión.
      Un tutor puede tener un máximo de estudiantes asignados.
      Este get calcula el mínimo de tutores que requerirá el curso
    */
    return Math.ceil(this.studentCapacity.max / MaxStudentsByStaff.Tutors)
  }

  get daysUntilStart (): number {
    const today = DateTime.now()
    const start = DateTime.fromISO(this.calendar.start)
    const diff = Interval.fromDateTimes(today, start)
    return Math.ceil(diff.length('days'))
  }

  constructor (
    _id: string,
    id: number,
    v1CourseId: string,
    enable: boolean,
    Icountry: ICountry,
    status: string,
    name: string,
    statusChangedAt: string,
    classesTaught: number,
    classes: Class[],
    afterClasses: Class[],
    studentCapacity: IStudentCapacity,
    program: IProgram,
    zoom: IZoomAccountData,
    powerBi: IPowerBiTeachersAccountData,
    toolsLinks: IClassLink[],
    calendar: Calendar,
    configTags: ConfigurationTag[],
    tutoring: Tutoring[]
  ) {
    this._id = _id
    this.id = id
    this.v1CourseId = v1CourseId
    this.enable = enable
    this.country = Icountry
    this.status = status
    this.name = name
    this.statusChangedAt = statusChangedAt
    this.classes = classes
    this.classesTaught = classesTaught
    this.afterClasses = afterClasses
    this.studentCapacity = studentCapacity
    this.program = program
    this.zoom = zoom
    this.powerBi = powerBi
    this.toolsLinks = toolsLinks
    this.calendar = calendar
    this.configTags = configTags
    this.tutoring = tutoring
  }

  static convertConfigurationTagToCourseTypeTag (tag: ConfigurationTag): CourseTypeConvertion | ConfigurationTag {
    switch (tag) {
      case ConfigurationTag.Intensive:
        return 'INT'
      case ConfigurationTag.Corporate:
        return 'B2B'
      case ConfigurationTag.Workshop:
        return 'W'
      default:
        return tag
    }
  }
}
