import {
  EnrollmentContract,
  IEnrollmentContract
} from '@/models/contracts/enrollment-contract.model'
import axios from 'axios'
import { Pagination } from '@/models/pagination'
import {
  CommissionAssignmentContract,
  ContractType,
  ContractTypeOption,
  GetContractTypeOptionsResponse,
  GetContractTypesResponse,
  ICommissionAssignmentContract,
  IContractType
} from '@/models/contracts'

export interface NewEnrollmentContractsResponse {
  contracts: IEnrollmentContract[]
  skip: number
  limit: number
  total: number
}
export interface CommissionAssignmentContractsResponse {
  contracts: ICommissionAssignmentContract[]
  skip: number
  limit: number
  total: number
}

const baseURL = process.env.BACKOFFICE_BFF

const contractsApi = axios.create({
  baseURL
})

export default class ContractsApi {
  static instanceEnrollmentContract(
    dto: IEnrollmentContract
  ): EnrollmentContract {
    return new EnrollmentContract(
      dto.firstName,
      dto.id,
      dto.lastName,
      dto.role,
      dto.group,
      dto.status,
      dto.createdAt,
      dto.expirationDate
    )
  }

  static instanceCommissionAssignmentContract(
    dto: ICommissionAssignmentContract
  ): CommissionAssignmentContract {
    return new CommissionAssignmentContract(
      dto.commissionNumber,
      dto.courseName,
      dto.firstName,
      dto.id,
      dto.lastName,
      dto.role,
      dto.startsIn,
      dto.status,
      dto.group
    )
  }

  static instanceContractType(dto: IContractType): ContractType {
    return new ContractType(
      dto.id,
      dto.active,
      dto.createdBy,
      dto.group,
      dto.monthlyLife,
      dto.role,
      dto.type,
      dto.roleAlias,
      dto.typeAlias,
      dto.createdAt,
      dto.updatedAt
    )
  }

  static async getCommissionAssignmentContracts(
    params = {}
  ): Promise<Pagination<CommissionAssignmentContract>> {
    return contractsApi
      .get<CommissionAssignmentContractsResponse>(
        '/v1/contracts/commission-assignment',
        { params }
      )
      .then(({ data }) => ({
        ...data,
        data: data.contracts.map((c) =>
          ContractsApi.instanceCommissionAssignmentContract(c)
        )
      }))
  }

  static async createContractType(data: any): Promise<ContractType> {
    return contractsApi
      .post<IContractType>('/v1/contract-type', data)
      .then((r) => ContractsApi.instanceContractType(r.data))
  }

  static async updateContractType(
    id: string,
    data: any
  ): Promise<ContractType> {
    return contractsApi
      .put<IContractType>(`/v1/contract-type/${id}`, data)
      .then((r) => ContractsApi.instanceContractType(r.data))
  }

  static async getNewEnrollmentContracts(
    params = {}
  ): Promise<Pagination<EnrollmentContract>> {
    return contractsApi
      .get<NewEnrollmentContractsResponse>('/v1/contracts/new-enrollment', {
        params
      })
      .then(({ data }) => ({
        ...data,
        data: data.contracts.map((c) =>
          ContractsApi.instanceEnrollmentContract(c)
        )
      }))
  }

  static async getContractTypes(
    params = {}
  ): Promise<Pagination<ContractType>> {
    return contractsApi
      .get<GetContractTypesResponse>('/v1/contract-type', { params })
      .then(({ data }) => ({
        ...data,
        data: data.contracts.map((el) => ContractsApi.instanceContractType(el))
      }))
  }

  static async getContractTypeRoles(params = {}) {
    return contractsApi.get('/v1/role', { params }).then((r) => r.data)
  }

  static async getContractTypeOptions(): Promise<ContractTypeOption[]> {
    return contractsApi
      .get<GetContractTypeOptionsResponse>('/v1/type')
      .then(({ data }) => data.types)
  }

  static async getNewEnrollmenContractsReport(params = {}): Promise<string> {
    return contractsApi
      .get('/v1/contracts/new-enrollment/csv-download', { params })
      .then((r) => r.data)
  }

  static async getCommissionAssignmentContractsReport(
    params = {}
  ): Promise<string> {
    return contractsApi
      .get('/v1/contracts/commission-assignment/csv-download', { params })
      .then((r) => r.data)
  }

  static async sendEmailReminder(
    contractId: string,
    createdBy: string
  ): Promise<unknown> {
    return contractsApi.get(`/v1/contracts/send-reminder-email/${contractId}`, {
      params: { createdBy }
    })
  }

  static async getContractFileUrl(
    contractId: string
  ): Promise<{ urlReceived: string; urlSent: string }> {
    return contractsApi
      .get(`/v1/contracts/url-contract/${contractId}`)
      .then((r) => r.data)
  }

  static async confirmSignature(
    contractId: string,
    createdBy: string
  ): Promise<EnrollmentContract> {
    return contractsApi
      .put<IEnrollmentContract>(
        `/v1/contracts/new-enrollment/${contractId}/sign`,
        { createdBy }
      )
      .then((r) => ContractsApi.instanceEnrollmentContract(r.data))
  }

  static async rejectSignature(
    contractId: string,
    reason: string,
    createdBy: string
  ): Promise<EnrollmentContract> {
    return contractsApi
      .put<IEnrollmentContract>(
        `/v1/contracts/new-enrollment/${contractId}/reject`,
        { createdBy, reason }
      )
      .then((r) => ContractsApi.instanceEnrollmentContract(r.data))
  }

  static async cancelNewEnrollmentContract(
    contractId: string,
    reason: string,
    createdBy: string
  ): Promise<EnrollmentContract> {
    return contractsApi
      .put<IEnrollmentContract>(
        `/v1/contracts/new-enrollment/${contractId}/cancel`,
        { createdBy, reason }
      )
      .then((r) => ContractsApi.instanceEnrollmentContract(r.data))
  }

  static async cancelCommissionAssignmentContract(
    contractId: string,
    reason: string,
    createdBy: string
  ): Promise<CommissionAssignmentContract> {
    return contractsApi
      .put<ICommissionAssignmentContract>(
        `/v1/contracts/commission-assignment/${contractId}/cancel`,
        { createdBy, reason }
      )
      .then((r) => ContractsApi.instanceCommissionAssignmentContract(r.data))
  }
}
