import { ContractRoles } from './contract-roles.enum'

export interface IContractType {
  active: boolean
  createdAt: string
  createdBy: string | null
  group: string
  id: string
  monthlyLife: null | number
  role: ContractRoles
  type: string
  roleAlias: string
  typeAlias: string
  updatedAt: string
}

export class ContractType implements Omit<IContractType, 'createdAt' | 'updatedAt'> {
  createdAt: Date
  updatedAt: Date
  constructor (
    public id: string,
    public active: boolean,
    public createdBy: string | null,
    public group: string,
    public monthlyLife: null | number,
    public role: ContractRoles,
    public type: string,
    public roleAlias: string,
    public typeAlias: string,
    createdAt: string,
    updatedAt: string
  ) {
    this.createdAt = new Date(createdAt)
    this.updatedAt = new Date(updatedAt)
  }
}

export interface GetContractTypesResponse {
  contracts: IContractType[]
  limit: number
  skip: number
  total: number
}

export interface ContractTypeOption {
  label: string,
  value: string
}

export interface GetContractTypeOptionsResponse {
  types: ContractTypeOption[]
}
