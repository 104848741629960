
import { defineComponent, computed } from 'vue'
import { useCountriesStore } from '@/store/modules/commons/countries-store'
import { Country } from '@/models/countries/classes/Country'

export default defineComponent({
  props: {
    disabled: {
      type: Boolean,
      required: true
    }
  },
  setup () {
    const countriesStore = useCountriesStore()
    const countries = computed<Country[]>(() => countriesStore.countriesSortedByNames)
    return {
      countries,
      Country
    }
  }
})
