import { CoursePlanAPI } from '@/api/academic-definitions/course-plan.api'
import { CoursePlan } from '@/models/academic-definitions/classes/CoursePlan'
import { ToastLife, ToastSeverities, ToastSummaries } from '@/models/components/toast/enums'
import { Country } from '@/models/countries/classes/Country'
import { CustomFilter, FilterProps } from '@/models/custom-filters/classes/CustomFilter'
import { CourseFilters } from '@/models/custom-filters/filters/CourseFilters'
import { TableName, TypeFilter } from '@/models/custom-filters/enum'
import { DataTablePageEvent, DataTableSortEvent } from '@/models/interfaces/primevue'
import { computed, onMounted, ref, Ref, watch } from 'vue'
import { useCustomFilters } from '../../../../education/courses/children/hooks/useCustomFilters'
import { Observable } from 'rxjs'
import { useToast } from 'primevue/usetoast'
import { ColumnFields } from '@/models/components/tables/enums'
import { Column } from '@/models/components/tables/Column'
import { StaffFilters } from '@/models/custom-filters/filters/StaffFilters'
import { StaffListApi } from '@/api/staff-list.api'
import { StaffData } from '@/models/staff-list/classes/StaffList'
import { PersonRoles } from '@/models/person/enums'
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators'

interface StaffListState {
  staff: Ref<StaffData | null>
  firstVal: Ref<number>
  loading: Ref<boolean>
  filters: Ref<StaffFilters>
  allCoursePlans: Ref<CoursePlan[]>
  totalRecords: Ref<number>
  searchTxt: Ref<string>
  sortFieldFilter: Ref<string|null>
  sortOrderFilter: Ref<number>
  recordsLimit: number
  courseFilters: Ref<CustomFilter[]>
  courseViews: Ref<CustomFilter[]>
  selectedCustomFilter: Ref<CustomFilter | null>
  selectedCustomView: Ref<CustomFilter | null>
  filterNameInput: Ref<string>
  isFiltered: Ref<boolean>
  allColumns: Map<ColumnFields, Column>
  displayCreateFilter: Ref<{ show: boolean, type: string }>
  selectedColumns: Ref<ColumnFields[]>
  onPage: (e: DataTablePageEvent) => void
  onSort: (e: DataTableSortEvent) => void
  updateFilterListened: (f: FilterProps) => void
  showConfirmDeleteFilter: (cf: CustomFilter) => void
  updateSearchTxt: (val: string) => void
  changeSelectedColumns: (keys: ColumnFields[]) => void
  cleanAndUpdateFiltersBySelector: (f: CustomFilter) => void
  toggleCreateFilter: (type: TypeFilter | null) => void
  removeActiveFilter: (type: TypeFilter) => void
  saveFilters: (type: TypeFilter) => Promise<void>
}

export const useStaffList = (person: PersonRoles): StaffListState => {
  // Active filter
  const filters = ref<StaffFilters>(new StaffFilters())
  const selectedCountries = computed<Country[]>(() => filters.value.countries.value as Country[])
  const selectedCoursePlans = computed<CoursePlan[]>(() => filters.value.coursePlans.value as CoursePlan[])
  const searchTxt = ref('')
  const activeCommissionsCount = computed<number>(() => filters.value.activeCommissionsCount.value as number)
  const finishedRatingLower = computed<number>(() => parseFloat(filters.value.finishedAverage.value ? filters.value.finishedAverage.value.toString().split(',')[0] : '0'))
  const finishedRatingHigher = computed<number>(() => parseFloat(filters.value.finishedAverage.value ? filters.value.finishedAverage.value.toString().split(',')[1] : '5'))
  const lastCommissionRatingLower = computed<number>(() => parseFloat(filters.value.lastCommissionRating.value ? filters.value.lastCommissionRating.value.toString().split(',')[0] : '0'))
  const lastCommissionRatingHigher = computed<number>(() => parseFloat(filters.value.lastCommissionRating.value ? filters.value.lastCommissionRating.value.toString().split(',')[1] : '5'))
  const warmup = computed<boolean>(() => filters.value.warmup.value as boolean)
  const kickoff = computed<boolean>(() => filters.value.kickoff.value as boolean)
  const allColumns: Map<ColumnFields, Column> = new Map([
    [ColumnFields.Name, { field: ColumnFields.Name, header: 'Nombre' }],
    [ColumnFields.CoursePlan, { field: ColumnFields.CoursePlan, header: 'Cursos' }],
    [ColumnFields.ActiveCommissionsCount, { field: ColumnFields.ActiveCommissionsCount, header: 'Asignaciones activas' }],
    [ColumnFields.FinishedAverage, { field: ColumnFields.FinishedAverage, header: 'Promedio finalizadas' }],
    [ColumnFields.LastCommissionRating, { field: ColumnFields.LastCommissionRating, header: 'Rating última comisión' }],
    [ColumnFields.Warmup, { field: ColumnFields.Warmup, header: 'Warmup' }],
    [ColumnFields.Kickoff, { field: ColumnFields.Kickoff, header: 'Kickoff' }]
  ])

  const selectedColumns = ref<ColumnFields[]>(Array.from(allColumns.keys()))

  const changeSelectedColumns = (keys: ColumnFields[]):void => {
    selectedColumns.value = keys
  }

  watch(selectedColumns, () => {
    filters.value.columns.value = selectedColumns.value
  })

  const updateSearchTxt = (val: string): void => {
    searchTxt.value = val
  }
  const searchTxt$ = new Observable<string>(subscriber => {
    watch(searchTxt, () => subscriber.next(searchTxt.value))
  })

  searchTxt$.pipe(
    debounceTime(1500),
    distinctUntilChanged(),
    tap(() => (firstVal.value = 0))
  ).subscribe(() => { updateList(person) })

  // UI
  const toast = useToast()
  const loading = ref<boolean>(false)
  // Table config
  const staff = ref<StaffData | null>(null)
  const firstVal = ref(0)
  const recordsLimit = 15
  const sortFieldFilter = ref<string|null>(null)
  const sortOrderFilter = ref<number>(1)
  const totalRecords = ref<number>(0)
  const allCoursePlans = ref<CoursePlan[]>([])
  const updateList = async (person: PersonRoles) => {
    try {
      loading.value = true
      const response = await StaffListApi.getStaffList(
        {
          offset: firstVal.value,
          limit: recordsLimit,
          role: person,
          search: (searchTxt.value.trim() === '' ? null : encodeURIComponent(searchTxt.value.trim())) as string,
          ...(selectedCountries.value) && { countries: selectedCountries.value.map(c => c.code).join(',') },
          ...(selectedCoursePlans.value) && { courseNames: selectedCoursePlans.value.map(c => c.information.name).join(',') },
          activeCommissionsCount: activeCommissionsCount.value,
          finishedRatingLower: (isNaN(finishedRatingLower.value) ? null : finishedRatingLower.value) as number,
          finishedRatingHigher: (isNaN(finishedRatingHigher.value) ? null : finishedRatingHigher.value) as number,
          lastCommissionRatingLower: (isNaN(lastCommissionRatingLower.value) ? null : lastCommissionRatingLower.value) as number,
          lastCommissionRatingHigher: (isNaN(lastCommissionRatingHigher.value) ? null : lastCommissionRatingHigher.value) as number,
          warmup: warmup.value,
          kickoff: kickoff.value
        }
      )
      staff.value = response as StaffData
      totalRecords.value = response.count
    } catch (error) {
      toast.add({
        summary: ToastSummaries.Error,
        detail: 'Ocurrió un error al cargar listado de staff',
        severity: ToastSeverities.Error,
        life: ToastLife.Default
      })
      console.error(error)
    } finally {
      loading.value = false
    }
  }
  const findAllCoursePlansToSelector = async () => {
    try {
      loading.value = true
      allCoursePlans.value = await CoursePlanAPI.findAll()
    } catch (e) {
      console.error(e)
    } finally {
      loading.value = false
    }
  }
  const {
    findAllCustomFilters,
    showConfirmDeleteFilter,
    updateFilterListened,
    cleanAndUpdateFiltersBySelector,
    toggleCreateFilter,
    removeActiveFilter,
    saveFilters,
    allCustomFilters,
    isFiltered,
    filterNameInput,
    selectedCustomFilter,
    selectedCustomView,
    allCustomViews,
    displayCreateFilter
  } = useCustomFilters({
    loading: loading,
    tableName: TableName.PlanningCoursesView,
    filtersListened: filters,
    cleanFilter: new CourseFilters() as unknown as FilterProps
  })
  onMounted(async () => {
    findAllCoursePlansToSelector()
    findAllCustomFilters()
    updateList(person)
  })
  watch(filters, () => {
    firstVal.value = 0
    updateList(person)
  })

  return {
    staff,
    firstVal,
    filters,
    allCoursePlans,
    loading,
    searchTxt,
    totalRecords,
    sortFieldFilter,
    sortOrderFilter,
    filterNameInput,
    recordsLimit,
    selectedCustomFilter,
    selectedCustomView,
    isFiltered,
    selectedColumns,
    allColumns,
    displayCreateFilter,
    courseFilters: allCustomFilters,
    courseViews: allCustomViews,
    onPage: (e: DataTablePageEvent) => {
      firstVal.value = e.page * recordsLimit
      updateList(person)
    },
    onSort: (e: DataTableSortEvent) => {
      sortFieldFilter.value = e.sortField as string
      sortOrderFilter.value = e.sortOrder as number
      updateList(person)
    },
    updateFilterListened,
    showConfirmDeleteFilter,
    updateSearchTxt,
    cleanAndUpdateFiltersBySelector,
    toggleCreateFilter,
    removeActiveFilter,
    saveFilters,
    changeSelectedColumns
  }
}
