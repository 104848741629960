
import { defineComponent, onMounted, ref, reactive, Ref } from 'vue'
import { CommissionAssignmentContract, ContractRoles, ContractStatus, ContractGroups } from '@/models/contracts'
import { MenuItem } from 'primevue/menuitem'
import { contractRole, contractStatus, contractStatusTextColor, contractGroup } from '../../../helpers'
import { FilterMatchMode } from 'primevue/api'
import { DataTableFilterEvent, DataTableFilterMeta, DataTablePageEvent } from 'primevue/datatable'
import { formatDate } from '@/utils/formatDate'
import { FormatDates } from '@/models/enums'
import { useUserSessionStore } from '@/store/modules/commons/user-session-store'
import { useToastService } from '@/hooks/useToastService'
import ContractsApi from '@/api/contracts.api'
import Menu from 'primevue/menu'
import { CoursePlanAPI } from '@/api/academic-definitions/course-plan.api'
import { CoursePlan } from '@/models/academic-definitions/classes/CoursePlan'

interface SearchParams extends Record<string, unknown> {
  skip: number;
  limit: number;
}

export default defineComponent({
  setup () {
    const actionMenu = ref<Menu>()
    const actionMenuItems = ref<MenuItem[]>([])
    const courseNameFilterOptions = ref<CoursePlan[]>([])
    const contracts = ref<CommissionAssignmentContract[]>([])
    const loading = ref(true)
    const session = useUserSessionStore()
    const toast = useToastService()
    const totalRecords = ref(0)
    const params = reactive<SearchParams>({
      skip: 0,
      limit: 50
    })
    const filters = ref<DataTableFilterMeta>({
      status: {
        value: null, matchMode: FilterMatchMode.IN
      },
      expirationDate: {
        value: null, matchMode: FilterMatchMode.DATE_AFTER
      },
      createdAt: {
        value: null,
        matchMode: FilterMatchMode.DATE_AFTER
      },
      roles: {
        value: null, matchMode: FilterMatchMode.IN
      },
      groups: {
        value: null, matchMode: FilterMatchMode.IN
      },
      userName: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      },
      courseName: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },
      commissionNumber: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },
      moreThanTenDays: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      }
    })
    const loadContracts = async () => {
      try {
        loading.value = true
        const { total, data } = await ContractsApi.getCommissionAssignmentContracts(params)
        courseNameFilterOptions.value = await CoursePlanAPI.findAll({ published: true })
        totalRecords.value = total
        contracts.value = data
      } catch (error) {
        console.error(error)
        toast.showError('Error al cargar los contratos')
      } finally {
        loading.value = false
      }
    }
    const onPage = (ev: DataTablePageEvent) => {
      params.skip = ev.first
      params.limit = ev.rows
      loadContracts()
    }
    const onFilter = (ev: DataTableFilterEvent) => {
      const { status, roles, createdAt, expirationDate, userName, courseName, commissionNumber, moreThanTenDays } = ev.filters as unknown as Record<string, Ref>
      params.status = status.value
      params.roles = roles.value
      params.createdAt = createdAt?.value
      params.expirationDate = expirationDate?.value
      params.userName = userName.value
      params.courseName = courseName.value
      params.commissionNumber = commissionNumber.value
      params.moreThanTenDays = moreThanTenDays.value
      loadContracts()
    }
    onMounted(loadContracts)
    return {
      actionMenu,
      contractRole,
      contractGroup,
      contracts,
      contractStatus,
      ContractStatus,
      contractStatusTextColor,
      filters,
      formatDate,
      FormatDates,
      loadContracts,
      loading,
      onFilter,
      onPage,
      params,
      totalRecords,
      actionMenuItems,
      toggleActionsMenu: (e: Event, item: CommissionAssignmentContract) => {
        actionMenuItems.value = [
          {
            label: 'Reenviar recordatorio',
            visible: item.status === ContractStatus.PendingSignature,
            icon: 'pi pi-refresh',
            command: async () => {
              try {
                loading.value = true
                await ContractsApi.sendEmailReminder(item.id, session.currentUser._id)
                toast.showSuccessfullyDone('Recordatorio enviado')
              } catch (error) {
                console.error(error)
                toast.showError('Error al reenviar el recordatorio')
              } finally {
                loading.value = false
              }
            }
          },
          {
            label: 'Confirmar firma',
            icon: 'pi pi-check',
            visible: item.status === ContractStatus.PreSigned,
            command: async () => {
              try {
                loading.value = true
                const contract = await ContractsApi.confirmSignature(item.id, session.currentUser._id)
                contracts.value = contracts.value.map(
                  (c) => c.id === contract.id
                    ? ContractsApi.instanceCommissionAssignmentContract(c)
                    : c
                )
                toast.showSuccessfullyDone('Firma confirmada')
              } catch (error) {
                console.error(error)
                toast.showError('Error al confirmar la firma del contrato')
              } finally {
                loading.value = false
              }
            }
          },
          {
            label: 'Rechazar firma',
            icon: 'pi pi-times',
            visible: item.status === ContractStatus.PreSigned,
            command: async () => {
              try {
                loading.value = true
                const reason = prompt('Indique el motivo')
                if (reason) {
                  await ContractsApi.rejectSignature(item.id, reason, session.currentUser._id)
                  loadContracts()
                  toast.showSuccessfullyDone('Firma rechazada')
                } else {
                  throw new Error('Debe especificar la razón por la cual rechazó la firma')
                }
              } catch (error) {
                console.error(error)
                toast.showError('Error al rechazar la firma del contrato')
              } finally {
                loading.value = false
              }
            }
          },
          {
            label: 'Ver contrato',
            icon: 'pi pi-file-pdf',
            command: async () => {
              try {
                toast.showInfo('Buscando la URL del contrato')
                const { urlReceived, urlSent } = await ContractsApi.getContractFileUrl(item.id)
                if (urlReceived) window.open(urlReceived)
                else if (urlSent) window.open(urlSent)
                else {
                  toast.showError('No se pudo encontrar la URL del contrato')
                }
              } catch (error) {
                console.error(error)
                toast.showError('Error al buscar la URL del contrato')
              }
            }
          },
          {
            label: 'Cancelar contrato',
            icon: 'pi pi-ban',
            visible: ![ContractStatus.Cancelled, ContractStatus.Expired, ContractStatus.Rejected].includes(item.status),
            command: async () => {
              try {
                loading.value = true
                const reason = prompt('Indique el motivo')
                if (reason) {
                  const contract = await ContractsApi.cancelCommissionAssignmentContract(item.id, reason, session.currentUser._id)
                  contracts.value = contracts.value.map(
                    (c) => c.id === contract.id
                      ? ContractsApi.instanceCommissionAssignmentContract({
                        ...c,
                        status: contract.status
                      })
                      : c
                  )
                  toast.showSuccessfullyDone('Contrato cancelado')
                } else {
                  toast.showError('Debe completar el motivo de cancelación')
                }
              } catch (error) {
                toast.showError('Error al cancelar el contrato')
                console.error(error)
              } finally {
                loading.value = false
              }
            }
          }
        ]
        actionMenu.value?.toggle(e)
      },
      async downloadCommissionAssignmentContractsReport () {
        try {
          toast.showInfo('Descargando CSV')
          const csv = await ContractsApi.getCommissionAssignmentContractsReport(params)
          const hiddenElement = document.createElement('a')
          hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
          hiddenElement.target = '_blank'
          hiddenElement.download = 'reporte_contratos_de_asignacion_a_comision.csv'
          hiddenElement.click()
        } catch (error) {
          console.error(error)
          toast.showError('Error al obtener el .csv')
        }
      },
      courseNameFilterOptions,
      roleFilterOptions: Object.values(ContractRoles)
        .map((role) => ({ name: contractRole(role), value: role })),
      groupFilterOptions: Object.values(ContractGroups)
        .map((group) => ({ name: contractGroup(group), value: group })),
      statusFilterOptions: Object.values(ContractStatus)
        .map((status) => ({ name: contractStatus(status), value: status }))
    }
  }
})
