
import { Column } from '@/models/components/tables/Column'
import { CustomFilter } from '@/models/custom-filters/classes/CustomFilter'
import { ColumnFields } from '@/models/components/tables/enums'
import OverlayPanel from 'primevue/overlaypanel'
import ScrollPanel from 'primevue/scrollpanel'
import { TypeFilter } from '@/models/custom-filters/enum'
import { defineComponent, ref, PropType, watch, onMounted, toRefs } from 'vue'
export default defineComponent({
  props: {
    allColumns: {
      require: true,
      type: Array as PropType<Column[]>
    },
    allFilters: {
      type: Array as PropType<CustomFilter[]>,
      require: true
    },
    allViews: {
      type: Array as PropType<CustomFilter[]>,
      require: true
    },
    modelValue: {
      type: Array as PropType<ColumnFields[]>,
      require: true
    },
    selectedColumns: {
      require: false,
      type: Array as PropType<ColumnFields[]>
    }
  },
  components: {
    OverlayPanel,
    ScrollPanel
  },
  emits: ['update:selectedColumns', 'deleteFilter', 'selectFilter', 'toogleCreateFilter'],
  setup (props, { emit }) {
    const { selectedColumns, allColumns } = toRefs(props)
    const deleteOptionFlag = ref<boolean>(false)
    const activeIndex = ref(0)
    const op = ref()
    const toggle = (event: Event) => {
      op.value.toggle(event)
    }

    const selectedColumnsRef = ref<ColumnFields[]>([])
    const initSelectedColumns = () => {
      const newSelectedColumns = (allColumns.value as Column[] || []).map(c => c.field as ColumnFields)
      emitsChangeColums(newSelectedColumns)
    }
    onMounted(() => {
      !selectedColumns.value && initSelectedColumns()
    })
    watch(selectedColumns, () => {
      if (selectedColumns.value) {
        selectedColumnsRef.value = selectedColumns.value as ColumnFields[]// Si se cambia el filtro, que se actualice el nro en el selector
      } else {
        initSelectedColumns()
      }
    })

    const deleteFilter = (cf: CustomFilter) => {
      deleteOptionFlag.value = true
      emit('deleteFilter', cf)
    }
    const returnToNull = (sf: CustomFilter):void => {
      if (deleteOptionFlag.value) { // Esta función permite que no se cambie el valor cuando se clickea el botón de eliminar
        deleteOptionFlag.value = false
        return
      }
      emit('selectFilter', sf)
      op.value.hide()
    }

    const emitsChangeColums = (value: ColumnFields[]) => {
      emit('update:selectedColumns', value)
    }

    const emitToggleCreateFilter = (type: TypeFilter = TypeFilter.View) => emit('toogleCreateFilter', type)

    return { op, toggle, activeIndex, selectedColumnsRef, deleteFilter, returnToNull, emitsChangeColums, emitToggleCreateFilter }
  }
})
