import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.classRef),
    style: _normalizeStyle({
    'background-color': _ctx.styles.backgroundColor,
    'color': _ctx.styles.textColor,
    'padding': _ctx.styles.padding,
    'font-size': _ctx.styles.fontSize,
    'border-radius': _ctx.styles.borderRadius,
    'display': _ctx.styles.display,
    'align-items': _ctx.styles.alignItems,
    'text-align' : _ctx.styles.textAlign,
    'letter-spacing': _ctx.styles.letterSpacing,
    'margin-left': _ctx.styles.marginLeft,
    'justify-content': _ctx.styles.justifyContent,
    'border': _ctx.styles.border
  })
  }, [
    _createElementVNode("span", null, _toDisplayString(_ctx.text), 1)
  ], 6))
}