import { MapPerson } from '@/models/person/classes/MapPerson'
import { Person } from '@/models/person/classes/Person'
import { PersonRoles } from '@/models/person/enums'
import { average } from '@/utils/math'
import { CourseFeedbackByPerson } from '../interfaces/CourseFeedBackByPerson'
import { CourseFeedback } from './CourseFeedback'
import { FeedbackEntry } from '../interfaces/dto/FeedbackEntry'
import { IEntriesFeedbacks } from '../interfaces/dto/IEntriesFeedbacks'
import { PersonFeedbackEntry } from '../interfaces/dto/PersonFeedbackEntry'
import { Class } from '@/models/course/classes/Class'
import { CourseAverageRating } from '../interfaces/dto/CourseAverageRating'
import { CourseFeedbackRating } from '../interfaces/dto/CourseFeedbackRating'

export class EntriesFeedbacks implements IEntriesFeedbacks {
  contentFeedback: FeedbackEntry
  entries: PersonFeedbackEntry[]
  npsFeedback?: FeedbackEntry
  private persons: MapPerson
  constructor (from: CourseFeedback) {
    this.contentFeedback = from.contentFeedback
    this.entries = from.entries
    this.npsFeedback = from.npsFeedback
    this.persons = new MapPerson()
  }

  getAvarage (): number | null {
    const entriesScore = this.entries?.reduce((entriesScore, entry) => {
      const score = entry.score
      if (score) {
        entriesScore.push(score)
      }
      return entriesScore
    }, [] as number[])
    const contentScore = this.contentFeedback && this.contentFeedback.score ? [this.contentFeedback.score] : []
    return [...entriesScore, ...contentScore].length !== 0
      ? average([...entriesScore, ...contentScore])
      : null
  }

  getAccumulatedRating (): CourseFeedbackRating {
    const entries = this.entries?.reduce((entries, entry) => {
      const score = entry.score
      if (score) {
        entries.ratingSummation += entry.score
        entries.feedbackCount++
      }
      return entries
    }, { ratingSummation: 0, feedbackCount: 0 } as CourseFeedbackRating)
    if (this.contentFeedback && this.contentFeedback.score) {
      entries.ratingSummation += this.contentFeedback.score
      entries.feedbackCount++
    }
    return entries
  }

  getTeacherScore (): number {
    return this.entries.find(e => e.role === PersonRoles.Teacher)?.score || 0
  }

  getTutorScore (): number {
    return this.entries.find(e => e.role === PersonRoles.Tutor)?.score || 0
  }

  getcontentScore (): number {
    return this.contentFeedback.score || 0
  }

  async getTeacher (): Promise<Person> {
    const feedback = this.entries.find(e => e.role === PersonRoles.Teacher)
    if (feedback) return this.persons.getPerson(feedback.personId)

    throw new Error("Feedback there isn't teacher")
  }

  /**
   * Returns the average rating of a student in all the classes in which he gave feedback.
   * The average is calculated depending on whether they are classes or afterclasses.
   * So we need to pass classes or afterClasses to calculate validEntries
   * @param CourseFeedbackByPerson Map<string, EntriesFeedbacks>
   * @param classes Class[]
   * @returns number : the general average rating
   */
  static getAllAvarageByStudent (mapper: Map<string, EntriesFeedbacks>, classes: Class[]): number {
    let result = 0
    let count = 0
    const validEntries = Array.from(mapper.keys()).filter(el => {
      return classes.some(c => c._id === el)
    })
    for (const [k, entry] of mapper) {
      if (validEntries.includes(k)) {
        const entryAverage = entry.getAvarage()
        if (entryAverage) {
          result += entryAverage
          count++
        }
      }
    }
    return result / (count || 1)
  }

  /**
  * Returns a map with the number of students who gave feedback in each class.
  * The classIds may be from class or afterClass
  * @param {CourseFeedbackByPerson} mapper
  * @returns Map<classId: string, qtyStudents: number>
  * Esta OK
  */
  static getPersonsGaveFeedbacks (
    mapper: CourseFeedbackByPerson
  ): Map<string, number> {
    const mapClass = new Map<string, number>()
    for (const personAndFeedbacks of mapper.values()) {
      for (const [classId, feedbackEntry] of personAndFeedbacks.feedbacks) {
        const classInMap = mapClass.get(classId)
        if ((feedbackEntry.contentFeedback || feedbackEntry.entries) && feedbackEntry.npsFeedback === null) {
          // This if filters persons with only nps feedback
          if (classInMap) {
            const newValue = classInMap + 1
            mapClass.set(classId, newValue)
          } else {
            mapClass.set(classId, 1)
          }
        }
      }
    }
    return mapClass
  }

  /**
  * Returns a map with the avg feedback rating in each class
  * @param {CourseFeedbackByPerson} mapper
  * @param { Map<string, number> } qtyGaveFeedbackByClassId
  * @returns Map<classId: string, avgRating: number | null>
  */
  static getAllAvarage (
    mapper: CourseFeedbackByPerson,
    studentGaveMapp: Map<string, number | null>
  ): CourseAverageRating {
    const courseAverageRating: CourseAverageRating = { classes: new Map(), course: { rating: 0 } }
    const mapClass = new Map<string, number | null>()
    const accumulated: CourseFeedbackRating = { ratingSummation: 0, feedbackCount: 0 }
    for (const personAndFeedbacks of mapper.values()) {
      for (const [classId, feedbackEntry] of personAndFeedbacks.feedbacks) {
        const classInMap = mapClass.get(classId)
        if (classInMap || feedbackEntry.getAvarage()) {
          mapClass.set(classId, ((classInMap || 0) + (Number(feedbackEntry.getAvarage()) / (studentGaveMapp.get(classId) || 1))))
        } else {
          mapClass.set(classId, feedbackEntry.getAvarage())
        }
        const courseFeedbackRating = feedbackEntry.getAccumulatedRating()
        accumulated.ratingSummation += courseFeedbackRating.ratingSummation
        accumulated.feedbackCount += courseFeedbackRating.feedbackCount
      }
    }
    courseAverageRating.course.rating = (accumulated.ratingSummation / accumulated.feedbackCount) || 0
    courseAverageRating.classes = mapClass
    return courseAverageRating
  }
}
