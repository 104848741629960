
import { defineComponent, PropType, toRefs, ref, watch } from 'vue'
import CustomFilterSelector from '@/components/commons/selectors/CustomFilterSelector.vue'
import { CustomFilter } from '@/models/custom-filters/classes/CustomFilter'
import { ColumnFields } from '@/models/components/tables/enums'
import { Column } from '@/models/components/tables/Column'
import Chip from 'primevue/chip'
import { TypeFilter } from '@/models/custom-filters/enum'
export default defineComponent({
  components: {
    CustomFilterSelector,
    Chip
  },
  props: {
    isLoading: {
      require: true,
      type: Boolean
    },
    isFiltered: {
      require: true,
      type: Boolean
    },
    searchTxt: {
      require: true,
      type: String
    },
    allCustomFilters: {
      require: true,
      type: Array as PropType<CustomFilter[]>
    },
    allCustomViews: {
      require: true,
      type: Array as PropType<CustomFilter[]>
    },
    selectedCustomFilter: {
      require: true,
      type: Object as PropType<CustomFilter>
    },
    selectedCustomView: {
      require: true,
      type: Object as PropType<CustomFilter>
    },
    allColumns: {
      require: false,
      type: Array as PropType<Column[]>
    },
    selectedColumns: {
      require: false,
      type: Array as PropType<ColumnFields[]>
    }
  },
  emits: ['selectFilter', 'deleteFilter', 'removeActiveFilter', 'toggleCreateFilter', 'updateSearchTxt', 'selectColumns'],
  setup (props, { emit }) {
    const { searchTxt } = toRefs(props)
    const searchTxtRef = ref<string>(searchTxt.value as string)
    watch(searchTxtRef, () => {
      emit('updateSearchTxt', searchTxtRef.value)
    })
    return {
      searchTxtRef,
      emitSelectFilter: (sf: CustomFilter): void => emit('selectFilter', sf),
      emitDeleteFilter: (sf: CustomFilter): void => emit('deleteFilter', sf),
      emitToggleCreateFilter: (type: TypeFilter = TypeFilter.Filter): void => emit('toggleCreateFilter', type),
      emitRemoveActiveFilter: (): void => emit('removeActiveFilter', TypeFilter.Filter),
      emitRemoveActiveView: (): void => emit('removeActiveFilter', TypeFilter.View),
      emitSelectColumns: (keys: ColumnFields[]): void => emit('selectColumns', keys)
    }
  }
})
