export class Rating {
  value: number
  constructor (value: number) {
    this.value = value
  }
}

export class AverageSummary {
  contentSummary: Rating
  teacherSummary: Rating
  tutorSummary: Rating
  constructor (
    contentSummary?: Rating,
    teacherSummary?: Rating,
    tutorSummary?: Rating
  ) {
    this.contentSummary = contentSummary || { value: 0 }
    this.teacherSummary = teacherSummary || { value: 0 }
    this.tutorSummary = tutorSummary || { value: 0 }
  }
}
