import { RouteRecordRaw } from 'vue-router'

const TalentRoutes: Array<RouteRecordRaw> = [
  {
    path: 'reports',
    name: 'Reports',
    component: () => import('@/views/talent/Reports.vue')
  }
]

export default TalentRoutes
