import { PersonRoles } from '@/models/person/enums'
import { _objectToMapper } from '@/utils/mapper'
import { FeedbackCharacter } from '../../enums'
import { IFeedbackSummary, IFeedbackSummaryTotal } from '../interfaces/IFeedbackSummary'
import { Rating } from './Rating'

export class FeedbackSummary {
  rating: Rating | null
  nps: string | undefined
  ratingsByStudents: number[]
  normalized: Rating | null
  feedbackCount: number
  _id: string
  name: string
  lastname: string
  role: PersonRoles
  tagsFeedbacks?: {
    numberTags: Map<FeedbackCharacter, Map<string, number>>
  }

  detailFeedbackCount?: Map<string, number>
  constructor (from: IFeedbackSummary) {
    this.rating =
      from && from.rating
        ? <Rating>{
          value: from.rating.value,
          variation: from.rating.variation
        }
        : null
    this.ratingsByStudents = from.ratingsByStudents
    this.normalized =
    from && from.normalized
      ? <Rating>{
        value: from.normalized.value,
        variation: from.normalized.variation
      }
      : null
    this.feedbackCount = from.feedbackCount
    this._id = from._id
    this.name = from.name
    this.lastname = from.lastname
    this.role = from.role
    if (from.tagsFeedbacks) {
      this.tagsFeedbacks = {
        numberTags: new Map(
          Object.keys(from.tagsFeedbacks.numberTags).map(key => [
            key as FeedbackCharacter,
            _objectToMapper(
              from.tagsFeedbacks?.numberTags[
                key as FeedbackCharacter
              ] as Record<string, number>
            )
          ])
        )
      }
    } else {
      this.tagsFeedbacks = {
        numberTags: new Map<FeedbackCharacter, Map<string, number>>()
      }
    }
    if (from.detailFeedbackCount) {
      this.detailFeedbackCount = _objectToMapper(from.detailFeedbackCount)
    }
    this.nps = from.nps
  }

  static sortByPersonRating (a: FeedbackSummary, b: FeedbackSummary): 1 | -1 | 0 {
    if (a.rating && b.rating) {
      if (a.rating?.value > b.rating?.value) return 1
      if (a.rating?.value < b.rating?.value) return -1
      return 0
    } else {
      return 0
    }
  }
}

export class FeedbackSummaryTotal {
  rating: Rating | null
  name: string
  lastname: string
  role: PersonRoles
  _id: string
  constructor (from: IFeedbackSummaryTotal) {
    this.rating =
      from && from.rating
        ? <Rating>{
          value: from.rating.value,
          variation: from.rating.variation
        }
        : null
    this._id = from._id
    this.name = from.name
    this.lastname = from.lastname
    this.role = from.role
  }
}
