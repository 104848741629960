import { CoursePlan } from '@/models/academic-definitions/classes/CoursePlan'
import { ColumnFields } from '@/models/components/tables/enums'
import { Country } from '@/models/countries/classes/Country'

export interface IStaffFilters {
  countries: { value: Country[] | null }
  activeCommissionsCount: { value: number | null }
  finishedAverage: { value: Array<number> | Array<null> }
  lastCommissionRating: { value: Array<number> | Array<null> }
  columns: { value: ColumnFields[] | null }
  coursePlans: { value: CoursePlan[] | null }
  warmup: { value: boolean | null }
  kickoff: { value: boolean | null }
}

export class StaffFilters implements IStaffFilters {
  countries: { value: Country[] | null }
  activeCommissionsCount: { value: number | null }
  finishedAverage: { value: Array<number> | Array<null> }
  lastCommissionRating: { value: Array<number> | Array<null> }
  coursePlans: { value: CoursePlan[] | null }
  columns: { value: ColumnFields[] | null }
  warmup: { value: boolean | null }
  kickoff: { value: boolean | null }

  constructor (filters?: IStaffFilters) {
    this.countries = filters?.countries || { value: null }
    this.activeCommissionsCount = filters?.activeCommissionsCount || { value: null }
    this.finishedAverage = filters?.finishedAverage || { value: [null, null] }
    this.lastCommissionRating = filters?.lastCommissionRating || { value: [null, null] }
    this.coursePlans = filters?.coursePlans || { value: null }
    this.columns = filters?.columns || { value: null }
    this.warmup = filters?.warmup || { value: false }
    this.kickoff = filters?.kickoff || { value: false }
  }
}
