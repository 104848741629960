import { Api } from './api'
import { EmailData } from '@/models/email-sending/EmailData'

class EmailSendingApi {
  private readonly baseURL: string

  constructor() {
    this.baseURL = process.env.ACADEMIC_API_ENDPOINT
  }

  public async sendEmail(emailData: EmailData): Promise<void> {
    await Api.post<void>(`${this.baseURL}/v1/send-email`, emailData)
  }
}

export const EmailSendingAPI = new EmailSendingApi()
