
import { computed, defineComponent, onMounted } from 'vue'
import CodeBox from '@/components/commons/boxes/CodeBox.vue'
import { CommonRouteNames, RouteNames } from '@/router/route-names'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {
    CodeBox
  },
  props: {
    error: {
      type: String,
      required: false
    }
  },
  setup (props) {
    const errorRef = computed(() => props.error && JSON.parse(props.error))
    const router = useRouter()
    onMounted(() => {
      if (!errorRef.value) {
        router.push({
          name: CommonRouteNames.HOME_PAGE
        })
      }
    })
    return {
      RouteNames,
      errorRef
    }
  }
})
