
import { defineComponent, PropType } from 'vue'
import { RouteLocationRaw } from 'vue-router'

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true
    },
    to: {
      type: Object as PropType<RouteLocationRaw>,
      required: true
    },
    img: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    },
    workArea: {
      type: String,
      required: true
    }
  }
})
