import { Api } from './api'
import { ILocale, Locale } from '@/models/locale/locale'

const baseURL = `${process.env.API_GATEWAY}/translation/api`

async function getLocale(lang: string): Promise<Locale> {
  const locale = await Api.get<ILocale>(
    `${baseURL}/v1/languages/${lang}/context/brain/translations-1.1.json`
  )
  return new Locale(locale)
}

export { getLocale }
