import { EducationPlanConfiguration } from '../enums'
import { PlanInformation } from '../interfaces/PlanInformation'
import { PlanLinks } from '../interfaces/PlanLinks'

export class EducationPlan {
  constructor (
    readonly id: string,
    readonly v1Id: string,
    readonly countries: string[],
    readonly information: PlanInformation,
    readonly links: PlanLinks,
    readonly published: boolean,
    readonly display: boolean,
    readonly config: EducationPlanConfiguration[],
    readonly createdAt: Date,
    readonly updatedAt: Date,
    readonly __v: number
  ) {}
}
