import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-10a81011")
const _hoisted_1 = ["src", "alt"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Card = _resolveComponent("Card")!

  return (_ctx.visible)
    ? (_openBlock(), _createBlock(_component_Card, {
        class: "p-m-2 WorkArea p-shadow-2",
        key: _ctx.title,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push(_ctx.to))),
        id: _ctx.id
      }, {
        header: _withCtx(() => [
          _createElementVNode("img", {
            src: _ctx.img,
            alt: _ctx.title
          }, null, 8, _hoisted_1)
        ]),
        title: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.title), 1)
        ]),
        _: 1
      }, 8, ["id"]))
    : _createCommentVNode("", true)
}