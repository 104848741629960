import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!

  return (!_ctx.multipleSelection)
    ? (_openBlock(), _createBlock(_component_Dropdown, {
        key: 0,
        disabled: _ctx.disabled,
        options: _ctx.coursePlans,
        placeholder: "Seleccionar curso",
        optionLabel: "information.name",
        modelValue: _ctx.selectedCoursePlan,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectedCoursePlan = $event))
      }, null, 8, ["disabled", "options", "modelValue"]))
    : (_openBlock(), _createBlock(_component_MultiSelect, {
        key: 1,
        disabled: _ctx.disabled,
        panelClass: "overlay-gray",
        showToggleAll: false,
        modelValue: _ctx.selectedCoursePlans,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectedCoursePlans = $event)),
        options: _ctx.coursePlans,
        optionLabel: "information.name",
        placeholder: "Seleccionar cursos",
        style: {"max-width":"100%"},
        filter: _ctx.filter,
        filterPlaceholder: "Buscar curso",
        emptyFilterMessage: "UPS! No hay cursos con ese nombre"
      }, null, 8, ["disabled", "modelValue", "options", "filter"]))
}