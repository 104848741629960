import { DayOfTheWeek, FormatDates } from '@/models/enums'
import { DateTime } from 'luxon'
import { CourseHours } from '../enums'

export class Calendar {
  start: string
  end: string
  days?: number[] | null
  day?: number | null
  startHour?: string
  endHour?: string

  get daysOfTheWeekNames (): DayOfTheWeek[] | undefined {
    return this.days?.map(n => Object.values(DayOfTheWeek)[n])
  }

  get hours (): Map<CourseHours, string> | undefined {
    return this.startHour && this.endHour
      ? new Map([
        [CourseHours.Start, DateTime.fromISO(this.startHour).toFormat('HH:mm')],
        [CourseHours.End, DateTime.fromISO(this.endHour).toFormat('HH:mm')]
      ])
      : undefined
  }

  get startLAFormat (): string {
    return DateTime.fromISO(this.start).toFormat(FormatDates.LatinAmerica)
  }

  get endLAFormat (): string {
    return DateTime.fromISO(this.end).toFormat(FormatDates.LatinAmerica)
  }

  constructor (
    start: string,
    end: string,
    days?: number[] | null,
    day?: number | null,
    startHour?: string,
    endHour?: string
  ) {
    this.start = start
    this.end = end
    this.days = days
    this.day = day
    this.startHour = startHour
    this.endHour = endHour
  }

  formatDate (prop: keyof Calendar, format: FormatDates): string {
    if (prop === 'days') throw new Error("Can't parse days(number[])")
    return DateTime.fromISO((this[prop] as string)).toFormat(format)
  }
}
