export enum CoursePerformanceFiltersProps {
  countries= 'countries',
  startMonth= 'startMonth',
  courseType= 'courseType',
  coursePlans= 'coursePlans'
}

export enum CourseFiltersProps {
  countries= 'countries',
  startMonth= 'startMonth',
  endMonth= 'endMonth',
  courseType= 'courseType',
  coursePlans= 'coursePlans',
  teacherAssigned= 'teacherAssigned',
  tutorsCompleted= 'tutorsCompleted',
  coordinatorAssigned = 'coordinatorAssigned',
  isSoldOut = 'isSoldOut',
  isCancelled = 'isCancelled',
  days= 'days'
}

export enum StaffFiltersProps {
  countries= 'countries',
  coursePlans= 'coursePlans',
  activeCommissionsCount= 'activeCommissionsCount',
  finishedAverage= 'finishedAverage',
  lastCommissionRating= 'lastCommissionRating',
  warmup= 'warmup',
  kickoff= 'kickoff',
}
