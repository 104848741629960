
import { OrganizationChartNode, PersonPositionPopulatedDto } from '@/models/organization'
import { groupByInMap } from '@/utils/collections'
import OrgChart from 'primevue/organizationchart'
import { computed, defineComponent, nextTick, onMounted, PropType, toRefs, watch } from 'vue'
import { useRoute } from 'vue-router'

const Top = '--top--'

/**
 *
 * @param positions
 */
function convertToChartNodes (positions: Array<PersonPositionPopulatedDto>, showHiddenPositions: boolean): OrganizationChartNode {
  const positionsByParent = groupByInMap(positions.filter(p => showHiddenPositions || !p.hidden), p => p.parent?._id || Top)

  const topPositions = positionsByParent.get(Top)

  if (!topPositions) throw new Error('Invalid structure, no top elements')

  function convertToNode (position: PersonPositionPopulatedDto): OrganizationChartNode {
    const positionId = position._id.toString()

    const childrenNodes = positionsByParent.get(positionId)?.map(convertToNode)

    return {
      key: positionId,
      type: 'person',
      styleClass: 'p-person',
      data: position,
      selectable: true,
      collapsible: true,
      children: childrenNodes
    } as OrganizationChartNode
  }

  return convertToNode(topPositions[0])
}

/**
 *  Ver como alternativa: https://dev.to/jasper2virtual/bootstrapvue-responsive-organisation-chart-2f16
 *  Ver https://fperucic.github.io/treant-js/
 */
export default defineComponent({
  name: 'PeopleChart',
  components: {
    OrgChart
  },
  props: {
    editMode: Boolean,
    positions: {
      type: Object as PropType<Array<PersonPositionPopulatedDto>>,
      required: true
    },
    centerOn: {
      type: Object as PropType<PersonPositionPopulatedDto>,
      required: true
    }
  },
  emits: ['selected'],
  setup (props, { emit }) {
    const { positions, centerOn } = toRefs(props)
    const route = useRoute()

    const showHiddenPositions = route.query.showHiddenPositions === 'yes'

    const rootNode = computed(() => {
      const root = convertToChartNodes(positions.value, showHiddenPositions)
      console.log(`Computed rootNode: ${root.key}`)
      return root
    })

    const centerKey = computed(() => {
      const newCenter = centerOn.value._id || rootNode.value.key.toString()
      console.log(`Computed centerKey: ${newCenter}`)
      return newCenter
    })

    onMounted(async () => {
      console.log('PeopleChart mounted')
      await centerTo(centerKey.value)
    })

    const centerTo = async (key: string) => await nextTick(() => {
      console.log(`Moving center to: ${key}`)
      document.getElementById(key)?.scrollIntoView({
        block: 'nearest',
        behavior: 'smooth',
        inline: 'center'
      })
    })

    watch(centerKey, async (key: string) => {
      console.log(`Watched change on center key: ${key}`)
      key && await centerTo(key)
    })

    const nodeClicked = (position: PersonPositionPopulatedDto) => {
      console.log(`Position: ${position._id}, person: ${position.people.map(p => p._id).join(', ')}`)
      emit('selected', position)
    }

    return {
      rootNode,
      nodeClicked,
      unknown: require('@/assets/icons/unknown-person.png')
    }
  }
})
