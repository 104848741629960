import { CustomFiltersAPI } from '@/api/custom-filters.api'
import { ToastLife, ToastSeverities, ToastSummaries } from '@/models/components/toast/enums'
import { CustomFilter, FilterProps } from '@/models/custom-filters/classes/CustomFilter'
import { TableName, TypeFilter } from '@/models/custom-filters/enum'
import { useUserSessionStore } from '@/store/modules/commons/user-session-store'
import { useConfirm } from 'primevue/useconfirm'
import { useToast } from 'primevue/usetoast'
import { computed, ComputedRef, ref, Ref } from 'vue'

interface useCustomFiltersProps {
  loading: Ref<boolean> // usada para loadings de peticiones asincronas
  tableName: TableName
  filtersListened: Ref<FilterProps>
  cleanFilter: FilterProps
}

interface useCustomFiltersResponse {
  findAllCustomFilters: () => void
  toggleCreateFilter: (type: TypeFilter | null) => void
  saveFilters: (type: TypeFilter) => Promise<void>
  showConfirmDeleteFilter: (cf: CustomFilter) => void
  removeActiveFilter: (type: TypeFilter) => void
  updateFilterListened: (f: FilterProps) => void
  cleanAndUpdateFiltersBySelector: (f: CustomFilter) => void
  isFiltered: ComputedRef<boolean>
  allCustomFilters: Ref<CustomFilter[]>
  allCustomViews: Ref<CustomFilter[]>
  filterNameInput: Ref<string>
  selectedCustomFilter: Ref<CustomFilter | null>
  selectedCustomView: Ref<CustomFilter | null>
  displayCreateFilter: Ref<{ show: boolean, type: string }>
}

export const useCustomFilters = (
  {
    loading,
    tableName,
    filtersListened,
    cleanFilter
  }: useCustomFiltersProps): useCustomFiltersResponse => {
  const toast = useToast()
  const confirm = useConfirm()
  const user = useUserSessionStore().currentUser
  const allCustomFilters = ref<CustomFilter[]>([])
  const allCustomViews = ref<CustomFilter[]>([])
  const filterNameInput = ref<string>('')
  const selectedCustomFilter = ref<CustomFilter | null>(null)
  const selectedCustomView = ref<CustomFilter | null>(null)
  const displayCreateFilter = ref<{ show: boolean, type: string }>({ show: false, type: '' })

  const isFiltered = computed<boolean>(() => {
    const { columns, ...otherFilters } = filtersListened.value
    return Object.values(otherFilters).some(v => v.value)
  })

  const findAllCustomFilters = async () => {
    try {
      loading.value = true
      const cf = await CustomFiltersAPI.findAllByTableName(user._id, tableName)
      allCustomFilters.value = cf.find(type => type._id === TypeFilter.Filter)?.data as CustomFilter[] || []
      allCustomViews.value = cf.find(type => type._id === TypeFilter.View)?.data as CustomFilter[] || []
    } catch (e) {
      console.error(e)
    } finally {
      loading.value = false
    }
  }

  const updateFilterListened = (f: FilterProps): void => {
    filtersListened.value = f
  }

  const cleanAndUpdateFiltersBySelector = (f: CustomFilter) => {
    if (f.typeFilter === TypeFilter.Filter) {
      const { columns, ...filterprops } = f.filterProps
      filtersListened.value = { columns: filtersListened.value.columns, ...filterprops } as FilterProps
      selectedCustomFilter.value = f
    } else {
      filtersListened.value.columns = f.filterProps.columns
      selectedCustomView.value = f
    }
  }

  const toggleCreateFilter = (type: TypeFilter | null) => {
    switch (type) {
      case TypeFilter.Filter:
        filterNameInput.value = selectedCustomFilter.value && selectedCustomFilter.value.name ? selectedCustomFilter.value.name : ''
        break
      case TypeFilter.View:
        filterNameInput.value = selectedCustomView.value && selectedCustomView.value.name ? selectedCustomView.value.name : ''
        break
      default:
        filterNameInput.value = ''
    }
    displayCreateFilter.value = { type: type ?? '', show: !displayCreateFilter.value.show }
  }

  const saveFilters = async (type: TypeFilter) => {
    console.log(filtersListened)
    const isFilterType = type === TypeFilter.Filter
    const selected = isFilterType ? selectedCustomFilter : selectedCustomView
    const customFilter = new CustomFilter(
      filtersListened.value as unknown as FilterProps,
      user._id,
      type,
      tableName,
      selected.value?.name ?? filterNameInput.value,
      false, // si hay un filtro guardado preseleccionado, lo que va a hacer es sobreescribirlo
      selected.value?._id,
      selected.value?.__v,
      selected.value?.updatedAt,
      selected.value?.createdAt
    )
    try {
      await CustomFiltersAPI.upsert(customFilter)
      toast.add({
        severity: ToastSeverities.Success,
        summary: ToastSummaries.Success,
        detail: isFilterType ? 'Filtro guardado' : 'Vista guardada',
        life: ToastLife.Default
      })
      if (isFilterType) {
        selectedCustomFilter.value = customFilter
      } else {
        selectedCustomView.value = customFilter
      }
    } catch (e) {
      console.error(e)
      toast.add({
        severity: ToastSeverities.Error,
        summary: ToastSummaries.Error,
        detail: isFilterType ? 'Error al crear filtro' : 'Error al crear la vista',
        life: ToastLife.Default
      })
    } finally {
      toggleCreateFilter(null)
      await findAllCustomFilters()
    }
  }

  const deleteFilter = async (cf: CustomFilter): Promise<void> => {
    try {
      await CustomFiltersAPI.delete(cf._id as string)
      if (selectedCustomFilter.value?._id === cf._id) { // Si el filtro eliminado era el activo, limpia los campos
        filtersListened.value = cleanFilter
        selectedCustomFilter.value = null
      }
      if (selectedCustomView.value?._id === cf._id) { // Si la vista eliminada era el activo, limpia los campos
        filtersListened.value.columns.value = null
        selectedCustomView.value = null
      }
      toast.add({
        severity: ToastSeverities.Success,
        summary: ToastSummaries.Success,
        detail: 'Filtro eliminado',
        life: ToastLife.Default
      })
    } catch (e) {
      console.error(e)
      toast.add({
        severity: ToastSeverities.Error,
        summary: ToastSummaries.Error,
        detail: 'Error al eliminar filtro',
        life: ToastLife.Default
      })
    } finally {
      await findAllCustomFilters()
    }
  }

  const showConfirmDeleteFilter = (cf: CustomFilter): void => {
    confirm.require({
      message: cf.typeFilter === TypeFilter.Filter ? '¿Estás seguro/a de eliminar este filtro?' : '¿Estás seguro/a de eliminar la vista?',
      header: '¡Alto ahí!',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        deleteFilter(cf)
      },
      reject: () => {
        confirm.close()
        selectedCustomFilter.value = null
      }
    })
  }

  const removeActiveFilter = (type: TypeFilter) => {
    const { columns, ...other } = cleanFilter
    if (type === TypeFilter.Filter) {
      selectedCustomFilter.value = null
      filtersListened.value = { ...other, columns: filtersListened.value.columns }
    } else {
      selectedCustomView.value = null
      filtersListened.value.columns = columns
    }
  }

  return {
    findAllCustomFilters,
    toggleCreateFilter,
    saveFilters,
    showConfirmDeleteFilter,
    removeActiveFilter,
    updateFilterListened,
    cleanAndUpdateFiltersBySelector,
    allCustomFilters,
    allCustomViews,
    isFiltered,
    filterNameInput,
    selectedCustomFilter,
    selectedCustomView,
    displayCreateFilter
  }
}
