import { Api } from '@/api/api'
import { Career, Level } from '@/models/www/Products'

const baseURL = process.env.ACADEMIC_API_ENDPOINT

const getProducts: (
  query?: Record<string, string | number | boolean | Array<unknown> | Date>
) => Promise<{ remotes: Level[]; careers: Career[] }> = async (query = {}) => {
  const response = (await Api.get(`${baseURL}/v0/products`, query)) as {
    remotes: Level[]
    careers: Career[]
  }
  const { remotes, careers } = response
  return { remotes, careers }
}

export { getProducts }
