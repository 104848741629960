import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withCtx as _withCtx, Fragment as _Fragment, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-01d5b8f0")
const _hoisted_1 = {
  key: 1,
  class: "p-text-bold"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterConfiguration = _resolveComponent("FilterConfiguration")!
  const _component_BrainSpinner = _resolveComponent("BrainSpinner")!
  const _component_StaffTable = _resolveComponent("StaffTable")!
  const _component_ConfirmDialog = _resolveComponent("ConfirmDialog")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_FilterConfiguration, {
      class: "p-d-flex p-mb-2",
      searchTxt: _ctx.searchTxt,
      allCustomFilters: _ctx.courseFilters,
      allCustomViews: _ctx.courseViews,
      isLoading: _ctx.isLoading,
      isFiltered: _ctx.isFiltered,
      selectedCustomFilter: _ctx.selectedCustomFilter,
      selectedCustomView: _ctx.selectedCustomView,
      allColumns: Array.from(_ctx.allColumns.values()),
      selectedColumns: _ctx.filters.columns?.value,
      onUpdateSearchTxt: _ctx.updateSearchTxt,
      onSelectColumns: _ctx.changeSelectedColumns,
      onSelectFilter: _ctx.cleanAndUpdateFiltersBySelector,
      onToggleCreateFilter: _ctx.toggleCreateFilter,
      onRemoveActiveFilter: _ctx.removeActiveFilter,
      onDeleteFilter: _ctx.showConfirmDeleteFilter
    }, null, 8, ["searchTxt", "allCustomFilters", "allCustomViews", "isLoading", "isFiltered", "selectedCustomFilter", "selectedCustomView", "allColumns", "selectedColumns", "onUpdateSearchTxt", "onSelectColumns", "onSelectFilter", "onToggleCreateFilter", "onRemoveActiveFilter", "onDeleteFilter"]),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_BrainSpinner, { key: 0 }))
      : (_openBlock(), _createBlock(_component_StaffTable, {
          key: 1,
          allColumns: _ctx.allColumns,
          customFilterActive: _ctx.filters,
          selectedMails: _ctx.selectedMails,
          staff: _ctx.staff,
          loading: _ctx.loading,
          rows: _ctx.firstVal,
          totalRecords: _ctx.totalRecords,
          contextId: _ctx.contextId,
          selectedColumns: _ctx.filters.columns?.value,
          allCoursePlans: _ctx.allCoursePlans,
          onPage: _cache[0] || (_cache[0] = ($event) => _ctx.onPage($event)),
          onUpdateFilters: _ctx.updateFilterListened
        }, null, 8, ["allColumns", "customFilterActive", "selectedMails", "staff", "loading", "rows", "totalRecords", "contextId", "selectedColumns", "allCoursePlans", "onUpdateFilters"])),
    _createVNode(_component_ConfirmDialog, {
      breakpoints: {'960px': '75vw', '640px': '100vw'},
      style: {width: '50vw'}
    }),
    _createVNode(_component_Dialog, {
      header: _ctx.dialogText.title,
      visible: _ctx.displayCreateFilter.show,
      "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => (_ctx.displayCreateFilter.show = $event)),
      style: {width: '350px'},
      modal: true
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "Cancelar",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleCreateFilter(null))),
          class: "p-button-text button-underlined"
        }),
        _createVNode(_component_Button, {
          label: "Guardar",
          disabled: _ctx.dialogText.disabledBtn,
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.saveFiltersTypeFilter(_ctx.displayCreateFilter.type)))
        }, null, 8, ["disabled"])
      ]),
      default: _withCtx(() => [
        (_ctx.dialogText.newCustomFilter)
          ? (_openBlock(), _createBlock(_component_InputText, {
              key: 0,
              modelValue: _ctx.filterNameInput,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.filterNameInput = $event)),
              placeholder: _ctx.dialogText.placeholder,
              disabled: _ctx.isLoading
            }, null, 8, ["modelValue", "placeholder", "disabled"]))
          : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.updatingTxt), 1))
      ]),
      _: 1
    }, 8, ["header", "visible"])
  ], 64))
}