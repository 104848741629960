import { ContractRoles } from '@/models/contracts'

export function contractRole (role: string | ContractRoles): string {
  switch (role) {
    case ContractRoles.Teacher:
      return 'PROFESOR'
    case ContractRoles.Assistant:
      return 'TUTOR'
    case ContractRoles.Associate:
      return 'ASOCIADO'
    case ContractRoles.CoderAskAssistant:
      return 'TUTOR CODERASK'
    case ContractRoles.Coordinator:
      return 'TUTOR COORDINADOR'
    case ContractRoles.ReviewerAssistant:
      return 'TUTOR CORRECTOR'
    case ContractRoles.SyncAssistant:
      return 'TUTOR SINCRONO'
    case ContractRoles.Specialist:
      return 'ESPECIALISTA'
    case ContractRoles.Substitute:
      return 'SUBSTITUTO'
    default:
      return 'DESCONOCIDO'
  }
}
