export class LastCommission {
  number: number
  rating: number

  constructor (
    number: number,
    rating: number
  ) {
    this.number = number
    this.rating = rating
  }
}

export class CourseDates {
  dayFrom: number
  dayTo: number
  timeFrom: string
  timeTo: string

  constructor (
    dayFrom: number,
    dayTo: number,
    timeFrom: string,
    timeTo: string
  ) {
    this.dayFrom = dayFrom
    this.dayTo = dayTo
    this.timeFrom = timeFrom
    this.timeTo = timeTo
  }
}

export class ActiveCommission {
  courseNumber: number
  courseDates: Array<CourseDates>
  rating: number
  warmup: Date
  constructor (
    courseNumber: number,
    courseDates: Array<CourseDates>,
    rating: number,
    warmup: Date
  ) {
    this.courseNumber = courseNumber
    this.courseDates = courseDates
    this.rating = rating
    this.warmup = warmup
  }
}

export class Course {
  courseName: string
  activeCommissions: Array<ActiveCommission>
  finishedAverage: number
  lastCommission: LastCommission

  constructor (
    courseName: string,
    activeCommissions: Array<ActiveCommission>,
    finishedAverage: number,
    lastCommission: LastCommission
  ) {
    this.courseName = courseName
    this.activeCommissions = activeCommissions
    this.finishedAverage = finishedAverage
    this.lastCommission = lastCommission
  }
}

export class StaffListData {
  userId: string
  firstName: string
  lastName: string
  mail: string
  country: string
  courses: Array<Course>
  teacherKickOff: Date

  constructor (
    userId: string,
    firstName: string,
    lastName: string,
    mail: string,
    country: string,
    courses: Array<Course>,
    teacherKickOff: Date
  ) {
    this.userId = userId
    this.firstName = firstName
    this.lastName = lastName
    this.mail = mail
    this.country = country
    this.courses = courses
    this.teacherKickOff = teacherKickOff
  }
}

export class StaffData {
  count: number
  totalPages: number
  skip: number
  limit: number
  data: Array<StaffListData>

  constructor (
    count: number,
    totalPages: number,
    skip: number,
    limit: number,
    data: Array<StaffListData>
  ) {
    this.count = count
    this.totalPages = totalPages
    this.skip = skip
    this.limit = limit
    this.data = data
  }
}
