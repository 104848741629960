/**
 * Function util truncate
 * @param value number to truncate
 * @param digit decimal counts
 */
export function truncate (value: number, digit = 2): number {
  if (isNaN(Number(value.toString()))) return value
  return Number(value.toFixed(digit))
}

export function average (arr: Array<number>): number {
  return arr.reduce<number>((a, b) => a + b, 0) / arr.length
}

export function percentage (
  value: number,
  total: number,
  floor = false
): number {
  const percentage = (value / total) * 100
  return floor ? Math.floor(percentage) : percentage
}

/**
 * Evaluates if the sent parameter is a number or not
 * @param {unknown} value
 * @return true or false
 */
export function isNumber (value: unknown): boolean {
  return !isNaN(Number(value))
}
