import { ContractGroups } from '@/models/contracts'

export function contractGroup (value: string | ContractGroups): string {
  switch (value) {
    case ContractGroups.EnglishAcademy:
      return 'SI'
    case ContractGroups.Regular:
      return 'NO'
    default:
      return 'DESCONOCIDO'
  }
}
