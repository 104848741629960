import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-a34f2226")
const _hoisted_1 = { class: "p-d-flex p-jc-between p-ai-center" }
const _hoisted_2 = { class: "p-col-10" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("article", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      (_ctx.showRemoveButton)
        ? (_openBlock(), _createElementBlock("i", {
            key: 0,
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onRemove && _ctx.onRemove(...args))),
            class: "pi pi-fw pi-times-circle p-col-2"
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}