
import { PositionRef, PersonNode, PersonPositionPopulatedDto } from '@/models/organization'
import { groupByInMap } from '@/utils/collections'
import { compareByName } from '@/utils/entities'
import { computed, defineComponent, onMounted, PropType, ref, toRefs, watch } from 'vue'

const Save = 'save'
const Delete = 'delete'

/**
 * Returns all the position that can be parent of this position.
 * A position is candidate if it is not descendant of this position (so no circular relationship can be created).
 * @param positions all existent positions
 * @param positionId id of the position to find candidate parents
 */
function findCandidateParents (positions: Array<PersonPositionPopulatedDto>, positionId: string): Array<PositionRef> {
  const positionsByParent = groupByInMap(positions, p => p.parent?._id || '--root--')

  const removeChildren = (parentId: string) => {
    const children = positionsByParent.get(parentId)
    if (children) {
      positionsByParent.delete(parentId)
      children.forEach(child => removeChildren(child._id))
    }
  }

  removeChildren(positionId)

  const candidates = [...positionsByParent.values()].flat(1).map(({ _id, name, people }) => ({
    _id: _id,
    name: people.length === 1 ? people[0].name + '(' + name + ')' : name
  }))

  return candidates.sort(compareByName)
}

export default defineComponent({
  name: 'PositionEdit',
  props: {
    position: {
      type: Object as PropType<PersonPositionPopulatedDto>,
      required: true
    },
    positions: {
      type: Object as PropType<Array<PersonPositionPopulatedDto>>,
      required: true
    },
    people: {
      type: Object as PropType<Array<PersonNode>>,
      required: true
    }
  },
  emits: [Save, Delete],
  setup: function (props, { emit }) {
    const { position, positions } = toRefs(props)

    const name = ref<string | undefined>(position.value.name)
    const parent = ref<PositionRef | undefined>()
    const candidateParents = ref<Array<PositionRef>>([])
    const children = ref<Array<PositionRef>>([])
    const newSubAreaName = ref('')
    const assigned = ref<PersonNode | undefined>(undefined)
    const newMember = ref<PersonNode | undefined>(undefined)
    const members = ref<Array<PersonNode>>([])

    const computeDataForPosition = (position: PersonPositionPopulatedDto) => {
      name.value = position.name
      parent.value = position.parent
      candidateParents.value = findCandidateParents(positions.value, position._id)
      children.value = positions.value.filter(p => p.parent?._id === position._id)
      // Una posición con mas de un miembro no puede tener "responsable" (es una posición 'múltiple')
      members.value = [...position.people]
      newMember.value = undefined
      newSubAreaName.value = ''
      updateAssignee()
    }

    const makePositionDto = () => ({
      _id: position.value._id,
      name: name.value,
      parent: parent.value,
      people: [...members.value]
    } as PersonPositionPopulatedDto)

    onMounted(() => computeDataForPosition(position.value))

    watch(position, computeDataForPosition)

    const allowManyPeople = computed(() => children.value.length <= 0)
    const allowSubAreas = computed(() => members.value.length <= 1)
    const canDelete = computed(() => !children.value.length && !members.value.length)
    const isSingleMember = computed(() => members.value.length <= 1)
    const membersLabel = computed(() =>
      isSingleMember.value ? (allowManyPeople.value ? 'Responsable / Miembros' : 'Responsable') : 'Miembros'
    )
    const assignedChanged = () => {
      members.value = assigned.value ? [assigned.value] : []
    }

    const updateAssignee = () => {
      assigned.value = members.value.length > 0 ? members.value[0] : undefined
    }

    const addMember = () => {
      const member = newMember.value
      if (member && !members.value.find(m => m._id === member._id)) {
        members.value = [...members.value, member]

        updateAssignee()
      }
      newMember.value = undefined
    }

    const removeMember = (memberToRemove: PersonNode) => {
      const newMembers = members.value.filter(m => m._id !== memberToRemove._id)
      if (members.value.length !== newMembers.length) {
        members.value = newMembers

        updateAssignee()
      }
    }

    const save = () => emit(Save, makePositionDto(), newSubAreaName.value)

    const deletePosition = () => emit(Delete, makePositionDto())

    return {
      name,
      children,
      parent,
      members,
      newMember,
      candidateParents,
      assigned,
      newSubAreaName,
      allowManyPeople,
      allowSubAreas,
      canDelete,
      isSingleMember,
      membersLabel,
      assignedChanged,
      addMember,
      removeMember,
      deletePosition,
      save
    }
  }
})
