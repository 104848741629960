import { EducationPlanConfiguration } from '../enums'
import { PlanInformation } from '../interfaces/PlanInformation'
import { PlanLinks } from '../interfaces/PlanLinks'
import { EducationPlan } from './EducationPlan'

export class CoursePlan extends EducationPlan {
  constructor (
    id: string,
    v1Id: string,
    countries: string[],
    information: PlanInformation,
    links: PlanLinks,
    published: boolean,
    display: boolean,
    config: EducationPlanConfiguration[],
    createdAt: Date,
    updatedAt: Date,
    __v: number
  ) {
    super(
      id,
      v1Id,
      countries,
      information,
      links,
      published,
      display,
      config,
      createdAt,
      updatedAt,
      __v
    )
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export function isCoursePlan (el?: any | EducationPlan): el is CoursePlan {
  return el ? !('courses' in el) : false
}
