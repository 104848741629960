
import { ComponentPublicInstance, defineComponent, ref } from 'vue'
import useClipboard from 'vue-clipboard3'
import ScrollPanel from 'primevue/scrollpanel'
import { useToast } from 'primevue/usetoast'
import { ToastLife, ToastSeverities, ToastSummaries } from '@/models/components/toast/enums'

export default defineComponent({
  props: {
    scrollHeightPx: {
      type: Number
    },
    scrollWidthPx: {
      type: Number
    }
  },
  components: {
    ScrollPanel
  },
  setup () {
    const { toClipboard } = useClipboard()
    const toast = useToast()
    const codeRef = ref<ComponentPublicInstance<HTMLElement>>()
    const copy = () => {
      const codeValue = codeRef.value?.textContent || 'Error al copiar el texto'
      toClipboard(codeValue).then(() => {
        toast.add({
          severity: ToastSeverities.Info,
          summary: ToastSummaries.Copied,
          detail: 'El texto esta en el portapapeles.',
          life: ToastLife.Default
        })
      })
    }

    return {
      copy,
      codeRef
    }
  }
})
