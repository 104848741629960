import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CoderBadge = _resolveComponent("CoderBadge")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!

  return (_openBlock(), _createBlock(_component_MultiSelect, {
    panelClass: "overlay-gray",
    showToggleAll: false,
    disabled: _ctx.disabled,
    options: _ctx.options,
    optionLabel: "name",
    optionValue: "value",
    placeholder: _ctx.placeholder
  }, {
    option: _withCtx(({ option }) => [
      _createVNode(_component_CoderBadge, {
        text: option.name,
        backgroundColor: option.value ? _ctx.CSSColors.GreenBrain : _ctx.CSSColors.RedBrain,
        rounded: ""
      }, null, 8, ["text", "backgroundColor"])
    ]),
    _: 1
  }, 8, ["disabled", "options", "placeholder"]))
}