
import { computed, defineComponent, reactive, Ref, ref, toRefs, watch } from 'vue'
import { Direction } from './buttons/types/direction-buttons'

export default defineComponent({
  props: {
    initialZoomPercentage: {
      type: Number,
      default: 100
    },
    showDirectionButtons: {
      type: Boolean,
      default: true
    },
    wheelZoomEnabled: {
      type: Boolean,
      default: true
    }
  },
  components: {
    // ScrollPanel,
    // DirectionButtons
  },
  setup (props) {
    const isDown = ref(false)
    const minScalePossible = 0.1
    const maxScalePossible = 1.5
    const { initialZoomPercentage } = toRefs(props)
    const scaleJump = ref<number>(0.3)
    const initialScale = computed<number>(() => initialZoomPercentage.value / 100)
    const scaleValue = ref<number>(initialScale.value)
    const containerButtonsLeft = ref(15)
    const containerButtonsRight = ref(15)
    const containerButtonsTop = ref(15)
    const zoomPanelContainerRef = ref<HTMLDivElement>() as Ref<HTMLDivElement>
    const zoomPanelContentRef = ref<HTMLDivElement>() as Ref<HTMLDivElement>
    const willExceedMinScale = (): boolean => {
      return (scaleValue.value - scaleJump.value) < minScalePossible
    }
    const willExceedMaxScale = (): boolean => {
      return (scaleValue.value + scaleJump.value) > maxScalePossible
    }
    const position = reactive({
      pos1: 0,
      pos2: 0,
      pos3: 0,
      pos4: 0
    })
    watch(isDown, () => {
      isDown.value
        ? zoomPanelContainerRef.value.style.cursor = 'move'
        : zoomPanelContainerRef.value.style.cursor = 'default'
    })
    return {
      isDown,
      scaleValue,
      containerButtonsLeft,
      containerButtonsTop,
      containerButtonsRight,
      zoomPanelContainerRef,
      zoomPanelContentRef,
      scrollTo: (direction: Direction) => {
        switch (direction) {
          case Direction.Up:
            zoomPanelContainerRef.value && (zoomPanelContainerRef.value.scrollTop -= 20)
            break
          case Direction.Down:
            zoomPanelContainerRef.value && (zoomPanelContainerRef.value.scrollTop += 20)
            break
          case Direction.Right:
            zoomPanelContainerRef.value && (zoomPanelContainerRef.value.scrollLeft += 20)
            zoomPanelContainerRef.value && console.log(zoomPanelContainerRef.value.scrollLeft)
            break
          case Direction.Left:
            zoomPanelContainerRef.value && (zoomPanelContainerRef.value.scrollLeft -= 20)
            zoomPanelContainerRef.value && console.log(zoomPanelContainerRef.value.scrollLeft)
            break
          default:
            break
        }
      },
      onMouseDownToDrag: (e: MouseEvent) => {
        isDown.value = true
        e.preventDefault()
        position.pos3 = e.clientX
        position.pos4 = e.clientY
      },
      move: (e: MouseEvent) => {
        if (!isDown.value) return
        e.preventDefault()
        const el = zoomPanelContentRef.value as HTMLDivElement
        position.pos1 = position.pos3 - e.clientX
        position.pos2 = position.pos4 - e.clientY
        position.pos3 = e.clientX
        position.pos4 = e.clientY
        const offsetY = el.offsetTop - position.pos2
        const offsetX = el.offsetLeft - position.pos1
        el.style.left = offsetX + 'px'
        el.style.top = offsetY + 'px'
      },
      onZoom: (e: WheelEvent) => {
        if (e.deltaY > 0) {
          // zoomOut
          if (!willExceedMinScale()) {
            scaleValue.value -= scaleJump.value
          }
        } else {
          // zoomIn
          if (!willExceedMaxScale()) {
            scaleValue.value += scaleJump.value
          }
        }
      }
    }
  }
})
