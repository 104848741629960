import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_TreeTable = _resolveComponent("TreeTable")!

  return (_openBlock(), _createBlock(_component_TreeTable, {
    value: _ctx.roots,
    scrollable: true,
    expandedKeys: _ctx.expandedKeys
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Column, {
        field: "name",
        header: "Nombre",
        expander: true
      }),
      _createVNode(_component_Column, { header: "Asignado(s)" }, {
        body: _withCtx(({node: { data }}) => [
          _createElementVNode("ul", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.people, (person) => {
              return (_openBlock(), _createElementBlock("li", {
                key: person._id
              }, [
                _createVNode(_component_Avatar, {
                  image: person.avatar,
                  shape: "circle"
                }, null, 8, ["image"]),
                _createTextVNode(" " + _toDisplayString(person.name), 1)
              ]))
            }), 128))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["value", "expandedKeys"]))
}