
import { DayOfTheWeek } from '@/models/enums'
import { defineComponent, computed, toRefs } from 'vue'

export default defineComponent({
  props: {
    disabled: {
      type: Boolean,
      required: true
    }
  },
  setup () {
    const options = Object.values(DayOfTheWeek).map((d, i) => {
      return {
        index: i,
        name: d
      }
    })
    console.log(options)
    return {
      options,
      DayOfTheWeek
    }
  }
})
