import axios, { AxiosError, AxiosRequestConfig } from 'axios'

type Body = unknown | Record<string, unknown>
type Params = Record<string, string | number | boolean | Array<unknown> | Date>

export class ApiError extends Error {
  readonly isAxiosError: boolean
  readonly error: unknown
  readonly message: string
  readonly code: string | undefined
  readonly config: {
    headers: unknown
    baseUrl: string | undefined
    url: string | undefined
  }

  constructor(axiosErr: AxiosError) {
    super(axiosErr.response?.data.message || axiosErr.response?.statusText)
    this.error = axiosErr.response?.data.error
    this.message = axiosErr.response?.data.message
    this.code = axiosErr.code || axiosErr.response?.data.statusCode
    this.isAxiosError = axiosErr.isAxiosError
    this.config = {
      baseUrl: axiosErr.config.baseURL,
      headers: axiosErr.config.headers,
      url: axiosErr.config.url
    }
  }
}

export class Api {
  static async request<T>(config: AxiosRequestConfig): Promise<T> {
    try {
      const { data } = await axios(config)
      return data
    } catch (error) {
      throw (error as AxiosError).isAxiosError
        ? new ApiError(error as AxiosError)
        : error
    }
  }

  static async get<T>(
    url: string,
    params?: Params,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return this.request<T>({
      method: 'GET',
      url,
      params,
      ...config
    })
  }

  static async del<T>(
    url: string,
    body?: Body,
    params?: Params,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return this.request<T>({
      method: 'DELETE',
      url,
      params,
      data: body,
      ...config
    })
  }

  static async post<T>(
    url: string,
    body: Body,
    params?: Params,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return this.request<T>({
      method: 'POST',
      url,
      params,
      data: body,
      ...config
    })
  }

  static async patch<T>(
    url: string,
    body: Body,
    params?: Params,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return this.request<T>({
      method: 'PATCH',
      url,
      params,
      data: body,
      ...config
    })
  }

  static async put<T>(
    url: string,
    body: Body,
    params?: Params,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return this.request<T>({
      method: 'PUT',
      url,
      params,
      data: body,
      ...config
    })
  }
}
