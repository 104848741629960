
import { defineComponent } from 'vue'
import { MenuItem } from 'primevue/menuitem'
export default defineComponent({

  setup () {
    return {
      menuItems: [
        {
          label: 'Tipos de contrato',
          to: {
            name: 'ContractTypes'
          }
        },
        {
          label: 'Reportes',
          to: {
            name: 'ContractReports'
          }
        }
      ] as MenuItem[]
    }
  }
})
