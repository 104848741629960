export enum ContractRoles {
  Teacher = 'Teacher',
  Substitute = 'Substitute',
  Associate = 'Associate',
  Coordinator = 'Coordinator',
  Specialist = 'Specialist',
  Assistant = 'Assistant',
  ReviewerAssistant = 'ReviewerAssistant',
  CoderAskAssistant = 'CoderAskAssistant',
  SyncAssistant = 'SyncAssistant',
}
