import axios, { AxiosError } from 'axios'
import { Api } from './api'
import { CourseAPI } from './course.api'

import { CourseAssignment } from '@/models/recruitment/classes/CourseAssignment'
import { CourseAssignedByRole } from '@/models/recruitment/interfaces'
import {
  OrgPosition,
  OrgStructureNodeDto,
  PersonNode,
  PersonPositionPopulatedDto
} from '@/models/organization'
import { Person } from '@/models/person/classes/Person'
import { CourseFeedbackSummaryAPI } from './feedbacks.api'
import { DTOPerson } from '@/models/person/interfaces/DTOPerson'
import { PersonRoles } from '@/models/person/enums'

const baseURL = process.env.ACADEMIC_API_ENDPOINT

async function get<T>(path: string): Promise<T> {
  try {
    return (await axios.get<T>(path)).data
  } catch (err) {
    const axiosErr = err as AxiosError

    throw (
      axiosErr.response?.data.message ||
      axiosErr.response?.statusText ||
      String(err)
    )
  }
}

async function deleteOne(path: string): Promise<void> {
  try {
    await axios.delete<void>(path)
  } catch (err) {
    const axiosErr = err as AxiosError

    throw (
      axiosErr.response?.data.message ||
      axiosErr.response?.statusText ||
      String(err)
    )
  }
}

const findOrganizationTree = async (): Promise<OrgPosition> =>
  (
    await get<Array<OrgPosition>>(
      `${baseURL}/v1/org-positions?format=hierarchy`
    )
  )[0]

const findEditableOrganizationTree = async (): Promise<
  Array<OrgStructureNodeDto>
> => get<Array<OrgStructureNodeDto>>(`${baseURL}/v1/org-positions?format=tree`)

const findActivePeople = async (): Promise<Array<PersonNode>> =>
  get<Array<PersonNode>>(`${baseURL}/v1/people?enabled=true`)

const findPeoplePosition = async (
  id: string
): Promise<PersonPositionPopulatedDto> =>
  get(`${baseURL}/v1/org-positions/${id}?format=populated`)

const findAllPopulatedPeoplePosition = async (): Promise<
  Array<PersonPositionPopulatedDto>
> => get(`${baseURL}/v1/org-positions?format=populated`)

const updatePosition = async (
  position: PersonPositionPopulatedDto,
  newSubareaName?: string
): Promise<PersonPositionPopulatedDto> => {
  let addAreaPromise

  if (newSubareaName) {
    addAreaPromise = axios.post<PersonPositionPopulatedDto>(
      `${baseURL}/v1/org-positions`,
      {
        name: newSubareaName,
        parent: position._id,
        people: []
      }
    )
  } else {
    addAreaPromise = Promise.resolve(position)
  }

  const r = await axios.put<PersonPositionPopulatedDto>(
    `${baseURL}/v1/org-positions/${position._id}`,
    {
      name: position.name,
      parent: position.parent?._id,
      people: position.people.map((p) => p._id)
    }
  )

  await addAreaPromise

  return r.data
}

function convertDtoToPerson(dto: DTOPerson): Person {
  return new Person(
    dto._id,
    dto.name,
    dto.email,
    dto.avatar,
    dto.area,
    dto.enabled,
    dto.rol,
    dto.roles,
    dto.socialNetworks,
    dto.identityDocument,
    dto.phone,
    dto.country,
    dto.responsabilities,
    dto.lastname,
    dto.activeRole,
    dto.unenroll,
    undefined,
    dto.cvFileLink
  )
}

async function findPersonById(personId: string): Promise<Person> {
  const person = await axios.get<DTOPerson>(`${baseURL}/v1/people/${personId}`)
  const data = person.data
  return convertDtoToPerson(data)
}

async function findPersons(personIds: string[]): Promise<Map<string, Person>> {
  const person = await axios.post<DTOPerson[]>(
    `${baseURL}/v1/people/find`,
    personIds
  )
  const data: DTOPerson[] = person.data
  return new Map(data.map((p) => [p._id, convertDtoToPerson(p)]))
}

async function getCoursesAssignementByPersonId(
  personId: string
): Promise<CourseAssignment[]> {
  const {
    substitudteacher,
    assistant,
    assistantSubstituteTeacher,
    coordinator,
    repleacementTeacher,
    reviewerTeachers,
    supervisor,
    teacher,
    tutor
  } = await Api.get<CourseAssignedByRole>(`/v1/staff/${personId}`)

  async function getCourseAssignment(
    personId: string,
    courseId: string,
    role: PersonRoles
  ) {
    const course = await CourseAPI.findById(courseId)
    const summary = await CourseFeedbackSummaryAPI.findByPersonIdAndCourseIds(
      personId,
      [courseId]
    )
    return new CourseAssignment(
      course._id,
      course.calendar.start,
      course.calendar.end,
      course.name,
      summary[0]?.feedbackCount,
      summary[0]?.normalized,
      summary[0]?.rating,
      role,
      course.id,
      course.calendar.daysOfTheWeekNames,
      course.calendar.start,
      course.calendar.end
    )
  }

  const asTeacherPromises = Promise.all(
    teacher.map((courseId) =>
      getCourseAssignment(personId, courseId, PersonRoles.Teacher)
    )
  )
  const asTutorPromises = Promise.all(
    tutor.map((courseId) =>
      getCourseAssignment(personId, courseId, PersonRoles.Tutor)
    )
  )
  const asAssistantPromises = Promise.all(
    assistant.map((courseId) =>
      getCourseAssignment(personId, courseId, PersonRoles.Assistant)
    )
  )
  const asSubstitudteacherPromises = Promise.all(
    substitudteacher.map((courseId) =>
      getCourseAssignment(personId, courseId, PersonRoles.SubstitudTeacher)
    )
  )
  const asAssistantSubstituteTeacherPromise = Promise.all(
    assistantSubstituteTeacher.map((courseId) =>
      getCourseAssignment(
        personId,
        courseId,
        PersonRoles.AssistantSubstituteTeacher
      )
    )
  )
  const asCoordinatorPromise = Promise.all(
    coordinator.map((courseId) =>
      getCourseAssignment(personId, courseId, PersonRoles.Coordinator)
    )
  )
  const asRepleacementTeacherPromise = Promise.all(
    repleacementTeacher.map((courseId) =>
      getCourseAssignment(personId, courseId, PersonRoles.ReplacementTeacher)
    )
  )
  const asReviewerTeachersPromise = Promise.all(
    reviewerTeachers.map((courseId) =>
      getCourseAssignment(personId, courseId, PersonRoles.ReviewerTeachers)
    )
  )
  const asSupervisorPromise = Promise.all(
    supervisor.map((courseId) =>
      getCourseAssignment(personId, courseId, PersonRoles.Supervisor)
    )
  )

  const asTeacher = await asTeacherPromises
  const asTutor = await asTutorPromises
  const asAssistant = await asAssistantPromises
  const asSubstitudteacher = await asSubstitudteacherPromises
  const asAssistantSubstituteTeacher = await asAssistantSubstituteTeacherPromise
  const asCoordinator = await asCoordinatorPromise
  const asRepleacementTeacher = await asRepleacementTeacherPromise
  const asReviewerTeachers = await asReviewerTeachersPromise
  const asSupervisor = await asSupervisorPromise

  return [
    ...asTeacher,
    ...asTutor,
    ...asAssistant,
    ...asSubstitudteacher,
    ...asAssistantSubstituteTeacher,
    ...asCoordinator,
    ...asRepleacementTeacher,
    ...asReviewerTeachers,
    ...asSupervisor
  ]
}

const removePosition = async (positionId: string): Promise<void> =>
  await deleteOne(`${baseURL}/v1/org-positions/${positionId}`)

export {
  findPersonById,
  findOrganizationTree,
  findEditableOrganizationTree,
  findActivePeople,
  findPeoplePosition,
  findAllPopulatedPeoplePosition,
  updatePosition,
  removePosition,
  findPersons,
  getCoursesAssignementByPersonId
}
