export enum Formats {
  Minimal,
  Middle,
  Small,
  Full,
}

export enum CoderEmails {
  defaultEmail = 'coderhouse@coderhouse.com'
}
